@import '../../sass/variables';

.jdContentContainer {
    width: 98%;
    display: flex;
    flex-direction: column;
    margin-top: 1.3380909901873328vh;
}
@media (max-width: 550px) {
    .jdContentContainer {
        width: 95%;
    }
}
@media (max-height: 550px) {
    .jdContentContainer {
        margin-top: 3.338091vh;
    }
}
.lableWrap {
    height: 3.29vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.jdLable {
    font-size: clamp(14px, 18 * $vw-const, 18 * $vw-const);
    color: #130E49;
    letter-spacing: 0.65px;
    font-weight: 300;
}
@media (max-height: 550px) {
    .jdLable {
        font-size: 2.58344vh;
    }
}
.textAreaContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 3px 0 rgba(239,239,239,0.92);
    border-radius: 8.5px;
}
.textAreaStyle {
    font-size: 1.56163vh;
    color: #000000;
    letter-spacing: 0.5px;
    width: 100% !important;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 28.015vh !important;
    outline: none;
    box-sizing: border-box;
    font-weight: 400;
    padding-left: 12px;
    resize: none;
    background: transparent;
    border: 0px dashed #979797;
   // box-shadow: 0 2px 3px 0 rgba(239,239,239,0.92);
    border-radius: 8.5px;
    z-index: 10;
    overflow: auto;
    font-family: 'Poppins'
}
.textAreaStyle:focus {
    outline: none !important;
    border: 1px dashed #007AFF;
    box-shadow: 0 2px 3px 0 rgba(239,239,239,0.92);
}
.textAreaStyle::placeholder {
    font-size: 1.46163vh;
}
@media (max-width: 1024px) {
    .textAreaStyle {
        height: 35vh !important;
    }
}
@media (max-height: 550px) {
    .textAreaStyle {
        font-size: 2.22vh;
        height: 56vh !important;
    }
}
.placeholderWrap {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-top: 20px;
}
.PlaceholderImg {
    width: 30px;
    margin-right: 5px;
}
.buildBtn:disabled {
    background-color: #979797!important;
}
.buildBtn {
    background-color: #E06A09!important;
    margin-top: 10px;
    margin-bottom: 10px;
}
@media (max-width: 550px) {
    .PlaceholderImg {
        width: 20px;
    }
}
.placeholderText {
    font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
    font-weight: 300;
    letter-spacing: 0;
    margin-bottom: 12px;
    display: flex;
    align-items: center;

}
@media (max-width: 550px) {
    .placeholderText {
        margin-bottom: 6px;
    }
}
@media (max-height: 550px) {
    .placeholderText {
        font-size: 2.21803vh;
    }
}
.resetButton {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 14;
}
.viewFullButton {
    position: absolute;
    bottom: 12px;
    right: 12px;
    z-index: 14;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #424242;
    box-shadow: 0 2px 4px 0 #E7E7E7;
}
.quiteStyle {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.viewFullStyle {
    width: 14px;
    height: 14px;
    cursor: pointer;
}
@media (max-width: 550px) {
    .viewFullButton {
        width: 24px;
        height: 24px;
    }
    .viewFullStyle {
        width: 12px;
        height: 12px;
    }
}
.checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
    margin-top: 4px;
}
.checkbox > div > input {
    cursor: pointer;
}
.checkbox > div:first-child {
    display: flex;
    align-items: center;
}
.checkboxText {
    font-size: 1.46163vh;
    font-weight: 600;
    color: #000000;
    letter-spacing: 0.45px;
    display: flex;
}
@media (max-height: 550px) {
    .checkboxText {
        font-size: 2.1vh;
    }
}
.checkboxInput {
    margin-right: 8px;
}
.savedJdWrap {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 20px;
}
.dropDownContainer {
    z-index: 15;
}