body {
  margin: 0;
  padding: 0;
  /* //  font-family: Montserrat, sans-serif !important; */
  font-family: "Poppins", sans-serif !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* box-sizing: border-box; */
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  outline: none;
}
.slick-prev:before,
.slick-next:before
{
    color: #DDDDE0!important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

*:focus {
  outline: none;
}

* {
  margin: 0;
  padding: 0;
}

/* Webkit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Adjust the width as needed */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Handle color */
  border-radius: 6px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Handle color on hover */
}
* {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Handle and track color */
}