.Footer {
    min-height: 40px;
    z-index: 1;
    bottom: 0 !important;
    position: relative;
    width: 100%;
    height: 75px;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #ffffff;
}

.FooterSpan {
    margin-left: 20px;
    font-size: 10px;
    color: #fff;
    letter-spacing: 0.5px;
    font-weight: 700;
}

.Allright {
    font-size: 10px;
    color: #fff;
    letter-spacing: 0.5px;
    font-weight: 700;
}

@media (max-width: 767px) {
    .Allright {
       font-size: clamp(8px, 10px, 10px);
    }
    .allRightReserved {
        display: none;
    }
    .Footer {
        height: 65px;
    }
    .FooterSpan {
        margin-left: 5px;
        font-size: 8px;
        position: absolute;
        bottom: 5px;
        width: 100%;
       text-align: left;
    }
}



.contentWrap {
    display: flex;
    flex: 1;
}

.FooterSpanWrap {
    flex: 1;
    display: flex;
    align-items: center;
}

.reachOut {
    flex: 1;
    font-size: 10px;
    font-weight: 700;
    text-align: left;
    text-decoration: none;
    color: #ffffff;
    letter-spacing: 0.5px;
    margin-right: 20px;
}

.privacy {
    margin-left: 20px;
    margin-right: 5px;

}

.privacy:hover {
    color: blueviolet;
    cursor: pointer;
}

@media (max-width: 600px) {
    .FooterSpan {
        margin-left: 10px;
    }

    .reachOut {
        text-align: left;
    }
}

@media (max-width: 414px) {
    .reachOut {
        font-size: 8px;
        align-self: center;
    }
}

@media (max-height: 550px) {
    .FooterSpan {
        font-size: 2.218026796589525vh;
    }

    .Allright {
        font-size: 2.218026796589525vh;
    }

    .reachOut {
        font-size: 2.218026796589525vh;
    }
}