.contentContainer {
    position: fixed;
    top: calc(60vh);
    right: 0;
    width: 35%;
    height: 30vh;
    display: flex;
    background-color: transparent;
    z-index: 2000;
    transition: transform 0.75s ease-out;
}
@media (max-width: 1440px) {
    .contentContainer {
        width: 435px;
        height: 265px;
    }
}
@media (max-width: 1024px) {
    .contentContainer {
        top: calc(40vh);
    }
}
@media (max-width: 500px) {
    .contentContainer {
        width: 85%;
        height: auto;
    }
}
@media (max-height: 500px) {
    .contentContainer {
        top: calc(12vh);
    }
}
@media (max-width: 320px) {
    .contentContainer {
        height: auto;
    }
}
@media (max-height: 300px) {
    .contentContainer {
        height: auto;
    }
}
.FeedBackContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(209,209,209,0.50);
}
@media (max-height: 500px) {
    .feedBackTextArea {
        font-size: 14px !important;
    }
}
.Open{
    transform: translateX(0);
}
/* .Close {
    transform: translateX(400px);
} */
.ratingWrap {
    flex: 1;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media (max-width: 500px) {
    .ratingWrap {
        width: 90%;
    }
}
.ratingWrap > span {
    font-size: 2.192448233861145vh;
    font-weight: 700;
    color: #130E49;
    letter-spacing: 0;
    margin-top: 10px;
}
@media (max-height: 500px) {
    .ratingWrap > span {
        font-size: 18px;
    }
}
.feedBackToggleWrap {
    display: flex;
    flex-direction: column;
}
.toggleButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    z-index: 100;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: -3px 0px 4px 0 rgba(209,209,209,0.50);
    padding: 5px;
    cursor: pointer;
}
.feedBackImg {
    width: 28px;
}
.closeCrossImg {
    width: 18px;
}
@media (max-width: 500px) {
    .closeCrossImg {
        width: 20px;
    }
}
.emptySpace {
    flex: 1;
    background: transparent;
}
.ratingLabel {
    font-size:  2.43605359317905vh;
    font-weight: 500;
    color: #130E49;
    letter-spacing: 0;
}
@media (max-height: 500px) {
    .ratingLabel {
        font-size: 18px;
    }
}
.smileyWrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
}
.smileyImg {
    width: 3vw;
}
@media(max-width: 1440px) {
    .smileyImg {
        width: 45px;
        height: 45px; 
    }
}
@media(max-width: 500px) {
    .smileyImg {
        width: 35px;
        height: 35px; 
    }
}
.ratingStyle {
    font-size: 18px;
    font-weight: 700;
    color: #130E49;
    letter-spacing: 0;
    margin-top: 10px;
}
.feedBackTextArea {
    width: -webkit-fill-available;
    width: -moz-available;
    font-size: 1.705237515225335vh;
    font-weight: 500;
    background: #DFDEE7;
    border: 0 solid #EDEDED;
    border-radius: 1px;
    height: 11vh;
    padding: 5px;
    resize: none;
}
@media (max-width: 1440px) {
    .feedBackTextArea {
        height: 80px;
        width: -moz-available;
    }
}
@media (max-width: 500px) {
    .feedBackTextArea {
        height: 45px;
    }
}
.thankYoutext {
    font-size: 2.192448233861145vh;
    font-weight: 600;
    color: #130E49;
    letter-spacing: 0;
    margin-bottom: 10px;
}
@media (max-height: 500px) {
    .thankYoutext {
        font-size: 18px;
    }
}
.commentLable {
    font-size: 1.8270401948842874vh;
    font-weight: 500;
    color: #130E49;
    letter-spacing: 0;
    margin-bottom: 10px;
}
@media (max-height: 500px) {
    .commentLable {
        font-size: 15px;
    }
}
.socialImgWrap {
    display: flex;
    justify-content: flex-end;
}
.buttonContactUs {
    background: #007AFF;
    border-radius: 5px;
    outline: none;
    min-height: 32px !important;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    border: none;
    text-align: center;
    margin-bottom: 20px;
    z-index: 100;
    align-self: center;
    cursor: pointer;
    padding: 0.4rem;
}
.buttonContactUs:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.buttonContactUs:hover {
    box-shadow: 0 2px 10px 0 rgba(73,156,248,0.90);
}
@media (max-width: 1024px) {
    .buttonContactUs {
      font-size: 14px !important;
      margin-bottom: 10px;
    }
}
@media (max-width: 500px) {
    .buttonContactUs {
        width: 95px !important;
        font-size: 12px !important;
        margin-bottom: 10px;
    }
}
.shareImg {
    width: 20px;
    height: 20px;
    margin: 5px;
}
@media (max-width: 500px) {
    .shareImg {
        width: 16px;
        height: 16px;
    }
}