.jdViewerContainer {
    position: relative;
    width: 60%;
    height: 80%;
    background: #ffffff;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 550px) {
    .jdViewerContainer {
        width: 85%;
    }
}

.titleStyle {
    width: 100%;
    font-size: 25px;
    font-weight: 600;
    color: #252525;
    letter-spacing: 1.25px;
    text-align: center;
}

@media (max-width: 550px) {
    .titleStyle {
        font-size: 12px;
    }
}

.JdViewerStyle {
    font-size: 15px;
    color: #000000;
    letter-spacing: 1px;
    line-height: 22px;
    width: 98%;
    height: 86%;
    border: 0px;
    background: #E9E9E9;
    border-radius: 5px;
    resize: none;
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 8px;
    overflow: auto;
}

@media (max-width: 550px) {
    .JdViewerStyle {
        font-size: 12px;
    }
}

.buttonWrap {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (max-width: 550px) {
    .buttonWrap {
        width: auto;
    }
}

.cancelButtonStyle {
    width: 10vw;
    min-width: 100px;
    background: #959595;
    border-radius: 5px;
    font-weight: 600;
    margin-right: 20px;
}

.cancelButtonStyle:hover {
    box-shadow: 0 2px 10px 0 rgba(126, 126, 126, 0.9);
}

.saveButtonStyle {
    width: 10vw;
    min-width: 100px;
    font-weight: 600;
}