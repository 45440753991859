
@import '../../../sass/variables';
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  inset: 0;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.content {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: flex-start;
  /*  background: white; */
  background: #fafafa;
  border-radius: 10px;
  width: 50%;
  padding: clamp(10px, 30 * $vw-const, 30 * $vw-const);
  flex-direction: column;
  height: 80vh;
  overflow: auto;
  font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
}
.contentContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}
.jobDescription {
  height: 90%;
  overflow: auto;
  text-align: left;
  padding: 20px;
  background-color: rgb(245, 243, 243);
 // border: 1px solid gray;
  border-radius: 10px;
  margin-top: 5px;
}

@media (max-width: 767px) {
  .content {
    width: 70%;
    height: 90vh;
  } 
}