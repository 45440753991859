.tableContainer {
    width: 100%;
    /* border: 1px solid #979797; */
    transition: all 1s linear;
}
.tableHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: #DBFAFA;
    border: 0 solid #DADADA;
    font-size: 1.5834348355663825vh;
    color: #130E49;
    letter-spacing: 0;
    font-weight: 600;
}
.ConentContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 1.218026796589525vh;
    color: #000000;
    letter-spacing: 0;
}
@media (max-width: 600px) {
    .tableHeader {
        font-size: 18px;
    }
    .ConentContainer {
        font-size: 16px;
    }
}
@media (max-width: 500px) {
    .tableHeader {
        font-size: 14px;
    }
    .ConentContainer {
        font-size: 14px;
    }
}
@media (max-width: 414px) {
    .tableHeader {
        font-size: 10px;
    }
    .ConentContainer {
        font-size: 10px;
    }
}
.col1 {
    flex: 1;
    padding: 10px;
    background-color: #D8D8D8;
    color: #000000;
}
.commonBack1 {
    background-color: #E7E9EC !important;
}
.commonBack2 {
    background-color: #ECEAED !important;
}
.commonBack3 {
    background-color: #F4ECEE !important;
}
.col2 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    position: relative;
    background-color: #CFD4DA;
    color: #355070;
}
.col3 {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    position: relative;
    background-color: #E8D8DC;
    color: #b56576;
}
@media (max-width: 500px) {
    .col1 {
        padding: 5px;
    }
    .col2 {
        padding: 5px;
    }
    .col3 {
        padding: 5px;
    }
}
.colHeader {
    flex: 1;
}
.viewMore {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
    color: #000000;
    letter-spacing: 0.5px;
    text-align: center;
    font-weight: bold;
    background: #BFEAB4;
    border: 0px;
    cursor: pointer;
}
.downArrow {
    width: 20px;
}
.upArrow {
    width: 20px;
    transform: rotate(180deg);
}