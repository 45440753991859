.tableContainer {
    width: 100%;
    max-height: 45vh;
    min-height: fit-content;
    display: flex;
    flex-direction: row;
    transition: all 1s linear;
    border: 1px solid rgba(151,151,151,0.50);
}
@media (max-width: 1024px) {
    .tableContainer {
        flex-direction: column;
    }
}
@media (max-height: 500px) {
    .tableContainer {
        height: 300px;
    }
}
.tableCol1 {
    display: flex;
    flex-direction: column;
}
@media (max-width: 1024px) {
    .tableCol1 {
        flex-direction: row;
    }
}
.tableHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: #DBFAFA;
    border: 0 solid #DADADA;
    font-weight: 600;
    font-size: 1.5834348355663825vh;
    color: #355070;
    letter-spacing: 1px;
    text-align: center;
    z-index: 100;
}
.skillTab {
    flex: 1;
    width: 11vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 1.94884287454324vh;
    font-weight: 600;
    color: #4D4D4D;
    letter-spacing: 0.9px;
    background: #FFFFFF;
    border-right: 1px solid #CFCFCF;
    cursor: pointer;
    text-align: center;
}
.skillCountTab {
    flex: 1;
    width: 11vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: #DAE8FF;
    font-size: 1.94884287454324vh;
    font-weight: 600;
    color: #2673F1;
    letter-spacing: 0.9px;
    border-right: 1px solid #CFCFCF;
    cursor: pointer;
    text-align: center;
    word-break: break-word;
}
@media (max-width: 550px) {
    .skillTab {
        font-size: 12px;
    }
    .skillCountTab {
        font-size: 12px;
    }
}
@media (max-height: 550px) {
    .skillTab {
        font-size: 12px;
    }
    .skillCountTab {
        font-size: 12px;
    }
}
.tableWrap {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1;
    overflow: hidden;
}
.col2Skill {
    display: inline-table;
    align-content: baseline;
    border-right: 1px solid rgba(151,151,151,0.50);
    background: #f0f3f6 !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    overflow-y: auto;
}
.col2Skill::-webkit-scrollbar {
    width: 10px;
    overflow-y: auto;
}
.col2Skill::-webkit-scrollbar-track {
    position: absolute;
    background: #F0F3F6; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  /* Handle */
.col2Skill::-webkit-scrollbar-thumb {
    background: #888; 
}
  
  /* Handle on hover */
.col2Skill::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
@media (max-width: 550px) {
    .col2Skill::-webkit-scrollbar {
        width: 5px;
    }
}
.col3Skill {
    display: inline-table;
    align-content: baseline;
    background: #f0f3f6 !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    border-left: 1px solid rgba(151,151,151,0.50);
    overflow-y: auto;
}
.col3Skill::-webkit-scrollbar {
    width: 10px;
    overflow-y: auto;
}
.col3Skill::-webkit-scrollbar-track {
    position: absolute;
    background: #F0F3F6; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  /* Handle */
.col3Skill::-webkit-scrollbar-thumb {
    background: #888; 
}
  
  /* Handle on hover */
.col3Skill::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
@media (max-width: 550px) {
    .col3Skill::-webkit-scrollbar {
        width: 5px;
    }
}
.skillCountWrap {
    overflow-y: auto;
}
.skillCountWrap::-webkit-scrollbar {
    width: 10px;
    overflow-y: auto;
}
.skillCountWrap::-webkit-scrollbar-track {
    position: absolute;
    background: #F0F3F6; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  /* Handle */
.skillCountWrap::-webkit-scrollbar-thumb {
    background: #888; 
}
  
  /* Handle on hover */
.skillCountWrap::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
@media (max-width: 550px) {
    .skillCountWrap::-webkit-scrollbar {
        width: 5px;
    }
}
.ConentContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    font-size: 2.192448233861145vh;
    color: #000000;
    letter-spacing: 0;
    overflow: hidden;
}
@media (max-width: 1160px) {
    .ConentContainer {
        height: auto;
    }
}
@media (max-width: 600px) {
    .tableHeader {
        font-size: 18px;
    }
    .ConentContainer {
        font-size: 16px;
    }
}
@media (max-width: 550px) {
    .tableHeader {
        font-size: 12px;
    }
    .ConentContainer {
        font-size: 10px;
    }
}
@media (max-height: 550px) {
    .tableHeader {
        font-size: 12px;
    }
    .ConentContainer {
        font-size: 10px;
    }
}
@media (max-width: 320px) {
    .tableHeader {
        font-size: 10px;
    }
}
.col1 {
    flex: 1;
    display: flex;
    padding: 10px;
    background: #CFD4DA;
}
.col2 {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #6D597A;
    text-align: center;
    position: relative;
    background: #DAD6DC;
}
.col3 {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    position: relative;
    background: #E8D8DC;
    color: #B56576;
}
@media (max-width: 500px) {
    .col1 {
        padding: 5px;
    }
    .col2 {
        padding: 5px;
    }
    .col3 {
        padding: 5px;
    }
}
.col4 {
    width: 10px;
    background-color: #E8D8DC;
}
@media (max-width: 550px) {
    .col4 {
        width: 5px;
    }
}
.colHeader {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.viewMore {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
    color: #000000;
    letter-spacing: 0.5px;
    text-align: center;
    font-weight: bold;
    background: #BFEAB4;
    border: 0px;
    cursor: pointer;
}
.downArrow {
    width: 20px;
}
.upArrow {
    width: 20px;
    transform: rotate(180deg);
}
@media (max-width: 550px) {
    .downArrow {
        width: 12px;
        padding: 2px;
    }
    .upArrow {
        width: 12px;
        padding: 2px;
        transform: rotate(180deg);
    }
}
.skillTextStyle {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    border-radius: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 8px;
    padding-left: 8px;
    margin: 5px;
    font-size: 1.218026796589525vh;
    color: #000000;
    letter-spacing: 0.75px;
    text-align: center;
    margin-right: 15px;
}
@media (max-width: 550px) {
    .skillTextStyle {
        font-size: 10px;
        margin: 3px;
        margin-right: 6px;
        padding-right: 4px;
        padding-left: 4px;
    }
}
@media (max-height: 550px) {
    .skillTextStyle {
        font-size: 10px;
    }
}
.starStyle {
    width: 14px;
    margin-left: 5px;
}
.skillLength {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 50%;
    background: #FFFFFF;
    margin-left: 10px;
}
.skillCountRow {
    background: #FFFFFF !important;
}
.skillCountRowBorder1 {
    font-size: 1.705237515225335vh;
    color: #000000;
    border-right: 1px solid rgba(151,151,151,0.50);
    word-break: break-all;
}
.skillCountRowBorder2 {
    font-size: 1.8270401948842874vh;
    color: #000000;
    border-right: 1px solid rgba(151,151,151,0.50);
}
.skillCountRowBorder3 {
    font-size: 1.8270401948842874vh;
    color: #000000;
}
@media (max-width: 500px) {
    .skillCountRowBorder1 {
        font-size: 10px;
    }
    .skillCountRowBorder2 {
        font-size: 10px;
    }
    .skillCountRowBorder3 {
        font-size: 10px;
    }
}
@media (max-height: 500px) {
    .skillCountRowBorder1 {
        font-size: 10px;
    }
    .skillCountRowBorder2 {
        font-size: 10px;
    }
.skillCountRowBorder3 {
        font-size: 10px;
    }
}
.countComparison {
    flex: 1;
}
.countCol1 {
    border-right: 1px solid rgba(151,151,151,0.50);
}
.countCol2 {
    border-right: 1px solid rgba(151,151,151,0.50);
}
.deleteSkillsWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    bottom: 0;
    background: #D8D8D8;
    padding: 0.4rem;
}
.deleteSkill {
    font-size: 1.46163215590743vh;
    padding: 0.4rem;
    /* background: #FFFFFF;
    color: #2673F1; */
    font-weight: 600;
}
.deleteSkill:hover {
    /* box-shadow: 0 2px 10px 0 rgba(123, 124, 126, 0.9); */
}
@media (max-width: 500px) {
    .deleteSkill {
        font-size: 10px;
    }
}
@media (max-height: 500px) {
    .deleteSkill {
        font-size: 10px;
    }
}
.CancelButton {
    min-width: 100px;
    background: #FFFFFF;
    color: #000000;
    margin-right: 20px;
}
.CancelButton:hover {
    box-shadow: 0 2px 10px 0 rgba(123, 124, 126, 0.9);
}
.doneButton {
    min-width: 100px;
}
.doneButton:disabled {
    opacity: 0.5;
    /* background: #ffffff !important; */
    cursor: not-allowed;
}
@media (max-width: 500px) {
    .CancelButton {
        min-width: 70px;
        padding: 0.4rem !important;
        margin-right: 10px;
    }
    .doneButton {
        min-width: 70px;
        padding: 0.4rem !important;
    }
}
.deleteCount {
    font-size: 1.218026796589525vh;
    color: #355070;
    font-weight: 500;
    font-style: italic;
    position: absolute;
    right: 10px;
}
@media (max-width: 500px) {
    .deleteCount {
        font-size: 9px;
        right: 2px;
    }
}
@media (max-height: 500px) {
    .deleteCount {
        font-size: 8px;
        right: 2px;
    }
}
.resetButton {
    display: flex;
    align-items: center;
}
.quiteStyle {
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin-left: 10px;
}
.deletebuttonWrap {
    display: flex;
    align-items: center;
}
.skillDeleteLabel {
    font-size: 1.46163215590743vh;
    color: #4D4D4D;
    font-weight: 600;
    margin-right: 20px;
}
@media (max-width: 500px) {
    .skillDeleteLabel {
        font-size: 10px;
        margin-right: 8px;
    }
}
@media (max-height: 500px) {
    .skillDeleteLabel {
        font-size: 8px;
    }
}