@import '../../../../../sass/variables';

.container {
  width: 100%;
  font-family: "Lato", sans-serif !important;
  padding-top: 20px;
  padding-bottom: 50px;

  .title {
    font-family: "Lato", sans-serif !important;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    padding-top: 30px;
  }

  .applicationImage {
    padding-top: 20px;
    width: 100%;
  }

  .subTitle {
    font-family: "Lato", sans-serif !important;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    padding-top: 30px;
  }

  .text {
    .table {
      border-collapse: collapse;
      th, td {
        border-style: solid;
        border-color: rgba(231, 223, 223, 1);
        border-width: 1.5px;
        width: 50%;
        font-weight: 500;
      }
      th {
        padding: 8px 0 8px 10px;
        text-align: left;
        background-color: rgba(239, 81, 35, 0.08);
      }
      td {
        padding: 15px 20px 15px 35px;
      }
    }
  }

  .list {
    padding-left: 15px;
    line-height: 30px;
    font-size: 18px;
    .listItem {
      padding-top: 13px;
    }
  }

  .listText {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 5px;

    .list {
      .listItem {
        padding-top: 5px;
        .content {
          display: flex;
          align-items: center;
          gap: 18px;
          padding-top: 5px;
          padding-bottom: 10px;
          .bulb {
            height: 45px;
          }
        }
      }
    }
  }

  .listWithNestedContent {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 5px;

    .list {
      .listItem {
        padding-top: 30px;
        .nestedList {
          padding-left: 15px;
          list-style-type: disc !important;
          .nestedListItem {}
        }
        .highlight {
          width: 110%;
          padding: 10px 10px 10px 25px;
          background-color: rgba(239, 81, 35, 0.08);
        }
      }
    }
  }

  .text {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-top: 20px;
  }
}