.progressBarWrap {
    width: 20%;
}
@media (max-width: 550px) {
    .progressBarWrap {
        width: 50%;
    }
}
.progressBarWrap > div > div > div:nth-child(2) {
    margin: 0 auto !important;
    top: 0;
}
.dashboard {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.innerCircle {
    display: flex;
    overflow: visible;
}
.innerCircle > text {
    font-size: 2.47168vh;
    font-weight: bold;
    color: #000000;
    text-anchor: middle;
    dominant-baseline: middle;
}
@media (max-height: 550px) {
    .innerCircle > text {
        font-size: 4.47168vh;
    }
}