@import '../../sass/variables';

.container {
    width: 100%;
    height: 100%;
    font-weight: 16 * $vw-const;
    line-height: 1.5;
    .headerContainer {
      width: 100%;
      height: clamp(400px, 100vh, 100vh);
      background-image: url('../../assets/score/bg.png');
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #000;
      background-position: bottom;
      background-position-x: -20px;
      background-color: #fafafa;
    }

    .headerSection {
          width: 78%;
          margin: 0 auto;
          display: flex;
          align-items: center;
       
          .left{
              width: 680 * $vw-const;
              margin-right: 30px;
              .headText {
                  font-size: clamp(20px, 35 * $vw-const, 35 * $vw-const);
                  font-weight: 700;
                  line-height: 1.5;
                  margin-top: 40px;
                  width: 680 * $vw-const;
              }
              .freeText {
                background: #FF5D47;
                color: #ffffff;
                border-radius: 10px;
                padding: 0px 10px;
              }
              .content {
                  font-size: clamp(14px, 20 * $vw-const, 20 * $vw-const);
                  font-weight: 300;
                  color: #646464;
                  margin-top: 10px;
                  margin-bottom: 20px;
                  line-height: 1.5;
                  width: 620* $vw-const;
              }
              .fileUploadContainer {
                border: 1px solid #CAC8C8;
                padding: 20px 20px 20px 20px;
                border-radius: 20px;
                background-color: rgba(255,255,255, 0.5);
              }
          }
          
    
    }

    .blackStripContainer {
          background-color: black;
          height: 12vh;
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          font-size: 1rem;
          color: white;
      
          .bigFont {
              font-size: 1.5rem;
              margin-right: 20px;
          }
      
    }
      
      @media (max-width: 1023px) {


        .headerSection {
            width: 90%;
         
            .left{
                width: 100%;
                margin-right: 30px;
                .headText {
                    width: 100%;
                }
                .content {
                    width: 100%;
                }
                .fileUploadContainer {
                  border: 1px solid #CAC8C8;
                  padding: 30px 20px 20px 20px;
                  border-radius: 20px;
                  background-color: rgba(255,255,255, 0.5);
                }
            }
            .right {
                width: 40%;
            }
      
      }




          .blackStripContainer {
              height: 125px;
              > div {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  text-align: center;
                  font-size: 13px;
              }
          }
          .bigFont {
              font-size: 24px !important;    
              font-weight: 600;   
              margin-right: 0px!important;
          }
          .trySample {
          font-size: 14px !important;
          padding: 8px 5px!important;
          
          }
      }

}




@media (max-width: 1024px) {
    
}


.overLay {
   background-color: #fafafa;
   width: 100%;
   height: 100%;
}


.bold {
    font-weight: bold !important;
}
.activeInstruction {
    font-weight: 500 !important;
}

.height100 {
    height: 100% !important;
}
.width100 {
    width: 100%!important;
    margin-bottom: 100px;
}
.opacity1 {
    background-color: rgba(255,255,255, 0.9)!important;
}

.landingPageContentContainer {
    // height: 55vh;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10vh;
    height: 55vh;
    background-color: transparent !important;
    margin-top: 7vh;
}


@media (max-width: 1024px) {
    .landingPageContentContainer {
        height: 100% !important;
    }

    .LandingPageContainer {
        padding-top: 3%;
        padding-bottom: 5%;
        flex-direction: column;
    }
}

@media (max-height: 720px) {
    .landingPageContentContainer {
        height: 100% !important;
    }
}

@media (max-width: 550px) {
    .LandingPageContainer {
        padding-top: 10%;
        padding-bottom: 5%;
    }
}

.col1 {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 1%;
    z-index: 10;

    .heading {
        font-size: clamp(20px, 32 * $vw-const, 32 * $vw-const);

        font-weight: bold;
        margin-bottom: 20px;
    }

    .content,
    .points {
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        margin-bottom: 20px;
    }

    .points {
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        margin-top: 20px;
        width: 100%;

        .pointContent {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 20px;
        }

        .point {
            width: 20px;
            height: 20px;
            background-color: #4936D3;
            color: #FFFFFF;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }
    }

}

@media (max-width: 1024px) {
    .col1 {
        padding-left: 0px;
    }
}

@media (min-width: 1024px) and (max-height: 800px) {
    .col1 {
        padding-top: 5%;
        padding-bottom: 2%;
    }
}

.contentWrap {
    width: 100%;
    padding-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (max-width: 1024px) {
    .contentWrap {
        padding-left: 0px;
        align-items: center;
    }
}

.shortContent {
    width: 77%;
    margin-top: 20px;
    font-size: 1.58344vh;
    font-weight: 500;
    color: #130e49;
    letter-spacing: 0.6px;
}

@media (max-width: 1024px) {
    .shortContent {
        width: 85%;
        text-align: center;
    }
}

@media (max-width: 550px) {
    .shortContent {
        width: 90%;
    }
}

@media (max-height: 550px) {
    .shortContent {
        font-size: 2.46163vh;
    }
}

.col2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: all 1s linear;
    z-index: 10;
    padding-top: 2.43605359317905vh;
    box-shadow: 3px 3px 20px #cccccc;
    border-radius: 10px;
    background-color: transparent;
    padding: 20px;
    background-color: rgba(240, 240, 240, 0.9);
}

.trySampleWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 16px;
}

.orStyle {
    font-size: 1.58344vh;
    font-weight: 600;
    color: #130E49;
    letter-spacing: 0.65px;
    margin-bottom: 20px;
}

@media (max-width: 550px) {
    .orStyle {
        margin-bottom: 10px;
    }
}

@media (max-height: 550px) {
    .orStyle {
        font-size: 2.67112vh;
    }
}

.sampleWrap {
    display: flex;
    align-items: center;
}

.sampleLable {
    font-size: clamp(12px, 14px, 14px);
    font-weight: 400;
    color: #000;
    letter-spacing: 0.65px;

}

@media (max-width: 550px) {
    .sampleLable {
        margin-right: 0px;
    }
}

@media (max-height: 550px) {
    .sampleLable {
        font-size: 2.67112vh;
    }
}

.onUploadInputField {
    height: 6.284vh !important;
    //  background-color: #F5FAFF !important;
    border: 1px dashed #007AFF !important;
    transition: height 0.6s linear;
}

@media (max-width: 1024px) {
    .onUploadInputField {
        // height: 10.284vh !important;
    }
}

@media (max-height: 550px) {
    .onUploadInputField {
        height: 10vh !important;
    }
}

.popupStyle {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh);
    background: rgba(0, 0, 0, 0.3);
    z-index: 1400;
}

.animationFullPage {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100% !important;
    z-index: 1300;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-height: 550px) {
    .animationFullPage {
        height: 100% !important;
    }
}

.trySample {
    font-size: clamp(12px, 14px, 14px);
    font-weight: 300;
    background: transparent !important;
    border: 0px solid #4936D3 !important;
    color: #EF5123 !important;
    letter-spacing: 0.45px !important;
    padding-left: 5px;
}

.trySample:hover {
    color: #EF5123 !important;
    text-decoration: underline;
    box-shadow: none;
}

@media (max-height: 550px) {
    .trySample {
        font-size: 2.67112vh !important;
    }
}




.instructionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .step {
        font-size: clamp(10px, 14 * $vw-const, 14 * $vw-const);
        font-weight: 300;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 160px;
        position: relative;
        flex:1;
    }
    svg {
        margin-top: 26px;
        position: absolute;
        left: 60%;
        width: 80%;
       
    }
    .circle {
        width: 10px;
        height: 10px;
        background-color: gray;
        border-radius: 50%;
        margin-top: 10px;
      }
    .currentStep {
        font-weight: bold;
      
    }
    .activeInstruction .circle {
        background-color: #000;
    }
    
}

.blackStripContainer {
    background-color: #040935;
    height: clamp(236px, 12vh, 12vh);
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-size: 1rem;
    color: white;
    text-align: center;
    .blackStripContainerChild {
        width: 78%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .bigFont {
        font-size: clamp(20px, 42 * $vw-const, 42* $vw-const);
        margin-right: 20px;
       line-height: 2;
      
    }

}
.reportDisplayContainer {
    display: flex;
    width: 78%;
    margin: 0 auto;
    justify-content: space-between;
    margin-top: 100px;
    align-items: center;
    .content {
        font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
        width: 45%;
    }
    .sampleReportImage {
        width: clamp(200px, 750* $vw-const, 750* $vw-const);
    }
}
.featuresMainContainer {
    width: 78%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
    margin-top: 80px;
    .featuresContainer {
        display: flex;
        justify-content: space-between;
    }
    .heading {
        font-size: clamp(20px, 32 * $vw-const, 32* $vw-const);
        font-weight: bold;
        margin-bottom: 20px;
    }
    .feature {
        width: clamp(200px, 480* $vw-const, 480* $vw-const);
        
        .featureHeading {
            font-size: clamp(16px, 20 * $vw-const, 20* $vw-const);
            font-weight: bold;
            margin-bottom: 10px;
        }
        .featureContent {
            font-size: clamp(16px, 20 * $vw-const, 20* $vw-const);
            font-weight: 400;
        }
        .featureIcon {
            width: clamp(50px, 70 * $vw-const, 70* $vw-const);
            margin-bottom: 20px;
        }
    }

}
.calculateScoreContainer {
    width: 78%;
    margin: 0 auto;
    font-size: clamp(16px, 20 * $vw-const, 20* $vw-const);
    .heading {
        font-size: clamp(20px, 32 * $vw-const, 32* $vw-const);
        font-weight: bold;
        margin-bottom: 50px;
        text-align: center;
    }
    .stepsContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 300px;
        flex: 1;
    }
    .left {
        flex: 1;
        display: flex;
        justify-content: center;
        video {
            width: clamp(200px, 547 * $vw-const, 547* $vw-const);;
        }
    }
    .right {
        margin-bottom: 20px;
        flex: 1;
        .point {
            font-size: clamp(16px, 20 * $vw-const, 20* $vw-const);
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .pointDesc {
            margin-left: 44px;
        }
        .dashedLine {
            border-bottom: 1px dashed #A6AFCC;
            margin-left: 44px;
            margin-top: 20px;
            margin-bottom: 20px;
 
        }
        .bold {
            font-weight: 800;
        }
        .step {
            width: 34px;
            height: 34px;
            color: #ffffff;
            border-radius: 50%;
            font-size: clamp(14px, 20 * $vw-const, 20* $vw-const);
            background-color: #D9D9D9;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            margin-right: 10px;
        }
    }
}  

.buildResumeContainer {
    background-color: #040935;
    width: 100%;
    .buildResumeCotainerChild {
        width: 78%;
        margin: 0 auto;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 50px;
        padding-bottom: 50px;

        .left {
            width: clamp(300px, 620 * $vw-const, 620* $vw-const);
            .heading {
                font-size: clamp(22px, 42 * $vw-const, 42* $vw-const);
                font-weight: bold;
                margin-bottom: 20px;
            }
            .content {
                font-size: clamp(16px, 20 * $vw-const, 20* $vw-const);
               
            }
            .btn1 {
                background-color: #EF5123;
                font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
                font-weight: 600;
                color: white;
                border-radius: 10px;
                padding: 10px 24px 10px 24px;
                margin-top: 30px;
            }
        }
        .right {
         
            .buildResumeFlowImage {
                width: clamp(300px, 750 * $vw-const, 750* $vw-const);
            }
        }
    }
   

   
}

@media (max-width: 1023px) {
    .blackStripContainer {
        height: 125px;
        .blackStripContainerChild {
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 13px;
            width: 50%;
            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                font-size: 13px;
            }
        }
       
    }
    .bigFont {
        font-size: 24px !important;    
        font-weight: 600;   
        margin-right: 0px!important;
        line-height: 1.5!important;
    }
    .trySample {
    font-size: 14px !important;
    padding: 8px 5px!important;
    
    }
    .reportDisplayContainer {
        .content {
            font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
            width: 45%;
        }
        .sampleReportImage {
            width: clamp(200px, 750* $vw-const, 750* $vw-const);
        }
    }


}

@media (max-width: 768px) {
    .blackStripContainer {
        height: 125px;
        .blackStripContainerChild {
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 13px;
            width: 100%;
            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                font-size: 13px;
            }
        }
       
    }
    .bigFont {
        font-size: 24px !important;    
        font-weight: 600;   
        margin-right: 0px!important;
        line-height: 1.5!important;
    }
    .trySample {
    font-size: 14px !important;
    padding: 8px 5px!important;
   // width: 200px;
    }
    .reportDisplayContainer {
        width: 90%;
        flex-direction: column;
        .content {
            font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
            width: 90%;

        }
        .sampleReportImage {
            width: clamp(90%, 750* $vw-const, 750* $vw-const);
            margin-top: 50px;
        }
    }

    .featuresMainContainer {
        width: 85%;
        margin-bottom: 50px;
        margin-top: 80px;
        .featuresContainer {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }
        .heading {
            font-size: clamp(20px, 32 * $vw-const, 32* $vw-const);
            font-weight: bold;
            margin-bottom: 10px;
        }
        .feature {
            width: 100%;
            
            .featureHeading {
                font-size: clamp(16px, 20 * $vw-const, 20* $vw-const);
                font-weight: bold;
                margin-bottom: 10px;
               
            }
            .featureContent {
                font-size: clamp(16px, 20 * $vw-const, 20* $vw-const);
                font-weight: 400;
            }
            .featureIcon {
                width: clamp(50px, 70 * $vw-const, 70* $vw-const);
                margin-bottom: 10px;
                margin-top: 30px;
            }
        }
    
    }

    .calculateScoreContainer {
        width: 90%;
        .stepsContainer {
            flex-direction: column;
        }
    }  

    .buildResumeContainer {
        background-color: #040935;
        width: 100%;
        .buildResumeCotainerChild {
            flex-direction: column;
            width: 90%;
            .left, .buildResumeFlowImage {
                width: 100%!important;
            }
           
        }
    }
}