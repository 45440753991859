@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

.feedbackContainer {
    position: fixed;
    right: 0px;
    top: 50%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background: #4936D3;
    fill: white;
    z-index: 3;
    .upSvg,
    .downSvg {
        width: 30px;
        height: 30px;
        padding: 10px;
        margin-bottom: 20px;
        cursor: pointer;

    }

    .upSvg:hover,
    .green {
        fill: green;
    }

    .downSvg:hover,
    .red {
        fill: red;
    }


    .animate__fadeInRightBig {
        -webkit-animation-name: fadeInRightBig;
        animation-name: fadeInRightBig
    }

    .feedBackBoxContainer {
        width: 300px;
        height: 140px;
        padding: 20px;
        box-shadow: 0 7px 30px 0 #444746;
        border-radius: 5px;
        position: fixed;
        z-index: 1;
        right: 0;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        // -webkit-animation-duration: 0.5s;
        // animation-duration: 0.5s;
        // -webkit-animation-fill-mode: both;
        // animation-fill-mode: both;
        // // -webkit-animation-name: fadeInRightBig;
        // animation-name: fadeInRightBig;
        flex-direction: column;

        .feedbackBox {
            width: 90%;
            height: 90%;
            margin-bottom: 10px;
            padding: 10px;
            resize: none;
        }
    }
}