.tableContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: all 1s linear;
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
}
@media (max-height: 500px) {
    .tableContainer {
        height: 300px !important;
    }
}
.tableHeader {
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: row;
    background: #DBFAFA;
    border: 0 solid #DADADA;
    font-size: 2.192448233861145vh;
    color: #130E49;
    letter-spacing: 0;
    font-weight: 600;
}
@media (max-width: 500px) {
    .tableHeader {
        font-size: 12px !important;
    }
}
@media (max-height: 500px) {
    .tableHeader {
        font-size: 12px !important;
    }
}
.actionRowWrap {
    flex: 1;
    overflow-y: auto;
}
.actionRowWrap::-webkit-scrollbar {
    width: 10px;
    overflow-y: auto;
}
.actionRowWrap::-webkit-scrollbar-track {
    position: absolute;
    background: #F0F3F6; 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }
  /* Handle */
.actionRowWrap::-webkit-scrollbar-thumb {
    background: #888; 
}
  
  /* Handle on hover */
.actionRowWrap::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
@media (max-width: 550px) {
    .actionRowWrap::-webkit-scrollbar {
        width: 5px;
    }
}
.ConentContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
}
@media (max-width: 600px) {
    .tableHeader {
        font-size: 18px;
    }
    .ConentContainer {
        font-size: 16px;
    }
}
@media (max-width: 500px) {
    .tableHeader {
        font-size: 14px;
    }
    .ConentContainer {
        font-size: 14px;
    }
}
@media (max-width: 414px) {
    .tableHeader {
        font-size: 10px;
    }
    .ConentContainer {
        font-size: 10px;
    }
}
.columnHeader {
    text-align: center;
}
.col1 {
    width: 11vw;
    max-width: 14vw;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #D8D8D8;
    font-size: 1.5834348355663825vh;
    color: #000000;
    letter-spacing: 1px;
    text-align: center;
}
@media (max-width: 550px) {
    .col1 {
        width: 20vw;
        min-width: 80px;
        font-size: 12px;
    }
}
@media (max-height: 500px) {
    .col1 {
        font-size: 12px;
    }
}
.categoryStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-right: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
}
.categoryActionVerbCount {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    padding: 0.3rem;
    border-radius: 50%;
}
.categoryName {
    font-size: 1.5834348355663825vh;
    font-weight: 600;
    color: #000000;
    letter-spacing: 0.9px;
    text-align: center;
    margin-bottom: 20px;
    word-break: break-all;
}
@media (max-width: 900px) {
    .categoryName {
        font-size: 15px;
    }
}
@media (max-width: 550px) {
    .categoryName {
        font-size: 10px;
    }
    .categoryActionVerbCount {
        font-size: 10px;
    }
}
@media (max-height: 550px) {
    .categoryName {
        font-size: 10px;
    }
    .categoryActionVerbCount {
        font-size: 10px;
    }
}
.col2 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    position: relative;
    background-color: #CFD4DA;
    font-size: 1.5834348355663825vh;
    color: #355070;
    letter-spacing: 1px;
    text-align: center;
}
@media (max-width: 550px) {
    .col2 {
        font-size: 12px;
    }
}
@media (max-height: 500px) {
    .col2 {
        font-size: 12px;
    }
}
.actionVerbFound {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: baseline;
    background-color: #F0F3F6;
    border-right: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
}
.col3 {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    text-align: center;
    position: relative;
    background-color: #E8D8DC;
    font-size: 1.5834348355663825vh;
    color: #B56576;
    letter-spacing: 1px;
}
@media (max-width: 550px) {
    .col3 {
        font-size: 12px;
    }
}
@media (max-height: 550px) {
    .col3 {
        font-size: 12px;
    }
}
.col4 {
    width: 10px;
    background-color: #E8D8DC;
}
@media (max-width: 550px) {
    .col4 {
        width: 5px;
    }
}
.recommendedVerbs {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: baseline;
    background-color: #F0F3F6;
    border-right: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
}
@media (max-width: 500px) {
    .col1 {
        padding: 5px;
    }
    .col2 {
        padding: 3px;
    }
    .col3 {
        padding: 3px;
    }
}
.colHeader {
    flex: 1;
}
.viewMore {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
    color: #000000;
    letter-spacing: 0.5px;
    text-align: center;
    font-weight: bold;
    background: #BFEAB4;
    border: 0px;
    cursor: pointer;
}
.downArrow {
    width: 20px;
}
.upArrow {
    width: 20px;
    transform: rotate(180deg);
}
.skillTextStyle {
    background: #FFFFFF;
    border-radius: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 8px;
    padding-left: 8px;
    margin: 5px;
    font-size: 1.218026796589525vh;
    color: #000000;
    letter-spacing: 0.75px;
    text-align: center;
    margin-right: 15px;
    word-break: break-all;
}
@media (max-width: 550px) {
    .skillTextStyle {
        font-size: 10px;
        margin: 3px;
        margin-right: 6px;
        padding-right: 4px;
        padding-left: 4px;
        word-break: break-all;
    }
}
@media (max-height: 550px) {
    .skillTextStyle {
        font-size: 10px;
        margin: 3px;
        margin-right: 6px;
        padding-right: 4px;
        padding-left: 4px;
    }
}
.EmptyList {
    flex: 1;
    font-size: 1.8270401948842874vh;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
}