.saveJdContainer {
    width: 40%;
    height: auto;
    background: #FCF8FF;
    border-radius: 5px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 550px) {
    .saveJdContainer {
        width: 70%;
    }
}
.titleStyle {
    font-size: 3.04507vh;
    font-weight: 600;
    color: #252525;
    letter-spacing: 1.25px;
    text-align: center;
    margin-bottom: 40px;
}
@media (max-width: 550px) {
    .titleStyle {
        font-size: 2.04507vh;
    }
}
.inputWrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 15px;
}
@media (max-width: 550px) {
    .inputWrap {
        padding-bottom: 15px;
    }
}
.labelStyle {
    font-size: 2.43605vh;
    font-weight: 600;
    color: #707070;
    letter-spacing: 1px;
}
@media (max-width: 550px) {
    .labelStyle {
        font-size: 1.3605vh;
    }
}
@media (max-height: 550px) {
    .labelStyle {
        font-size: 10px;
    }
}
.inputFieldStyle {
    width: 100%;
    height: 4.874vh;
    background: #E9E9E9;
    border-radius: 5px;
    font-size: 1.82704vh;
    color: #707070;
    color: #000000;
    letter-spacing: 0.75px;
    padding: 5px;
    outline: none;
    border: 0px;
    margin-top: 5px;
}
@media (max-height: 550px) {
    .inputFieldStyle {
        font-size: 12px;
        height: 30px;
    }
}
.buttonWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
@media (max-width: 550px) {
    .buttonWrap {
        width: auto;
    }
}
.cancelButtonStyle {
    width: 100px;
    background: #959595;
    border-radius: 5px;
    font-weight: 600;
    margin-right: 20px;
}
.cancelButtonStyle:hover {
    box-shadow: 0 2px 10px 0 rgba(126, 126, 126, 0.9);
}
.saveButtonStyle {
    width: 100px;
    font-weight: 600;
}