.home {
    width: 65%;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    margin-top: 6%;
    z-index: 10;
  }
  @media (max-width: 1440px) {
    .home {
        width: 80%;
        padding-bottom: 0;
    }
  }
  @media (max-width: 1200px) {
    .home {
        width: 90%;
        align-items: center;
        padding-right: 0px;
        padding-bottom: 25px;
    }
  }
  @media (max-width: 1150px) {
    .home {
      padding-bottom: 0px;
    }
  }
  @media (max-width: 850px) {
    .home {
        width: 90%;
        align-self: center;
        padding-right: 0px;
        padding-bottom: 20px;
        margin-left: 0px;
    }
  }
  @media (max-width: 600px) {
    .home {
        width: 95%;
        align-items: center;
        padding-right: 0px;
        margin-top: 50px;
    }
  }
  @media (max-width: 414px) {
    .home {
        padding-top: 0px;
        padding-bottom: 20px;
    }
  }
  @media (max-height: 414px) {
    .home {
        margin-top: 20px;
    }
  }
  .content {
    font-size: 40px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 20px;
  }
  @media (max-width: 1700px) {
      .content {
          font-size: 30px;
      }
  }
  @media (max-width: 1500px) {
    .content {
        font-size: 30px;
    }
  }
  @media (max-width: 1024px) {
      .content {
          font-size: 30px;
      }
  }
  @media (max-width: 700px) {
    .content {
        font-size: 24px;
    }
  }
  @media (max-width: 414px) {
      .content {
          font-size: 16px;
          letter-spacing: 1.69px;
      }
  }
  @media (max-height: 414px) {
    .content {
        font-size: 24px;
        letter-spacing: 1.69px;
    }
}
  .contentType {
    opacity: 0.8;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }
  @media (max-width: 1680px) {
    .contentType {
        font-size: 16px;
    }
  }
  @media (max-width: 1500px) {
    .contentType {
        font-size: 14px;
    }
  }
  @media (max-width: 940px) {
    .contentType {
      text-align: center;
    }
  }
  @media (max-width: 414px) {
      .contentType {
          font-size: 12px;
          letter-spacing: 0.74px;
      }
  }