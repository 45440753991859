@import '../../sass/variables';


.LandingPageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

}

.overLay {
    background-image: linear-gradient(to top right, transparent 50%, #ccc 100%), url('../../assets/icons/hands.png') !important;
    background-size: 100% !important;
    background-repeat: no-repeat;
}

// body {
//     background-color: #F3F3F3;
// }

.bold {
    font-weight: bold !important;
}

.height100 {
    height: 100% !important;
}

.landingPageContentContainer {
    // height: 55vh;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10vh;
    height: 55vh;
    background-color: transparent !important;
    margin-top: 7vh;
}


@media (max-width: 1024px) {
    .landingPageContentContainer {
        height: 100% !important;
    }

    .LandingPageContainer {
        padding-top: 3%;
        padding-bottom: 5%;
        flex-direction: column;
    }
}

@media (max-height: 720px) {
    .landingPageContentContainer {
        height: 100% !important;
    }
}

@media (max-width: 550px) {
    .LandingPageContainer {
        padding-top: 10%;
        padding-bottom: 5%;
    }
}

.col1 {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 1%;
    z-index: 10;

    .heading {
        font-size: clamp(20px, 32 * $vw-const, 32 * $vw-const);

        font-weight: bold;
        margin-bottom: 20px;
    }

    .content,
    .points {
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        margin-bottom: 20px;
    }

    .points {
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        margin-top: 20px;
        width: 100%;

        .pointContent {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            margin-bottom: 20px;
        }

        .point {
            width: 20px;
            height: 20px;
            background-color: #4936D3;
            color: #FFFFFF;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }
    }

}

@media (max-width: 1024px) {
    .col1 {
        padding-left: 0px;
    }
}

@media (min-width: 1024px) and (max-height: 800px) {
    .col1 {
        padding-top: 5%;
        padding-bottom: 2%;
    }
}

.contentWrap {
    width: 100%;
    padding-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (max-width: 1024px) {
    .contentWrap {
        padding-left: 0px;
        align-items: center;
    }
}

.shortContent {
    width: 77%;
    margin-top: 20px;
    font-size: 1.58344vh;
    font-weight: 500;
    color: #130e49;
    letter-spacing: 0.6px;
}

@media (max-width: 1024px) {
    .shortContent {
        width: 85%;
        text-align: center;
    }
}

@media (max-width: 550px) {
    .shortContent {
        width: 90%;
    }
}

@media (max-height: 550px) {
    .shortContent {
        font-size: 2.46163vh;
    }
}

.col2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: all 1s linear;
    z-index: 10;
    padding-top: 2.43605359317905vh;
    box-shadow: 3px 3px 20px #cccccc;
    border-radius: 10px;
    background-color: transparent;
    padding: 20px;
    background-color: rgba(240, 240, 240, 0.9);
}

.trySampleWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.orStyle {
    font-size: 1.58344vh;
    font-weight: 600;
    color: #130E49;
    letter-spacing: 0.65px;
    margin-bottom: 20px;
}

@media (max-width: 550px) {
    .orStyle {
        margin-bottom: 10px;
    }
}

@media (max-height: 550px) {
    .orStyle {
        font-size: 2.67112vh;
    }
}

.sampleWrap {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.sampleLable {
    font-size: 1.33983vh;
    font-weight: 500;
    color: #130E49;
    letter-spacing: 0.65px;
    margin-right: 25px;


}

@media (max-width: 550px) {
    .sampleLable {
        margin-right: 10px;
    }
}

@media (max-height: 550px) {
    .sampleLable {
        font-size: 2.67112vh;
    }
}

.onUploadInputField {
    height: 6.284vh !important;
    //  background-color: #F5FAFF !important;
    border: 1px dashed #007AFF !important;
    transition: height 0.6s linear;
}

@media (max-width: 1024px) {
    .onUploadInputField {
        // height: 10.284vh !important;
    }
}

@media (max-height: 550px) {
    .onUploadInputField {
        height: 10vh !important;
    }
}

.popupStyle {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh);
    background: rgba(0, 0, 0, 0.3);
    z-index: 1400;
}

.animationFullPage {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100% !important;
    z-index: 1300;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-height: 550px) {
    .animationFullPage {
        height: 100% !important;
    }
}

.trySample {
    font-size: 1.33983vh !important;
    font-weight: 400;
    background: transparent !important;
    border: 1px solid #4936D3 !important;
    border-radius: 5px !important;
    color: #4936D3 !important;
    padding: 0.8rem !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    letter-spacing: 0.45px !important;
}

.trySample:hover {
    background: #4936D3 !important;
    color: #FFFFFF !important;
}

@media (max-height: 550px) {
    .trySample {
        font-size: 2.67112vh !important;
    }
}




.instructionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;

    .step {
        font-size: clamp(10px, 16 * $vw-const, 16 * $vw-const);
        font-weight: 400;
        text-align: center;
    }

    .currentStep {
        font-weight: bold;
    }
}

.blackStripContainer {
    background-color: black;
    height: 12vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-size: 1rem;
    color: white;
    margin-top: 10vh;

    .bigFont {
        font-size: 1.5rem;
        margin-right: 20px;
    }

}

@media (max-width: 1023px) {
    .blackStripContainer {
        height: 125px;
        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-size: 13px;
        }
    }
    .bigFont {
        font-size: 24px !important;    
        font-weight: 600;   
        margin-right: 0px!important;
    }
    .trySample {
    font-size: 14px !important;
    padding: 8px 5px!important;
    width: 200px;
    }
}