@import '../../../sass/variables';

.slider {
    background-color: #F2F2F6;
    padding: 50px 0px 0px 0px;
}

.box1 {
    margin-left: 100px;
    margin-bottom: 100px;

    .parentShadow {
        position: relative;
        width: 180px;
        padding: 20px;
        background: #FF5D47;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background-image: url('../../../assets/build-resume-home/Vector.png');
    }

    .arrowBox {
        position: relative;
        width: 200px;
        padding: 20px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        /* shadow effect */
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        z-index: 123;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 50px;
            bottom: -40px;
            z-index: 1;
            /* Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /* Width of the triangle */
            border-right: 50px solid transparent;
            /* Width of the triangle */
            border-top: 50px solid #ffffff;
            /* Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 62px;
        bottom: -40px;
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-right: 60px solid transparent;
        /* Width of the triangle */
        border-top: 60px solid #FF5D47;
        /* Height of the triangle and color */
    }

    /* Height of the triangle container */
}

.box2 {
    margin-top: 50px;
    margin-left: 100px;


    .parentShadow {
        position: relative;
        width: 180px;
        padding: 20px;
        background: #FF5D47;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background-image: url('../../../assets/build-resume-home/Vector.png');
    }

    .arrowBox {
        position: relative;
        width: 200px;
        padding: 20px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        /* shadow effect */
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        z-index: 123;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 140px;
            top: -30px;
            z-index: 1;
            /* Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /* Width of the triangle */
            border-left: 50px solid transparent;
            /* Width of the triangle */
            border-bottom: 50px solid #ffffff;
            /* Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 158px;
        top: -28px;
        /* Height of the triangle container */
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-left: 60px solid transparent;
        /* Width of the triangle */
        border-bottom: 60px solid #FF5D47;
        /* Height of the triangle and color */
    }
}

.box3 {
    margin-top: 50px;
    margin-left: 100px;

    .parentShadow {
        position: relative;
        width: 269px;
        padding: 20px;
        background: #FF5D47;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background-image: url('../../../assets/build-resume-home/Vector.png');
    }

    .arrowBox {
        position: relative;
        width: 430px;
        padding: 40px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        /* shadow effect */
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        z-index: 123;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 140px;
            bottom: -30px;
            z-index: 1;
            /* Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /* Width of the triangle */
            border-left: 50px solid transparent;
            /* Width of the triangle */
            border-top: 50px solid #ffffff;
            /* Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 158px;
        bottom: -28px;
        /* Height of the triangle container */
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-left: 60px solid transparent;
        border-top: 60px solid #FF5D47;
        /* Height of the triangle and color */
    }
}

.resumeBuildHomeContainer {
    width: 100%;
    height: 100%;
    font-weight: 18 * $vw-const;
    line-height: 1.5;


    .header {
        width: 100%;
        margin-top: 0px;
        // background-image: url('../../assets/build-resume-home/bg.png');
        background-color: #F2F2F6;
        background-position: center;
        /* Center the image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
        background-size: cover;
        display: flex;
        align-items: center;
        flex-direction: column;

        justify-content: flex-end;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;

        .pic {
            width: 80* $vw-const;
            height: 80* $vw-const;
            border-radius: 40* $vw-const;
            background: white;
            margin-bottom: 10px;
            fill: gray
        }

        .name {
            font-weight: bold;
            font-size: 28* $vw-const;
            color: #000;

        }

        .text {
            font-size: 20* $vw-const;
            color: #333;
            margin-bottom: 20* $vw-const;
        }

        .blueText {
            color: #4936D3;
            line-height: 1.5;
            font-size: 16* $vw-const;
        }

        .btn {
            background-color: #4936d3;
            margin-bottom: 10* $vw-const;
            margin-top: 30* $vw-const;
            // width: 160* $vw-const;
        }
    }

    .heading {
        font-size: 28 * $vw-const;
        font-weight: bold;
        margin-bottom: 20* $vw-const;
    }

    .content1 {
        display: flex;
        flex: 1;
        justify-content: space-between;
        width: 70%;
        margin: 0 auto;
        margin-top: 100* $vw-const;
        margin-bottom: 100* $vw-const;

        .text {
            font-size: 16 * $vw-const;
        }

        .left {
            width: 45%;
        }

        .content {
            font-weight: 18 * $vw-const;
        }

        .right {
            width: 45%;
        }

    }

    .content2 {
        width: 70%;
        margin: 0 auto;
        margin-top: 100* $vw-const;
        margin-bottom: 100* $vw-const;

        .boxesContainer {
            display: flex;
            width: 70%;

            .boxContainer {
                margin-right: 30px;

                .img {
                    width: 270* $vw-const;
                }

                .head {
                    font-size: 16* $vw-const;
                    font-weight: bold;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .text {
                    font-size: 16* $vw-const;
                }
            }

            .box {
                width: 200 * $vw-const;
                height: 100 * $vw-const;
                background-color: #ccc;
                margin-right: 10px;
            }
        }
    }

    .content3 {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        background-image: url('../../../assets/build-resume-home/bottom.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 400* $vw-const;
        background-color: #000;

        .left {
            width: 70%;
            margin: 0 auto;
            color: #ffffff;
            margin: 0 auto;
            color: #ffffff;
            height: 400* $vh-const;
            justify-content: center;
            display: flex;
            flex-direction: column;

            .text {
                font-size: 24 * $vw-const;
                margin-bottom: 20px;
            }

            .heading {
                font-size: 30 * $vw-const;
                color: #555;
                margin-bottom: 20px;
                margin-top: 20px;

                .priceOld {
                    font-weight: bold;
                    color: white;
                }

                .priceNew {
                    font-weight: bold;
                    color: yellow;
                }
            }

            .btn {}
        }


    }

    .content4 {
        width: 70%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 200px;

        button {
            border: 0;
            padding: 15px;
            width: 250px;
            background: #ccc;
            font-weight: bold;
            font-size: 20px;
            cursor: pointer;
        }

        button:hover {
            background: #999;
        }

    }

    .trySample {
        text-decoration: underline;
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        margin-bottom: 30* $vw-const;
        margin-top: 20* $vw-const;
        cursor: pointer;
    }

    .faqsContainer {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 100px;

        .questionContainer {

            padding: 20px 0px;
            font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);

            .questionAns {
                background-color: #FAFAFA;
                margin-bottom: 20px;
                padding: 10px 40px;
                border-radius: 5px;
            }

            .question {
                font-weight: bold;
                padding-bottom: 10px;
                padding-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

            }

            .open {
                width: 15px;
                height: 15px;
                border-radius: 10px;
                border: 1px solid #DBD9D9;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .close {
                width: 15px;
                height: 15px;
                border-radius: 10px;
                border: 1px solid #DBD9D9;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            .answer {
                padding-top: 20px;
                border-top: 1px solid #DBD9D9;
                margin-bottom: 10px;

            }
        }
    }

    .footer {
        height: 200px;
        background-color: #ccc;
    }
}


@media only screen and (max-width: 768px) {

    .resumeBuildHomeContainer {
        font-weight: clamp(16px,18 * $vw-const, 18 * $vw-const);
    
        .header {
            margin-top: 0px;
            padding-bottom: 20px;
            padding-top: 20px;
            .pic {
                width: 60px;
                height: 60px;
                border-radius: 30px;
                margin-top: 20px;
            }
    
            .name {
                font-size: 20px;
    
            }
    
            .text {
                font-size: 13px;
                margin-bottom: 10px;
            }
    
            .blueText {
                font-size: 12px;
            }
    
            .btn {
                font-size: 14px;
            }
        }
    
        .heading {
            font-size: 20px;
        }
    
        .content1 {
            flex: 1;
            justify-content: space-between;
            width: 100%;
            margin: 0 auto;
            margin-top: 50px;
            margin-bottom: 50px;
            flex-direction: column;
            align-items: center;
    
            .text {
                font-size: 12px;
            }
    
            .left {
                width: 90%;
            }
    
            .content {
                font-weight: 18 * $vw-const;
            }
    
            .right {
                width: 70%;
                margin-top: 30px;
                margin-bottom: 30px;
            }
    
        }
    
        .content2 {
            width: 90%;
            margin: 0 auto;
            margin-top: 50px;
            margin-bottom: 50px;
    
            .boxesContainer {
                display: flex;
                width: 90%;
                flex-direction: column;
                margin: 0 auto;
                .boxContainer {
                    margin-right: 30px;
                    margin-top: 30px;
                    margin-bottom: 30px;

                    .img {
                        width: 100%;
                    }
    
                    .head {
                        font-size: 18px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        text-align: center;
                    }
    
                    .text {
                        font-size: 12px;
                    }
                }
             
            }
        }
    
        .content3 {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            background-image: url('../../../assets/build-resume-home/bottom.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 300px;
            background-color: #000;
            background-position-x: right;
            .left {
                width: 50%;
                color: #ffffff;
                color: #ffffff;
                height: 300px;
                justify-content: center;
                display: flex;
                flex-direction: column;
                margin-left: 50px;
    
                .text {
                    font-size: 16px;
                    margin-bottom: 20px;
                    color: black
                }
    
                .heading {
                    font-size: 20px;
                    color: #000;
                    margin-bottom: 20px;
                    margin-top: 20px;
    
                    .priceOld {
                        font-weight: bold;
                        color: #000;
                    }
    
                    .priceNew {
                        font-weight: bold;
                        color: #8a2be2;
                    }
                }
    
            }
    
    
        }
    
        .content4 {
            width: 70%;
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            text-align: center;
            justify-content: center;
            align-items: center;
            height: 200px;
    
            button {
                border: 0;
                padding: 15px;
                width: 250px;
                background: #ccc;
                font-weight: bold;
                font-size: 20px;
                cursor: pointer;
            }
    
            button:hover {
                background: #999;
            }
    
        }
    
        .trySample {
            text-decoration: underline;
            font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
            margin-bottom: 30* $vw-const;
            margin-top: 20* $vw-const;
            cursor: pointer;
        }
    
        .faqsContainer {
            width: 90%;
            margin: 0 auto;
            margin-bottom: 100px;
           
        }

        
.box1 {
    margin-left: 50px;
    margin-bottom: 50px;

    .parentShadow {
        position: relative;
        width: 150px;
        padding: 20px;
        background: #E6E4F4;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }

    .arrowBox {
        position: relative;
        width: 160px;
        padding: 40px;
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 50px;
            bottom: -40px;
            z-index: 1;
            /* Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /* Width of the triangle */
            border-right: 50px solid transparent;
            /* Width of the triangle */
            border-top: 50px solid #ffffff;
            /* Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 62px;
        bottom: -40px;
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-right: 60px solid transparent;
        /* Width of the triangle */
        border-top: 60px solid #E6E4F4;
        /* Height of the triangle and color */
    }

    /* Height of the triangle container */
}

.box2 {
    margin-top: 50px;
    margin-left: 50px;


    .parentShadow {
        position: relative;
        width: 169px;
        padding: 20px;
        background: #E6E4F4;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }

    .arrowBox {
        position: relative;
        width: 192px;

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 140px;
            top: -30px;
            z-index: 1;
            /* Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /* Width of the triangle */
            border-left: 50px solid transparent;
            /* Width of the triangle */
            border-bottom: 50px solid #ffffff;
            /* Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 158px;
        top: -28px;
        /* Height of the triangle container */
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-left: 60px solid transparent;
        /* Width of the triangle */
        border-bottom: 60px solid #E6E4F4;
        /* Height of the triangle and color */
    }
}

.box3 {
    margin-top: 50px;
    margin-left: 50px;

    .parentShadow {
        position: relative;
        width: 169px;
        padding: 20px;
        background: #E6E4F4;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    }

    .arrowBox {
        position: relative;
        width: 230px;
        padding: 40px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        /* shadow effect */
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        z-index: 123;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 140px;
            bottom: -30px;
            z-index: 1;
            /* Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /* Width of the triangle */
            border-left: 50px solid transparent;
            /* Width of the triangle */
            border-top: 50px solid #ffffff;
            /* Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 158px;
        bottom: -28px;
        /* Height of the triangle container */
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-left: 60px solid transparent;
        border-top: 60px solid #E6E4F4;
        /* Height of the triangle and color */
    }
}
    
    }

}

.videoContainer {
    width: 90%;
    max-width: 400px;
    height: 420px;
    margin: 0px auto;
}

@media only screen and (max-width: 450px) {
    .videoContainer {
        height: 380px;
    }
}

@media only screen and (max-width: 390px) {
    .videoContainer {
        height: 350px;
    }
}

@media only screen and (max-width: 350px) {
    .videoContainer {
        height: 300px;
    }
}

@media only screen and (max-width: 300px) {
    .videoContainer {
        height: 240px;
    }
}

.heroSection {
    max-width: 90%;
    margin: 0px auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;

    .primaryText {
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
    }

    .secondaryText {
        padding-top: 12px;
        color: #646464;
        line-height: 32px;
        font-size: 18px;
        line-height: 27px;
    }

    .buttonsContainer {
        text-align: center;
        padding-top: 35px;
        margin: 0px auto;
        max-width: 90%;


        .primaryButton {
            width: 100%;
            max-width: 500px;
            color: white;
            background-color: #EF5123;
            border-width: 0;
            padding: 10px;
            font-size: 16px;
            font-weight: 600;
            font-family: "Poppins", sans-serif !important;
            border-radius: 8px;
            cursor: pointer;
        }

        .secondaryButton {
            width: 100%;
            max-width: 500px;
            color: #EF5123;
            background-color: transparent;
            border-width: 0;
            padding: 10px;
            font-size: 16px;
            font-weight: 600;
            font-family: "Poppins", sans-serif !important;
            border-radius: 8px;
            cursor: pointer;
            margin-top: 15px;
        }
    }
}

.featuresContainer {
    background-color: #040935;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 35px 0px;
    
    .feature {
        width: 50%;
        margin: 0px auto;
        padding: 30px;
        text-align: center;

        .img {
            width: 50px;
        }
        .featuresText {
            color: white;
            font-size: 20px;
            font-weight: 300;
            padding-top: 5px;

            .highlight {
                color: #FF8E80;
            }
        }
    }

    @media only screen and (max-width: 470px) {
        .feature {
            width: 75%;
        }
    }
}

.prepareResumeContainer {
    max-width: 90%;
    margin: 0px auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;

    .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 48px;
        padding-bottom: 10px;
    }

    .imageContainer {
        .img {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
        }

        .description {
            .text {
                font-size: 18px;
                line-height: 29px;
                font-weight: 400;
                padding: 10px 0px;
            }
        }
    }

    @media only screen and (min-width: 720px) {
        .imageContainer {
            display: flex;
            gap: 25px;
            .img {
                width: 50%;
                height: auto;
                max-width: 400px;
                align-self: center;
            }

            .description {
                .text {
                    font-size: 18px;
                    line-height: 29px;
                    font-weight: 400;
                    padding: 10px 0px;
                }
            }
        }
    }

    .buttonContainer {
        text-align: center;
        padding-top: 25px;
        margin: 0px auto;
        max-width: 90%;

        .primaryButton {
            width: 100%;
            max-width: 500px;
            color: white;
            background-color: #EF5123;
            border-width: 0;
            padding: 10px;
            font-size: 16px;
            font-weight: 600;
            font-family: "Poppins", sans-serif !important;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}

.subFeaturesContainer {
    max-width: 90%;
    margin: 0px auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 60px;

    .subTitle {
        color: #A6B5C8;
        font-size: 18px;
        line-height: 26px;
        font-weight: 400;
    }

    .title {
        font-size: 32px;
        line-height: 48px;
        font-weight: 700;
        padding-bottom: 15px;
    }

    .feature {
        padding-bottom: 40px;

        .headingContainer {
            display: flex;
            gap: 20px;
            padding-bottom: 20px;

            .img {
                width: 40px;
                align-self: center;
            }

            .heading {
                font-size: 24px;
                line-height: 30px;
                font-weight: 700;
            }
        }

        .featureText {
            font-size: 18px;
            line-height: 25px;
        }
    }
}

.faqsContainer {
    max-width: 90%;
    margin: 0px auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    padding-top: 20px;

    .heading {
        font-size: 32px;
        line-height: 48px;
        font-weight: 700;        ;
    }

    .questionContainer {
        padding: 15px 0px;
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);

        .questionAns {
            background-color: #FAFAFA;
            margin-bottom: 20px;
            padding: 10px 15px;
            border-radius: 5px;
        }

        .question {
            font-weight: bold;
            padding-bottom: 10px;
            padding-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            font-size: 18px;
        }

        .open {
            width: 15px;
            height: 15px;
            border-radius: 10px;
            border: 1px solid #DBD9D9;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .close {
            width: 15px;
            height: 15px;
            border-radius: 10px;
            border: 1px solid #DBD9D9;
            display: flex;
            align-items: center;
            justify-content: center;

        }

        .answer {
            padding-top: 20px;
            border-top: 1px solid #DBD9D9;
            margin-bottom: 10px;
            font-size: 18px;
        }
    }
}

.content3 {
    max-width: 90%;
    margin: 0px auto;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 40px;
    padding-top: 60px;
    background-color: #000;

    .text {
        font-size: 24px;
        color: white;
        margin-bottom: 20px;
        font-weight: 300;
        line-height: 30px;
    }
    .heading {
        font-size: 30px;
        line-height: 45px;
        color: #555;
        padding-bottom: 30px;
        padding-top: 30px;
        font-weight: bold;
        .priceOld {
            font-weight: bold;
            color: white;
        }
        .priceNew {
            font-weight: bold;
            color: yellow;
        }
    }
    .btnContainer {
        text-align: center;
        padding-top: 25px;
        margin: 0px auto;
        max-width: 90%;

        .primaryButton {
            width: 100%;
            max-width: 500px;
            color: white;
            background-color: #EF5123;
            border-width: 0;
            padding: 10px;
            font-size: 16px;
            font-weight: 600;
            font-family: "Poppins", sans-serif !important;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}

.footer {
    background-color: #000;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 30px;
    padding-bottom: 40px;

    .contentWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        .copyRight {
            color: white;
            font-size: 10px;
            line-height: 15px;
            font-weight: 400;
        }

        .privacy {
            color: white;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }

        .reachOut {
            color: white;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
        }

        .socialLinks {
            display: flex;
            gap: 20px;

            .socialLink {
                height: 30px;
                cursor: pointer;
            }
        }
    }
}

video::-webkit-media-controls {
    display: none !important;
}