.pickButton {
    font-size: 1.46163vh;
    color: #0279ff;
    letter-spacing: 0.5px;
    text-align: center;
    font-weight: 600;
    margin-top: 10px;
    z-index: 200;
    background: #ffffff !important;
    padding: 0.3rem;
    border: 1px solid #0279ff;
    border-radius: 4px;
    cursor: pointer;
}
.pickButton:hover {
    box-shadow: 0 2px 10px 0 #0279ff;
    background: #dfeffa !important;
    color: #000000;
}
.pickButton > span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.driveIconStyle {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}