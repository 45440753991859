@import '../../../sass/variables';

.footerContainer {
    width: 100%;
    height: 100%;
    font-weight: 18 * $vw-const;
    line-height: 1.5;

    .content3 {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        background-image: url('../../../assets/build-resume-home/bottom.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 400* $vw-const;
        background-color: #000;

        .left {
            width: 70%;
            margin: 0 auto;
            color: #ffffff;
            margin: 0 auto;
            color: #ffffff;
            height: 400* $vh-const;
            justify-content: center;
            display: flex;
            flex-direction: column;

            .text {
                font-size: 24 * $vw-const;
                margin-bottom: 20px;
            }

            .heading {
                font-size: 30 * $vw-const;
                color: #555;
                margin-bottom: 20px;
                margin-top: 20px;

                .priceOld {
                    font-weight: bold;
                    color: white;
                }

                .priceNew {
                    font-weight: bold;
                    color: yellow;
                }
            }

            .btn button {
                background-color: #EF5123;
                font-size: 20* $vw-const;
                font-weight: 400;
                color: white;
                border-radius: 5px;
                padding: 10px 24px 10px 24px;
            }
        }


    }
    .onlyText {
        font-size: 16px;
        color: #ffffff;
    }
    .footer {
        height: 200px;
        background-color: #ccc;
    }
}

@media (max-width: 900px) {
    .pannaLogo {
      margin-top: 8px;
      margin-left: 10px;
    }
    .container {
      width: 90%;
    }
  }


@media only screen and (max-width: 1024px) {

    .footerContainer {
        font-weight: clamp(16px,18 * $vw-const, 18 * $vw-const);
    
        .content3 {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            background-image: url('../../../assets/build-resume-home/bottom.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 300px;
            background-color: #000;
            background-position-x: right;
            .left {
                width: 50%;
                color: #ffffff;
                color: #ffffff;
                height: 300px;
                justify-content: center;
                display: flex;
                flex-direction: column;
                margin-left: 50px;
    
                .text {
                    font-size: 16px;
                    margin-bottom: 20px;
                   // color: black
                }
    
                .heading {
                    font-size: 20px;
                  //  color: #000;
                    margin-bottom: 20px;
                    margin-top: 20px;
    
                    .priceOld {
                        font-weight: bold;
                     //   color: #000;
                    }
    
                    .priceNew {
                        font-weight: bold;
                      //  color: #8a2be2;
                    }
                    .onlyText {
                        font-size: 16px;
                        color: #ffffff;
                    }
                }
    
            }
    
    
        }    
    }

}
