.Follow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-right: 10px;
}
@media (max-width: 414px) {
    .Follow {
        margin-right: 10px;
    }
}

.Follow a {
    display: flex;
    align-items: center;
    /* opacity: 0.6; */
}

.Follow img {
    margin-right: 18px;
    width: 22px;
    height: 20px;
}
@media (max-width:414px) {
    .FollowSpan {
        display: none;
    }
    .Follow img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
    }
    .Follow:nth-child(2){
        margin-right: 0;
    }
}
.youtube {
    width: 24px !important;
    height: 20px !important;
}
@media (max-width:414px) {
    .youtube {
        width: 18px !important;
        height: 16px !important;
    }
}