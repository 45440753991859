.contentHeading {
  font-weight: 700;
  font-size: 17.6px;
  color: #ffffff;
  letter-spacing: 0;
}

@media (max-width: 1024px) {
  .contentHeading {
    font-size: 14.5px;
  }
}

@media (max-width: 414px) {
  .contentHeading {
    font-size: 12px;
  }
}

.content {
  margin-top: 60px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.4px;
  line-height: 28px;
}

@media (max-width: 1130px) {
  .content {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .content {
    margin-top: 30px;
    font-size: 12px;
  }
}

@media (max-width: 1010px) {
  .content {
    font-size: 12px;
  }
}

@media (max-width: 414px) {
  .content {
    font-size: 10px;
    margin-top: 22px;
  }
}

.hoverLogo {
  width: 123px;
  height: 123px;
  position: absolute;
  top: -44px;
  border-radius: 60px;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media (max-width: 1440px) {
  .hoverLogo {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 1050px) {
  .hoverLogo {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 1024px) {
  .hoverLogo {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 414px) {
  .hoverLogo {
    width: 60px;
    height: 60px;
    top: -30px;
  }
}

.ImageStyles {
  width: 80px;
  height: 80px;
}

@media (max-width: 1440px) {
  .ImageStyles {
    width: 65px;
    height: 65px;
  }
}

@media (max-width: 1050px) {
  .ImageStyles {
    width: 65px;
    height: 65px;
  }
}

@media (max-width: 1024px) {
  .ImageStyles {
    width: 45px;
    height: 45px;
  }
}

@media (max-width: 414px) {
  .ImageStyles {
    width: 38px;
    height: 38px;
  }
}

.logoParent {
  min-width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 66px;
}

@media (max-width: 1050px) {
  .logoParent {
    min-width: 90%;
  }
}

@media (max-width: 414px) {
  .logoParent {
    margin-top: 30px;
  }
}

.logoHeaderNonFocus {
  background: rgb(216, 216, 216, 0.5);
  border: 0px solid rgb(151, 151, 151);
  border-radius: 48px;
  width: 93px;
  height: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
}

@media (max-width: 1050px) {
  .logoHeaderNonFocus {
    width: 88px;
    height: 88px;
  }
}

@media (max-width: 414px) {
  .logoHeaderNonFocus {
    width: 50px;
    height: 50px;
  }
}

.logoHeaderFocus {
  background: rgb(216, 216, 216);
  border: 0px solid rgb(151, 151, 151);
  border-radius: 60px;
  width: 123px;
  height: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
}

@media (max-width: 1050px) {
  .logoHeaderFocus {
    width: 115px;
    height: 115px;
  }
}

@media (max-width: 414px) {
  .logoHeaderFocus {
    width: 60px;
    height: 60px;
  }
}

.imgNonFocus {
  width: 60px;
  height: 60px;
}

@media (max-width: 414px) {
  .imgNonFocus {
    width: 30px;
    height: 30px;
  }
}

.imgFocus {
  width: 80px;
  height: 80px;
}

@media (max-width: 414px) {
  .imgFocus {
    width: 40px;
    height: 40px;
  }
}

.sliderWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 40vw !important;
}

@media (max-width: 1024px) {
  .sliderWrapper {
    width: 45vw !important;
  }
}

@media (max-width: 900px) {
  .sliderWrapper {
    width: 55vw !important;
  }
}

@media (max-width: 550px) {
  .sliderWrapper {
    width: 90vw !important;
  }
}

@media (max-height: 550px) {
  .sliderWrapper {
    width: 65vw !important;
  }
}

.sliderWrapper>button:first-child {
  position: absolute;
  left: 20px;
  z-index: 99;
}

.sliderWrapper>button:last-of-type {
  position: absolute;
  right: 20px;
  z-index: 99;
}

@media (max-width: 550px) {
  .sliderWrapper>div:nth-child(2) {
    bottom: 0px;
  }
}

.ContentWrapper {
  outline: none;
}

.sliderUl {
  width: 75%;
  height: 123px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 11%; */
  margin-bottom: 20px;

}

.sliderUl li {
  display: flex;
  width: 12.048vw;
  height: 12.048vw;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

@media (max-width: 550px) {
  .sliderUl li {
    margin-right: 5px;
  }
}

@media (max-width: 1024px) {
  .sliderUl li {
    width: 180px;
    height: auto;
  }
}

@media (max-width: 900px) {
  .sliderUl li {
    width: 130px;
    height: auto;
  }
}

.sliderHref {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 0px solid rgb(151, 151, 151);
  border-radius: 60px;
  width: 8.217vw;
  height: 7.4vh;
  padding: 0.4rem;
  opacity: 0.3;
}

@media (max-width: 550px) {
  .sliderHref {
    padding: 0.4rem;
  }
}

@media (max-height: 550px) {
  .sliderHref {
    padding: 15px;
  }
}

.sliderHref:hover {
  opacity: 0.3;
  background: #FFFFFF;
  border: 1px solid #A163F5;
  box-shadow: 0 1px 2px 0 rgba(195, 159, 244, 0.43);
  border-radius: 22.8px;
}

@media (max-width: 1024px) {
  .sliderHref {
    width: 100%;
  }

  .sliderHref:hover {
    width: 100%;
  }
}

@media (max-width: 414px) {
  .sliderHref {
    /* width: 43px;
    height: 43px; */
  }

  .sliderHref:hover {
    /* width: 53px;
    height: 53px; */
  }
}

.skillDotStyles {
  width: 2.431vw;
  height: 3.655vh;
}

@media (max-width: 1440px) {
  .skillDotStyles {
    width: 35px;
    height: 30px;
  }
}

@media (max-width: 550px) {
  .skillDotStyles {
    width: 30px;
    height: 25px;
  }
}

.resumeAnalysisDotStyles {
  width: 2.084vw;
  height: 3.655vh;
}

@media (max-width: 1440px) {
  .resumeAnalysisDotStyles {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 550px) {
  .resumeAnalysisDotStyles {
    width: 25px;
    height: 25px;
  }
}

.similarityDotStyles {
  width: 2.431vw;
  height: 3.046vh;
}

@media (max-width: 1440px) {
  .similarityDotStyles {
    width: 35px;
    height: 25px;
  }
}

@media (max-width: 550px) {
  .similarityDotStyles {
    width: 20px;
    height: 20px;
  }
}

.slideActive {
  opacity: 1;
  background: #ffffff;
  background: #FFFFFF;
  border: 1px solid #A163F5;
  box-shadow: 0 1px 2px 0 rgba(195, 159, 244, 0.43);
  border-radius: 22.8px;
}

.slideActive:hover {
  opacity: 1;
  background: #ffffff;
  background: #FFFFFF;
  border: 1px solid #A163F5;
  box-shadow: 0 1px 2px 0 rgba(195, 159, 244, 0.43);
  border-radius: 22.8px;
}

.imageWrap {
  padding-top: 3.6540803897685747vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.corousalDescription {
  margin-top: 4.460303300624442vh;
  font-size: 1.58344vh;
  font-weight: 500;
  color: #130e49;
  letter-spacing: 0.6px;
}

@media (max-height: 550px) {
  .corousalDescription {
    font-size: 2.46163vh;
  }
}

.smartSkillsStyle {
  margin: 0 auto;
  width: 80%;
}

.resumeAnalysisImgStyle {
  margin: 0 auto;
  width: 55%;
}

.similarityScoreImgStyle {
  margin: 0 auto;
  width: 48%;
}

.dotSectionTitle {
  margin-top: 10px;
  font-size: 1.33983vh;
  font-weight: 600;
  color: #130E49;
  letter-spacing: 0.45px;
}

@media (max-height: 550px) {
  .dotSectionTitle {
    font-size: 2.09623vh;
  }
}