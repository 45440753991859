/* Variables */
$primary-color: #3498db;
$secondary-color: #ecf0f1;
$border-color: #000000;
$checked-color: $primary-color;
$disabled-color: #95a5a6;
$radio-size: 18px; // Adjust the size of the radio button
$inner-circle-size: 9px; // Adjust the size of the inner circle

/* Radio Button Wrapper */
.radioWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  border-bottom: 1px solid #ddd;
  .radioLabel {
    margin-left: 8px;
    font-size: 14px;
    color: $primary-color;
  }
}

/* Radio Button Styles */
.customRadio {
  display: flex!important;
  align-items: center;
  justify-content: space-between;
  input[type="radio"] {
    display: none; // Hide the default radio button

    & + label {
      display: inline-block;
      position: relative;
      padding-left: $radio-size + 12px;
      cursor: pointer;
      font-size: 14px;
      user-select: none;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: $radio-size;
        height: $radio-size;
        border: 1px solid $border-color;
        border-radius: 60%;
       // background: $secondary-color;
      }

      &::after {
        content: '';
        position: absolute;
        left: ($radio-size - $inner-circle-size) / 1.6;
        top: ($radio-size - $inner-circle-size) / 1.6;
        width: $inner-circle-size;
        height: $inner-circle-size;
        border-radius: 50%;
        background: $checked-color;
        opacity: 0;
        transition: opacity 0.2s;
      }
    }

    &:checked + label::after {
      opacity: 1;
    }

    &:disabled + label {
      cursor: not-allowed;
      color: $disabled-color;

      &::before {
        border-color: $disabled-color;
      }
    }
  }
}
