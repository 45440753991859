@import '../../../sass/variables';

.resumeMatchReport {
  position: relative;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
}

.reportResumeBuilderContainer {
  width: 90%;
  margin: 0 auto;
  gap: 10px;
  padding-bottom: 10rem;
  display: flex;
  justify-content: space-between;
}

.originalResumeContainer {
  margin-top: 2rem;
  width: 50%;

  .headContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .reportHeading {
      width: 150px;
      margin-bottom: 10px;
    }

    .spanScore {
      margin-bottom: 5px;
      font-size: 10px;
    }
  }


  .head {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.popUpContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  inset: 0;
  width: 100% !important;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  margin-top: 0 !important;
}

.popUp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /*  background: white; */
  background: #f1f6f7;
  padding: 20px;
  border-radius: 10px;
  width: 70%;
  padding: 50px;
  overflow: auto;

}

.coverLetterContainer {
  padding: 20px 20px 20px 0px;
  overflow: auto;
//  border: 1px solid #ddd;
  font-size: 14px;
  width: 100%;
  margin: 0 auto;
  // font-family: 'Times New Roman', Times, serif;
}


.downloadButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F2F2F6;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;

  .scoreContainer {
    background-color: #4936D3;
    color: #ffffff;
    width: 65px;
    height: 60px;
    font-size: 22px;
    border-radius: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .downloadButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .resetButtons {
      display: flex;
      font-size: 14px;
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .saveBtn {
        margin-right: 5px;
      }

      div {
        color: #4936D3;
        cursor: pointer;
      }

      div:hover {
        text-decoration: underline;
      }
    }

    .fullScreenBtn {
      img {
        width: 30px;
        margin-left: 5px;
        cursor: pointer;
      }

    }
  }

  .reportHeading {
    width: 150px;
    margin-bottom: 10px;

    .spanScore {
      margin-bottom: 5px;
      font-size: 10px;

    }
  }
}





.headContainer {

  width: 90%;
  margin: 0 auto;
  grid-gap: 10px;
  gap: 10px;
  display: flex;
  margin-top: 30px;
  margin-bottom: 50px;
  justify-content: space-between;

  .headLabel {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .splitContent {
    width: 32%;

  }

  .scoreSection {
    width: 100%;
    border: 1px solid #C5C5C5;
    border-radius: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    height: 125px;

    .scoreContainer {
      background-color: #F6F6F8;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 50%;

      .circle {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 4px solid #4936D3;
        display: flex;
        align-items: center;
        justify-content: center;

        .score {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #dfddf4;
          color: #4936D3;
          font-weight: bold;
          font-size: 16px;
        }

      }

      .label {
        font-weight: bold;
        font-size: 10px;
        color: #4936D3;
        line-height: 1.5;
        margin-top: 5px;
        text-align: center;
      }
    }


  }

  .templateSection {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #C5C5C5;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    flex-direction: column;
    font-size: 14px;
    height: 125px;

    .topSec {
      background: #F6F6F8;
      width: 99%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;


      div {
        background-color: white;
        padding: 10px;
        border-radius: 5px;
        margin-top: 15px;
        margin-bottom: 15px;

        span {
          font-weight: bold;
        }
      }
    }

    .label {
      font-size: 12px;
      color: #4936D3;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 10px;

    }

  }

  .downLoadSection {
    width: 100%;
    border: 1px solid #C5C5C5;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    flex-direction: column;
    height: 125px;

    .topSec {
      display: flex;
      justify-content: center;
    }

    .pdfIcon {
      background-color: #F6F6F8;
      width: 45%;
      margin: 5px;
      margin-left: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 50px;
        margin-right: 10px;
      }


      .span {
        color: grey;
        font-size: 10px;
        font-weight: 300;
      }
    }

    .wordIcon {
      background-color: #F6F6F8;
      width: 45%;
      margin: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      img {
        width: 50px;
        margin-right: 10px;
      }


      .span {
        color: grey;
        font-size: 10px;
        font-weight: 300;
      }
    }

    .text {
      font-size: 12px;
      width: 90%;
    }

  }

}





.AIResumeContainer {
  margin-top: 2rem;
  width: 50%;

  // transition: width 0.3s ease, height 0.3s ease;
}

// .AIResumeContainer:hover {
//   width: 70%;
// }

.aiGeneratedContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  gap: 10px;
}

.closeimg {
  width: 21px;
  height: 21px;
  position: absolute;
  right: 17.5px;
  top: 17.5px;
  cursor: pointer;
}

.aiGeneratedWrap {
  /* width: 40%; */
  margin: 0 auto;
  min-height: 80vh;
  font-weight: 400;
  height: 650px;
  overflow: auto;
}

// .resumeContainer:hover {
//   border: 2px solid #4936D3 !important;
// }

.aiGeneratedWrap:hover {
  border: 1px solid #4936D3 !important;
}

.resumeContainer {
  width: 100%;
  outline: none;
  background: white;
  border: 1px solid #c0c0c0;
  padding: 1rem;
  box-sizing: border-box;
}

@media print {
  @page {
    margin-top: 1.5cm !important;
    /* Set top margin */
    margin-bottom: 1.5cm !important;
    /* Set bottom margin */
    margin-left: 1cm !important;
    /* Set left margin */
    margin-right: 1cm !important;

    /* Set right margin */
    border: none !important;

  }

  .resumeContainer {
    border: none;
    font-family: 'Poppins', sans-serif !important;
  }

  body {
    font-family: 'Poppins', sans-serif !important;
  }
}



.resumeRow {
  margin-bottom: 1rem;
}

.resumeTitle {
  font-size: 0.8rem;
  font-weight: medium;
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 0.5rem;
  font-weight: 500;
  margin-top: 2rem;

}


.resumeDescrition {
  font-size: 0.7rem;
  font-weight: normal;
  color: #000000;
  margin-top: 0.5rem;
  margin-left: 2rem;
  line-height: 1.5;
  font-weight: 400;


}

.resumeName {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.experience {
  em {
    font-weight: bold;
    font-style: normal;
    color: #4936D3;
  }

  >ul>li {
    margin-top: 1rem;

    >ul {
      margin-left: 2rem;

      >li {
        margin-top: 0.3rem;
      }
    }
  }
}

@media (max-width: 414px) {
  .closeimg {
    width: 16px;
    height: 16px;
    right: 4px;
    top: 4px;
  }
}

.computingpopup {
  width: 97%;
  height: 90%;
  position: relative;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-top: 1%;
  padding-bottom: 20px;
  z-index: 10;
}

@media (max-width: 550px) {
  .computingpopup {
    padding-bottom: 0px;
  }
}

/* .computingpopup :first-of-type :last-of-type > a{
    display: none;
} */
.reportHeading {
  color: #130e49;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  /* margin-top: 5%; */
}

@media (max-width: 550px) {
  .reportHeading {
    font-size: 2.6542vh;
  }
}

@media (max-height: 550px) {
  .reportHeading {
    font-size: 5.6542vh;
  }
}

.spanScore {
  color: #aa6dce;
}

.percentile {
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 4%;
}

@media (max-width: 850px) {
  .percentile {
    font-size: 15px;
  }
}

@media (max-width: 414px) {
  .percentile {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media (max-width: 320px) {
  .percentile {
    font-size: 10px;
    margin-bottom: 10px;
  }
}

.basicReport {
  width: 100%;
  display: flex;
}

@media (max-width: 1024px) {
  .basicReport {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 414px) {
  .basicReport {
    background: linear-gradient(90deg, #668effad 0%, #58b8fb 100%);
  }
}

.FaqText {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0.8px;
  text-align: left;
}

@media (max-width: 414px) {
  .FaqText {
    font-size: 8px;
  }
}

.CanvasDoughnutChart {
  position: relative;
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 7px 30px 0 rgba(208, 208, 208, 0.5);
  border-radius: 5px;
  margin-bottom: 20px;
}

@media (max-width: 550px) {
  .CanvasDoughnutChart {
    width: 94%;
    padding: 15px 10px;
  }
}

.relevanceNote {
  width: 80%;
  font-size: 1.705237515225335vh;
  font-weight: 500;
  color: #130e49;
  letter-spacing: 0.6px;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: 550px) {
  .relevanceNote {
    width: 100%;
    font-size: 10px;
    font-weight: 400;
  }
}

@media (max-height: 550px) {
  .relevanceNote {
    font-size: 2.58344vh;
  }
}

.resumeCardWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  flex-wrap: wrap;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 7px 30px 0 rgba(208, 208, 208, 0.5);
  border-radius: 5px;
}

@media (max-width: 414px) {
  .resumeCardWrapper {
    background: none;
  }
}

.cardContentContainer {
  width: 30%;
  /* box-shadow: 0 0 10px 0 rgba(190,190,190,0.56); */
  /* background: linear-gradient(90deg, #000000af 0%, #000000c7 100%); */
  border-radius: 5px;
  margin: 20px;
}

@media (max-width: 1440px) {
  .cardContentContainer {
    width: 29%;
  }
}

@media (max-width: 1350px) {
  .cardContentContainer {
    width: 40% !important;
  }
}

@media (max-width: 850px) {
  .cardContentContainer {
    width: 44% !important;
  }
}

@media (max-width: 700px) {
  .cardContentContainer {
    width: 80% !important;
  }
}

@media (max-width: 500px) {
  .cardContentContainer {
    width: 80% !important;
    height: 95px !important;
    margin: 8px !important;
  }
}

@media (max-height: 500px) {
  .cardContentContainer {
    width: 80% !important;
    margin: 8px !important;
  }
}

.skillMatchStyles {
  width: 100%;
  height: 80%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.fontFamily {
  font-size: 15px;
  color: #afafaf;
  letter-spacing: 0.5px;
  width: 100px;
  height: 20px;
}

@media (max-width: 414px) {
  .fontFamily {
    font-size: 8px;
  }
}

.SummaryOrObjective {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.ExperieneCard {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #e8ecf2;
}

.rigthTick {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}

@media (max-width: 414px) {
  .rigthTick {
    width: 20px;
    height: 20px;
  }
}

.summaryText {
  font-size: 2.92326431181486vh;
  font-weight: bold;
  color: #000000;
  letter-spacing: 1.5px;
  text-align: center;
}

@media (max-width: 414px) {
  .summaryText {
    font-size: 14px;
  }
}

@media (max-height: 500px) {
  .summaryText {
    font-size: 14px;
  }
}

.experienceText {
  font-size: 2.192448233861145vh;
  font-weight: bold;
  color: #000000;
  letter-spacing: 1.5px;
  text-align: center;
}

@media (max-width: 414px) {
  .experienceText {
    font-size: 12px;
  }
}

@media (max-height: 500px) {
  .experienceText {
    font-size: 14px;
  }
}

.animatedNumberWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.92326431181486vh;
  font-weight: bold;
  color: #000000;
  letter-spacing: 1.5px;
  text-align: center;
  border-radius: 10px;
}

@media (max-width: 500px) {
  .animatedNumberWrapper {
    font-size: 14px;
  }
}

@media (max-height: 500px) {
  .animatedNumberWrapper {
    font-size: 14px;
  }
}

.actionVerbsWrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #1c6fff;
  letter-spacing: 0;
}

@media (max-width: 414px) {
  .actionVerbsWrapper {
    font-size: 14px;
  }
}

.circularOutter {
  opacity: 0.6;
  background-color: red;
}

.pdfDownloadLinkWrap {
  width: auto;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 550px) {
  .pdfDownloadLinkWrap {
    align-self: flex-end;
  }
}

.sampleWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 700px) {
  .sampleWrap {
    flex-direction: column;
    margin-right: 0px;
    padding: 5px;
  }
}

.sampleText {
  font-size: 15px;
  color: #000000;
  letter-spacing: 0.75px;
  text-align: center;
  font-weight: bold;
  margin-right: 20px;
}

@media (max-width: 1440px) {
  .sampleText {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .sampleText {
    margin-bottom: 10px;
  }
}

@media (max-width: 414px) {
  .sampleText {
    font-size: 10px;
  }
}

.downloadLink {
  text-decoration: none;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(208, 208, 208, 0.5);
  border-radius: 3.5px;
  font-size: 1.5834348355663825vh;
  font-weight: bold;
  color: #468cf2;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
}

@media (max-width: 550px) {
  .downloadLink {
    font-size: 10px !important;
    height: 10px;
    bottom: 1%;
    padding: 8px;
  }
}

@media (max-height: 550px) {
  .downloadLink {
    font-size: 10px !important;
    height: 10px;
    bottom: 1%;
    padding: 8px;
  }
}

.UploadYourButton {
  text-decoration: none;
  z-index: 100;
  border: 0px;
  background: #ff5d5d;
  border-radius: 3.5px;
  font-size: 12.5px;
  font-weight: bold;
  color: #ffffff;
  outline: none;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
}

.UploadYourButton:hover {
  box-shadow: rgba(224, 220, 220, 0.5) 0px 2px 10px 0px;
}

@media (max-width: 414px) {
  .UploadYourButton {
    font-size: 12px !important;
    bottom: 1%;
  }
}

.detailedReportWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  box-shadow: 0 7px 30px 0 rgba(208, 208, 208, 0.5);
  border-radius: 5px;
}

.resumeWrap {
  flex: 1;
  height: auto;
  overflow: hidden;
  // margin-left: 15px;
  // margin-right: 15px;
  border: 1px solid #9f9f9f;
  height: 800px;
}

@media (max-width: 1024px) {
  .resumeWrap {
    display: none;
  }
}

.ReportLabel {
  font-size: 3.604531410916581vh;
  font-weight: bold;
  color: #130e49;
  letter-spacing: 0;
  text-align: center;
  padding: 20px 0px;
  z-index: 100;
}

@media (max-width: 550px) {
  .ReportLabel {
    margin-top: 8%;
  }
}

@media (max-height: 550px) {
  .ReportLabel {
    font-size: 22px;
  }
}

.detailedReportLabel {
  font-size: 20px;
  color: #130e49;
  letter-spacing: 0;
  font-weight: bold;
  margin-bottom: 10px;
}

@media (max-width: 550px) {
  .detailedReportLabel {
    font-size: 14px;
    margin-top: 20px;
    align-self: flex-start;
  }
}

@media (max-height: 500px) {
  .detailedReportLabel {
    font-size: 14px;
  }
}

.detailReportContainer {
  padding: 20px;
  flex: 1;
  overflow: auto;
}

@media (max-width: 550px) {
  .detailReportContainer {
    padding: 5px;
  }
}

.cardValueWrapper {
  width: 80%;
  display: flex;
}

.cardValueWrapper>div:first-child {
  margin-right: 20px;
}

.matchSkillCardValueWrapper {
  display: flex;
  flex-direction: column;
}

.cardValue {
  font-size: 21px;
  color: #ff8484;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 414px) {
  .cardValue {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

.cardDescription {
  font-size: 15px;
  color: #5b5b5b;
  letter-spacing: 0;
}

@media (max-width: 414px) {
  .cardDescription {
    font-size: 8px;
  }
}

.skillsMatchedWrap {
  display: flex;
  flex-direction: column;
}

.matchedSkillLabel {
  font-size: 18px;
  color: #82ffc0;
  letter-spacing: 0;
  margin-bottom: 20px;
}

@media (max-width: 414px) {
  .matchedSkillLabel {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.unMatchedSkills {
  font-size: 18px;
  color: #ff8484;
  letter-spacing: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media (max-width: 414px) {
  .unMatchedSkills {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.computedSkills {
  width: 100%;
  max-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media (max-width: 414px) {
  .computedSkills {
    max-height: 100px;
  }
}

@media (max-width: 320px) {
  .computedSkills {
    max-height: 70px;
  }
}

.actionVerbsWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.skillTextStyle {
  font-size: 14px;
  color: #5b5b5b;
  letter-spacing: 0.8px;
  text-align: center;
  margin-right: 20px;
}

@media (max-width: 414px) {
  .skillTextStyle {
    font-size: 8px;
  }
}

.CheckNavButton {
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 600;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  border: 0px;


  cursor: pointer;
  background: transparent;

  img {
    width: 30px;
  }
}

@media (max-width: 414px) {
  .CheckNavButton {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.buttonsWrap {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 414px) {
  .buttonsWrap {
    justify-content: center;
  }
}

.ButtonIconWrap {
  display: flex;
  border: 0px;
  background: transparent;
  padding: 10px;
}

.buttonIcon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.toolTipPortal>div {
  background-color: red !important;
}

.ToolTipCustom {
  background-color: red !important;
  /* width: 200px;
    height: 200px; */
}

.description {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
}

@media (max-width: 1440px) {
  .description {
    font-size: 14px;
  }
}

@media (max-width: 414px) {
  .description {
    font-size: 12px;
  }
}

.detailedReportContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 3%;
}

.popupStyle {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh);
  background: rgba(0, 0, 0, 0.3);
  z-index: 2100;
}

.loaderWrap {
  width: 30%;
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 20px 10px;
}

@media (max-width: 500px) {
  .loaderWrap {
    width: 65%;
    padding: 12px 5px;
  }
}

.loaderTitle {
  font-size: 3.089598352214212vh;
  color: #130e49;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  /* margin-top: 5%; */
  margin-bottom: 10px;
}

@media (max-width: 500px) {
  .loaderTitle {
    font-size: 2.089598vh;
  }
}

.loaderMessage {
  font-size: 1.58344vh;
  font-weight: 500;
  letter-spacing: 0.6px;
}

.messageStyle {
  font-size: 1.58344vh;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-align: center;
}

@media (max-height: 550px) {
  .messageStyle {
    font-size: 12px;
  }
}

.progressBarWrap {
  width: 30%;
}

@media (max-width: 550px) {
  .progressBarWrap {
    width: 45%;
  }
}

.progressBarWrap>div>div>div:nth-child(2) {
  margin: 0 auto !important;
  top: 0;
}

.dashboard {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerCircle {
  display: flex;
  overflow: visible;
}

.innerCircle>text {
  font-size: 2.47168vh;
  font-weight: bold;
  color: #000000;
  text-anchor: middle !important;
}

.ReadabilityWrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
}

.ReadabilityCard {
  flex: 0;
}

.switchViewContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 92.5vh;
}

.ResumeViewer {
  position: relative;
  width: auto;
  height: 100%;
  overflow: auto;
  padding: 10px;
}

/* .ResumeViewer > html > body > div:nth-child(2) {
    position: relative !important;
} */
.ResumeViewer>div>div>div:nth-child(2)>div {
  /* width: 100% !important; */
  margin: 0 !important;
}

.ResumeViewer>div>div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ResumeViewer>div>div>div:nth-child(1) {
  display: none;
}

.ResumeViewer>div>div>div:nth-child(2) {
  position: relative !important;
}

.ResumeViewer>div>div>div:nth-child(2)>div {
  margin: 0px !important;
}

/* html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit ;
    vertical-align: baseline;
} */
.tabButtonsWrap {
  width: 100%;
  display: flex;
}

.tabButton {
  flex: 1;
  padding: 0.4rem;
  text-align: center;
  font-family: Poppins;
  font-size: 2.192448233861145vh;
  font-weight: 600;
  letter-spacing: -0.36px;
  border: 1px solid #c0c0c0;
  border-radius: 0px;
  cursor: pointer;
}

@media (max-width: 550px) {
  .tabButton {
    font-size: 12px;
  }
}

@media (max-height: 550px) {
  .tabButton {
    font-size: 12px;
  }
}

.ErrorStyleWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ErrorTitle {
  /* font-family: Montserrat; */
  font-size: 2.192448233861145vh;
  font-weight: 600;
  letter-spacing: -0.36px;
  margin-bottom: 10px;
}

.ErrorMessage {
  font-size: 1.46163215590743vh;
  font-weight: 500;
  color: #130e49;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.progressBarWrap {
  width: 50%;
}

@media (max-width: 550px) {
  .progressBarWrap {
    width: 50%;
  }
}

.progressBarWrap>div>div>div:nth-child(2) {
  margin: 0 auto !important;
  top: 0;
}

.dashboard {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerCircle {
  display: flex;
  overflow: visible;
}

.innerCircle>text {
  font-size: 2.47168vh;
  font-weight: bold;
  color: #000000;
  text-anchor: middle;
  dominant-baseline: middle;
}

@media (max-height: 550px) {
  .innerCircle>text {
    font-size: 4.47168vh;
  }
}

.oldResumeDiv {

  div {
    background-image: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  #sidebar {
    background-image: none !important;
  }

  div#page-container {
    background-color: transparent !important;
    background-image: none !important;
  }

  .pf {
    box-shadow: none !important;
  }
}


.tox-tinymce {
  z-index: 123456 !important;
}

#mytoolbar .tox-toolbar {
  background-color: lightgray !important;
  background-color: #000000 !important;
  background-image: none !important;
}

.tab-container {
  width: 90%;
  margin: 0 auto;
  border-bottom: 1px solid #ddd;

  ul {
    list-style-type: none;
    display: flex;
  }

  li {
    cursor: pointer;
    font-size: 14px;
    padding: 6px 12px;
  }

  .active {
    font-size: 14px;
    color: #130e49;
    letter-spacing: 0;
    font-weight: bold;
    border-bottom: 1.5px solid #3939c3;
  }
}

.tab-contents {
  width: 96%;
  margin: 0 auto;
  max-width: 400px;

  .scoreContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    height: 100%;
    padding: 10px 30px;

    .circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 4px solid #4936D3;
      display: flex;
      align-items: center;
      justify-content: center;

      .score {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #dfddf4;
        color: #4936D3;
        font-weight: bold;
        font-size: 16px;
      }

    }

    .label {
      font-family: 'Poppins', sans-serif !important;
      // font-weight: bold;
      font-size: 14px;
      color: #4936D3;
      line-height: 1.5;
      margin-top: 5px;
      text-align: center;
    }
  }
}

.resume-container {
  width: 98%;
  margin: 0 auto;
  background: #eee;
}

.mobileDownloadButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F2F2F6;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;

  .downloadButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 20px;

    .resetButtons {
      display: flex;
      gap: 5px;

      .saveBtn {
        border: 1px solid #777;
        padding: 5px 10px;
        background: #fff;
        border-radius: 4px;
        color: #999;
        cursor: pointer;
      }
    }
  }



  .iconContainer {
    display: flex;
    gap: 15px
  }

  .icon {
    height: 43px;
    width: 43px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.confirmationDialog {
  font-family: 'Poppins', sans-serif !important;

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;

    
    .icon {
      height: 43px;
      width: 43px;
    }
  }

  .warningMessage {
    color: #777;
    font-size: 18px;
    text-align: center;
  }

  .terms{
    color: #786ADE;
    font-size: 14;
    font-weight: bold;
    text-decoration: underline;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
  }

  .actionButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 24px 0;

    .actionButton {
      background-color: #4936D3;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      border: none;
      border-radius: 4px;
      padding: 10px 18px;
      cursor: pointer;
    }
  }
}

.respList {
  ul {
    margin-left: 20px;
  }
}

.infoLabel {
  font-size: clamp(13px, 16 * $vw-const, 16 * $vw-const);
  margin-top: 5px;
  text-align: center;
  font-style: italic;
  color: #686363;
}