@import '../sass/variables';


.resumeBuilderContainer {
  background: url('../assets/resume-builder/bg.png');
  width: 100vw;
  height: 85vh;
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; 
}

.container {
  width: 78%;
  margin: 0 auto;

  .header {
    font-size: clamp(14px, 26 * $vw-const, 26 * $vw-const);
    font-weight: 300;
    .h1 {
      font-weight: bold;
      font-size: clamp(24px, 50 * $vw-const, 50 * $vw-const);
    }
    .textHigh {
      color: #FF5D47;
      font-weight: 700;
    }  
  }
  .uploadBox {
    background-color: #F0F0F5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
   // padding: 10px;
    border-radius: 10px; 
    margin-top: 20px;
    .onUploadInputField {
      height: 60px;
      transition: height 0.6s linear;
    }    
    @media (max-width: 1024px) {
      .onUploadInputField {
        height: 60px;
      }
    }    
    @media (max-height: 550px) {
      .onUploadInputField {
        height: 60px;
      }
    }
  }
 }

 .popupStyle {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh);
  background: rgba(0, 0, 0, 0.3);
  z-index: 1400;
}

.animationFullPage {
  position: fixed;
  top: 0;
  width: 100%;
  height: calc(100vh);
  z-index: 1300;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.animationContainer {
    width: 500px;
    height: 500px;
    background-color: white;
    .animationHeading {
      font-size: clamp(20px, 32* $vw-const, 32 * $vw-const);
    }
}

 @media (max-width: 768px) {
  .container {
    width: 90%;
  }
}


