@import '../../../../../sass/variables';

.container {
  width: 100%;
  font-family: "Lato", sans-serif !important;
  padding-top: 20px;
  padding-bottom: 50px;

  .title {
    font-family: "Lato", sans-serif !important;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    padding-top: 30px;
  }

  .titleBlue {
    font-family: "Lato", sans-serif !important;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    padding-top: 30px;
    color: #265E76;
  }

  .highlight {
    line-height: 30px;
    margin-top: 20px;
    padding: 20px 25px 20px 25px;
    background-color: rgba(239, 81, 35, 0.08);

    ul {
      padding-left: 20px;
    }
  }

  .applicationImage {
    padding-top: 20px;
    width: 100%;
  }

  .subTitle {
    font-family: "Lato", sans-serif !important;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    padding-top: 30px;
  }

  .list {
    padding-left: 15px;
    line-height: 30px;
    .listItem {
      padding-top: 13px;
    }
  }

  .listText {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 5px;

    .list {
      .listItem {
        padding-top: 20px;
        .content {
          display: flex;
          align-items: center;
          gap: 18px;
          padding-top: 5px;
          padding-bottom: 10px;
          .bulb {
            height: 45px;
          }
        }
      }
    }

    .alphaList {
      list-style-type: lower-alpha !important;
      padding-left: 20px;

      .linkTitle {
        font-family: "Lato", sans-serif !important;
        font-weight: 700;
        font-size: 18px;
        color: black;
      }
      .listItem {
        padding-top: 20px;
        .content {
          display: flex;
          align-items: center;
          gap: 18px;
          padding-top: 5px;
          padding-bottom: 10px;
          .bulb {
            height: 45px;
          }
        }
      }
    }
  }

  .listWithNestedContent {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 5px;

    .list {
      .listItem {
        padding-top: 30px;
        .nestedListTitle {
          padding-top: 20px;
        }
        .nestedList {
          padding-top: 5px;
          padding-left: 15px;
          list-style-type: lower-alpha !important;
          .nestedListItem {}
        }
        .highlight {
          border-radius: 4px;
          width: 92%;
          padding: 10px 10px 10px 25px;
          background-color: rgba(231, 223, 223, 0.65);

          .highlightList {
            list-style-type: disc !important;
          }

          .highlightOrderedList {
            padding-left: 20px;
          }
        }
        .image {
          padding-top: 10px;
          width: 100%;
        }
      }
    }
  }

  .text {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-top: 20px;
  }
}