
@import '../../sass/variables';
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  inset: 0;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.content {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: flex-start;
  /*  background: white; */
  background: #fafafa;
  border-radius: 20px;
  width: clamp(400px, 700 * $vw-const, 700 * $vw-const);
  padding: clamp(20px, 30 * $vw-const, 30 * $vw-const);
  flex-direction: column;
  height: 90vh;
  overflow: auto;
  font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
}

.header {
  margin-bottom: 20px;
  .heading{
    font-size: clamp(24px, 46 * $vw-const, 46 * $vw-const);
    color: #404040;
    font-weight: 700;
  }
  .label{
    font-size: clamp(12px, 20 * $vw-const, 20 * $vw-const);
    margin-top: 10px;
    .paymentNumber {
      color: #EF5123;
      font-weight: 700;
    }
  }
  
}
.checkContainer {
  display: flex;
  width: 87%;
  margin: 0 auto;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  .checkBox {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .checkDescription {
    font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
    margin-left: 10px;
  }
}
.paymentBox {
  background-color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  flex-direction: column;
  border-radius: 10px;
  .text {
    font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
    margin-top: 10px;
  }
  .newPrice {
    font-size: clamp(24px, 66 * $vw-const, 66 * $vw-const);
    color: #000;
    font-weight: 700;
    margin-top: 30px;
    margin-top: 10px;
  }
  .gstLabel {
    font-size: clamp(12px, 18 * $vw-const, 18 * $vw-const);
    margin-bottom: 20px;
  }
  .footerLink {
    outline: none;
    border: none;
    /* // background: #007aff; */
    font-size: 0.8rem;
    color: #ffffff;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
    width: 90%;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    background-color: orangered;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: clamp(14px, 20 * $vw-const, 20 * $vw-const);
    
  }
  .footerLink:hover {
    box-shadow: 0 2px 10px 0 rgb(234, 163, 136);
  }
}
.box {
  font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
   padding-left: 20px;
  .text {
    font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
    font-style: italic;
    
  }
  .name {
    font-weight: bold;
    margin-top: 10px;
    text-align: right;
  }
}

@media (max-width: 767px) {
  .content {
    width: 70%;
    height: 90vh;
  }
  .header {
    .heading{
      font-size: 20px;
    }  
    .label {
      font-size: 12px;
      margin-top: 5px;
    }
  }
  .checkContainer {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0px;
    margin-bottom: 0px;
    .checkDescription {
      font-size: 14px;
    }
  }
  .paymentBox {
    margin: 0 auto;
  }
  .footerLink {
    box-sizing: border-box;
  }
  
}