.instructionWrap {
    width: 88%;
    background: #FFFFFF;
    box-shadow: 0 2px 3px 0 rgba(239,239,239,0.92);
    border-radius: 17px;
    padding: 10px;
    z-index: 100;
}
@media (max-width: 550px) {
    .instructionWrap {
        width: 90%;
    }
}
.lable {
    font-size: 1.82704vh;
    font-weight: 600;
    color: #130E49;
    margin-bottom: 3%;
}
@media (max-width: 1024px) {
    .lable {
        text-align: center;
    }
}
@media (max-width: 550px) {
    .lable {
        margin-bottom: 5%;
    }
}
@media (max-height: 550px) {
    .lable {
        font-size: 2.58344vh;
    }
}
.stepsWrap {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.stepContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.stepLable {
    font-size: 1.46163vh;
    font-weight: bold;
    color: #4A4A4A;
    letter-spacing: 0.5px;
    text-align: center;
}
@media (max-height: 550px) {
    .stepLable {
        font-size: 2.2vh;
    }
}
.step1ImgWrap {
    width: 3.19vw;
    height: 4.97vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background:  rgb(249, 248, 253);
    border-radius: 17px;
    padding: 7px;
    margin-bottom: 7px;
    opacity: 0.5;
    z-index: 100;
} 
@media (max-width: 1024px) {
    .step1ImgWrap {
        width: 55px;
        height: 50px;
    }
}
@media (max-width: 900px) {
    .step1ImgWrap {
        width: 40px;
        height: 35px;
    }
}
.step2ImgWrap {
    width: 3.49vw;
    height: 4.67vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background:  #FFF7E2;
    border-radius: 17px;
    padding: 7px;
    margin-bottom: 7px;
    opacity: 0.5;
    z-index: 100;
}
@media (max-width: 1024px) {
    .step2ImgWrap {
        width: 55px;
        height: 50px;
    }
}
@media (max-width: 900px) {
    .step2ImgWrap {
        width: 40px;
        height: 35px;
    }
}
.step3ImgWrap {
    width: 3.49vw;
    height: 4.67vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background:  #FFE9E2;
    border-radius: 17px;
    padding: 7px;
    margin-bottom: 7px;
    opacity: 0.5;
    z-index: 100;
}
@media (max-width: 1024px) {
    .step3ImgWrap {
        width: 55px;
        height: 50px;
    }
}
@media (max-width: 900px) {
    .step3ImgWrap {
        width: 40px;
        height: 35px;
    }
}
.step1ImgWrap:hover {
    opacity: 1;
    box-shadow: 0 2px 10px 0 rgba(160,141,248,0.90);
}
.step2ImgWrap:hover {
    opacity: 1;
    box-shadow: 0 2px 10px 0 rgba(216,157,0,0.90);
}
.step3ImgWrap:hover {
    opacity: 1;
    box-shadow: 0 2px 10px 0 rgb(245, 156, 121,0.90);
}
.step1ImgStyle {
    width: 2.0833333333333335vw;
}
@media (max-width: 1024px) {
    .step1ImgStyle {
        width: 38px;
    }
}
@media (max-width: 900px) {
    .step1ImgStyle {
        width: 23px;
    }
}
.step2ImgStyle {
    width: 1.7361111111111112vw;
}
@media (max-width: 1024px) {
    .step2ImgStyle {
        width: 38px;
    }
}
@media (max-width: 900px) {
    .step2ImgStyle {
        width: 24px;
    }
}
.step3ImgStyle {
    width: 2.7777777777777777vw;
    height: 3vh;
}
@media (max-width: 1024px) {
    .step3ImgStyle {
        width: 48px;
        height: auto;
    }
}
@media (max-width: 900px) {
    .step3ImgStyle {
        width: 30px;
        height: auto;
    }
}
.dashedLine {
    position: absolute;
    width: 65%;
    border-top: 2px dashed  #A8CEFF;
    z-index: 5;
    top: 40%;
    opacity: 0.5;
}