@import '../../sass/variables';

.container {
    width: 70%;
    margin: 0 auto;
    // margin-top: 100px;

    .head {
        font-size: 32 * $vw-const;
        font-weight: bold;
        margin-bottom: 20* $vw-const;
    }

    .content {
        margin-bottom: 60* $vw-const;
        font-size: 18* $vw-const;
        width: 90%;
    }

    thead {
        //  background: #9804f0;

        th {
            // color: white;
            font-weight: bold;
        }
    }

    tr {
        cursor: pointer;
    }

    .clickableCell {
        cursor: 'pointer';
        background-color: '#f0f0f0';
    }
}

.evenRow {
    background-color: #f0f3f6;
}

@media only screen and (max-width: 768px) {

    .container {
        width: 90%;
        .head {
            font-size: 20px;
        }
    
        .content {
            font-size: 14px;
        }
    }    
    
}