.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 500px;
  height: auto;
  position: relative;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;

}

.checkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.checkDescription {
  font-size: 1rem;
  font-weight: medium;
}

.header {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.footerLink {
  outline: none;
  border: none;
  background: #007aff;
  font-size: 0.8rem;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.footerLinkCancel {
  outline: none;
  border: 1px solid #007aff;
  background: #ffffff;
  font-size: 0.8rem;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.btn {
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: absolute;
  right: 2px;
  top: 2px;

  z-index: 999999;
}

.icon {
  height: 20px;
  width: 20px;
}

.spanScore {
  color: #aa6dce;
}



.moveRight {
  transition: transform 0.5s ease;
  transition: all 1s ease-out;

}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.move p {
  font-size: 0.5em !important;
}


@keyframes moveFromCenterToBottomRight {
  from {
    transform: translate(0%, 0%);
  }

  to {
    transform: translate(50%, 50%);
  }
}