.CutomTitleWrap {
    display: flex;
}
.resumeText {
    font-size: 5.48112vh;
    color: #130E49;
    letter-spacing: 2.25px;
    line-height: 55px;
    font-weight: bold;
    margin-right: 10px;
}
.scoreText {
    font-size: 5.48112vh;
    color: #AA6DCE;
    letter-spacing: 2.25px;
    line-height: 55px;
    font-weight: bold;
}
@media (max-width: 550px) {
    .resumeText {
        font-size: 3.48112vh;
    }
    .scoreText {
        font-size: 3.48112vh;
    }
}