.containerStyles {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;
}
.contentContainer {
    width: fit-content;
    position: relative;
    display: flex;
    align-items: baseline;
    padding-right: 22px;
    color: #AA6DCE;
}
.resumeHolderInput {
    font-size: 1.82704vh;
    background: transparent;
    border: 0px !important;
    color: #AA6DCE !important;
    outline: none;
}
@media (max-width: 550px) {
    .resumeHolderInput {
        font-size: 14px;
    }
}
.resumeHolderInput:focus {
    background: #D8D8D8;
    border-radius: 5px;
    outline: none !important;
    border: 1px solid #c7bdbd !important;
    box-shadow: 0 0 2px #ffffff !important;
}
.editIcon {
    position: absolute;
    width: 12px;
    height: 12px;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}
@media (max-width: 414px) {
    .editIcon {
        width: 12px;
        height: 12px;
    }
}
.EditFieldWrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.lable {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
    margin-left: 10px;
    margin-bottom: 20px;
}
.correctButton {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    border: 0px;
    cursor: pointer;
}
.correctIcon {
    width: 12px;
    height: 12px;
    
}
.resumeText {
    font-size: 1.82704vh;
    font-weight: bold;
    color: #130e49;
    letter-spacing: 0.65px;
    line-height: 16px;
    display: flex;
    align-items: center;
}
@media (max-width: 550px) {
    .resumeText {
        font-size: 14px;
    }
}
@media (max-height: 550px) {
    .resumeText {
        font-size: 14px;
    }
}
.noteStyles {
    font-size: 1.21803vh;
    font-style: italic;
    color: #130E49;
    letter-spacing: 0.4px;
    padding-top: 5px;
}
@media (max-height: 550px) {
    .noteStyles {
        font-size: 2.21803vh;
    }
}