.secodSectionContainer {
  position: relative;
  height: calc(100vh);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pannaLogo {
  cursor: pointer;
  width: 7.23611vw;
  height: 2.4vw;
  margin-top: 15px;
  margin-left: 15px;
  outline: none;
  border: none;
  z-index: 200;
}
@media (max-width: 700px) {
  .pannaLogo {
    margin-top: 8px;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 768px) {
  .pannaLogo {
    width: 80px;
    height: 24px;
  }
}
.dotScaleImg {
  width: 30%;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 15px;
}
@media (max-width: 550px) {
  .dotScaleImg {
    width: 40%;
  }
}
.triangle {
  position: absolute;
  z-index: 5;
}
.largeTriangle {
  width: 50%;
  bottom: 0;
  left: 2%;
}
@media (max-width: 550px) {
  .largeTriangle {
    width: 90%;
  }
}
.triangle1 {
  width: 15%;
  top: 25%;
  right: 0;
}
.triangle3 {
  width: 20%;
  bottom: 0;
  right: 10px;
}
.triangle2 {
  width: 10%;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 17%;
}
