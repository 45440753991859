.errorContentContainer {
    position: fixed;
    top: 0;
    width: 100%;
    height: calc(100vh);
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.errorContainer {
    width: 80%;
    background: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 4%;
    padding-right: 4%;
    box-shadow: 0 2px 10px 0 rgba(218, 228, 238, 0.9);
    z-index: 11000;
}
@media (max-width: 550px) {
    .errorContainer {
      flex-direction: column;
      justify-content: center;
      margin-top: 0%;
      padding-top: 25px;
      padding-bottom: 25px;
    }
    .unDrawStyle {
      width: 50%;
      margin-bottom: 10px;
    }
}
@media(max-height: 414px) {
  .errorContainer {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .unDrawStyle {
    width: 50%;
  }
}

.errorMessageWrap {
    flex: 1;
    height: 100%;;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  @media (max-width: 550px) {
    .errorMessageWrap {
      flex: 0;
    }
  }
.errorMessage {
    font-size: 20px;
    color: #707070;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 40px;
}
@media (max-width: 1050px) {
    .errorMessage {
      font-size: 18px;
    }
}
@media (max-width: 550px) {
    .errorMessage {
      font-size: 14px;
      margin-bottom: 20px;
    }
}
.errorText {
    font-size: 18px;
    color: #F44336;
    letter-spacing: 0.9px;
    text-align: center;
}
@media (max-width: 1024px) {
    .errorText {
      font-size: 14px;
    }
}
@media (max-width: 414px) {
    .errorText {
      font-size: 10px;
    }
}
@media (max-width: 550px) {
  .TryAgainButton {
    width: 150px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
}