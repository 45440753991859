@import '../../sass/variables';

.messageParentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    position: fixed;

    .messageContainer {
        width: 700* $vw-const;
        text-align: center;
        justify-content: center;
        font-weight: 400;

        .heading {
            font-size: clamp(24px, 36* $vw-const, 36* $vw-const);
            font-weight: bold;
            color: #707070;
            margin-bottom: 30* $vw-const;
        }

        .content1 {
            
            font-size: clamp(16px, 20* $vw-const, 20* $vw-const);
            margin-bottom: 30* $vw-const;
            display: flex;
            text-align: center;
            justify-content: center;
        }

        .content2 {
            font-size: clamp(14px, 16* $vw-const, 16* $vw-const);
            margin-bottom: 30* $vw-const;
            text-align: center;
        }

        .clickHereLink {
            color: blueviolet;
            margin-right: 10* $vw-const;
            cursor: pointer;
            text-decoration: underline;
        }
    }
}


@media only screen and (max-width: 900px) {
    .messageContainer {
        width: 90%!important;
        text-align: center;
        justify-content: center;
        font-weight: 400;
    }
}