.cardValueWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentContainer {
    margin-bottom: 30px;
}
 @media (max-width: 550px) {
     .contentContainer {
         margin-left: 0px;
     }
 }
.detailedCardTitle {
    font-size: 1.8270401948842874vh;
    color: #130E49;
    letter-spacing: 1px;
    font-weight: 600;
}
.SkillCountTitle {
    margin-top: 20px;
    margin-bottom: 10px;
}
@media (max-width: 550px) {
    .detailedCardTitle {
        font-size: 12px;
    }
}
@media (max-height: 550px) {
    .detailedCardTitle {
        font-size: 12px;
    }
}
.cardNameWrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
@media (max-width: 550px) {
    .cardNameWrap {
        margin-bottom: 10px;
    }
}
@media (max-height: 550px) {
    .cardNameWrap {
        margin-bottom: 10px;
    }
}
.matchSkillCardValueWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}
.cardValue {
    font-size: 30px;
    color: #FFFFFF;
    letter-spacing: 1.5px;
    text-align: center;
    margin-bottom: 20px;
    align-self: center;
}
@media (max-width: 414px) {
    .cardValue {
        font-size: 14px;
        margin-bottom: 10px;
    }
}
.cardValueStyle {
    font-size: 1.8270401948842874vh;
    letter-spacing: 1px;
    font-weight: 600;
}
@media (max-width: 550px) {
    .cardValueStyle {
        font-size: 10px;
    }
}
@media (max-height: 550px) {
    .cardValueStyle {
        font-size: 10px;
    }
}
.cardDescription {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0.7px;
    padding: 10px;
    margin-top: 20px;
}
@media (max-width: 414px) {
    .cardDescription {
        font-size: 8px;
    }
}
.skillsMatchedWrap {
    display: flex;
    flex-direction: column;
    padding: 0px;
}
.matchedSkillLabel {
    font-size: 18px;
    color: #22A600;
    letter-spacing: 0.9px;
    font-weight: 600 !important;
    font-weight: 400;
    margin-bottom: 20px;
}
@media (max-width: 414px) {
    .matchedSkillLabel {
        font-size: 14px;
        margin-bottom: 10px;
    }
}
.skillCountLabel {
    font-size: 18px;
    color: #130B4A;
    letter-spacing: 0.9px;
    font-weight: 600 !important;
    font-weight: 400;
    margin-bottom: 20px;
}
@media (max-width: 414px) {
    .skillCountLabel {
        font-size: 14px;
        margin-bottom: 10px;
    }
}
.unMatchedSkills {
    font-size: 18px;
    color: #F06A4A;
    letter-spacing: 0.9px;
    font-weight: 600 !important;
    margin-bottom: 20px;
    margin-Top: 20px;
}
@media (max-width: 414px) {
    .unMatchedSkills {
        font-size: 14px;
        margin-bottom: 10px;
        margin-Top: 10px;
    }
}
.actionVerbCategory {
    font-size: 18px;
    color: #22A600;
    letter-spacing: 0.9px;
    font-weight: 600 !important;
    margin-bottom: 15px;
    margin-Top: 15px;
}
@media (max-width: 414px) {
    .actionVerbCategory {
        font-size: 14px;
        margin-bottom: 10px;
        margin-Top: 10px;
    }
}
.computedSkills {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.actionVerbsWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.skillTextStyle {
    background: #EAEAEA;
    border-radius: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 8px;
    padding-left: 8px;
    margin: 5px;
    font-size: 15px;
    color: #000000;
    letter-spacing: 0.75px;
    text-align: center;
    margin-right: 40px;
}
@media (max-width: 414px) {
    .skillTextStyle {
        font-size: 12px;
        margin-right: 20px;
    }
}
.actionVerWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.spanScore {
    color: #AA6DCE;
}
.commentsStyle {
    font-size: 1.46163215590743vh;
    font-weight: 500;
    color: #130E49;
    letter-spacing: 1px;
    margin-bottom: 50px;
    text-align: justify;
}
@media (max-width: 550px) {
    .commentsStyle {
        font-size: 10px;
        margin-bottom: 30px !important;
    }
}
@media (max-height: 550px) {
    .commentsStyle {
        font-size: 10px;
        margin-bottom: 30px !important;
    }
}