@import '../../sass/variables';

.FileUploadContainer {
    position: relative;
    width: 100%;
    margin-top: 3.6540803897685747vh;
}

 
.btn1 {
    background-color: #EF5123;
    font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
    
    font-weight: 600;
    color: white;
    border-radius: 10px;
    padding: 10px 24px 10px 24px;
}

@media (max-width: 550px) {
    .FileUploadContainer {
        width: 90%;
        margin: 20px auto;
    }
}

.uploadArea {
    height: 32vh;
}

.uploadArea:hover {
    background: #F5FAFF !important;
    border: 2px dashed #000000 !important;
    box-shadow: 0 2px 3px 0 rgba(239, 239, 239, 0.92) !important;
}

@media (max-width: 1024px) {
    .uploadArea {
        height: 30.117vh;
    }
}

@media (max-height: 550px) {
    .uploadArea {
        height: 46vh;
    }
}

.uploadText {
    display: flex;
    font-size: 1.82704vh;
    color: #130E49;
    letter-spacing: 0.65px;
    font-weight: 600;
    margin-bottom: 10px;
}

@media (max-width: 550px) {
    .uploadText {
        text-align: center;
    }
}

@media (max-height: 550px) {
    .uploadText {
        font-size: 2.58344vh;
    }
}

.fullName {
    color: #A08DF8;
}

.uploadAreaStylewrap {
    //  background: #FFFFFF;
    box-shadow: 0 2px 3px 0 rgba(239, 239, 239, 0.92);
    border-radius: 15px;
    background-color: rgba(255, 255, 255);
}

.fileInputContainer {
    position: relative;
}

.placeHolderForFile {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.placeHolderForFile>div:nth-child(3) {
    z-index: 200;
}

.placeholderText {
    color: #000000;
    letter-spacing: 0.5px;
    text-align: center;
    font-weight: 400;
    font-size: 22 * $vw-const;
    line-height: 1.5;
}

@media (max-height: 550px) {
    .placeholderText {
        font-size: 12px;
    }
}

.fileImg {
    width: 31px;
    margin-bottom: 20px;
}

@media (max-width: 550px) {
    .fileImg {
        width: 26px;
        height: 32px;
        margin-bottom: 10px;
    }
}

.fileImg1 {
    position: relative;
    left: 28px;
    z-index: 10;
}

.fileImg2 {
    position: relative;
    top: 4px;
    z-index: 9;
    transition: all 0.3s linear;
}

.fileImg3 {
    position: relative;
    top: 8px;
    right: 28px;
    z-index: 8;
    transition: all 0.3s linear;
}

@media (max-width: 550px) {
    .fileImg1 {
        left: 23px;
    }

    .fileImg3 {
        right: 23px;
    }
}

.fileImg2After {
    position: relative;
    top: 4px;
    z-index: 9;
    transform: translateX(-34px) rotate(-25deg);
    transition: transform 0.3s linear;
}

.fileImg3After {
    position: relative;
    z-index: 8;
    top: 8px;
    right: 28px;
    transform: rotate(20deg) translateY(-14px) translateX(22px);
    transition: transform 0.3s linear;
}

.fileImg2Uploading {
    position: relative;
    top: 0px;
    z-index: 9;
    transform: translateX(-50px) !important;
    transition: transform 0.3s linear !important;
}

.fileImg3Uploading {
    position: relative;
    z-index: 8;
    top: 4px;
    right: 28px;
    transform: translateY(-5px) translateX(40px) !important;
    transition: transform 0.3s linear !important;
}

.span {
    /* you can change these variables to control the border */
    --border-color: #007AFF;
    --border-width: 3px;
    --bottom-distance: -3px;
    /* you can increase this */

    color: #007AFF;
    display: inline-block;
    background-image: linear-gradient(var(--border-color), var(--border-color));
    background-size: 0% var(--border-width);
    background-repeat: no-repeat;
    transition: background-size 0.3s;
    background-position: 50% calc(100% - var(--bottom-distance));
    font-size: 22 * $vw-const;
}
.dropLabel {
    font-size: clamp(14px, 18 * $vw-const, 18 * $vw-const);
    color: #787878;
    margin-top: 20px;
}

.supportFormat {
    font-size: 1.33983vh;
    color: #999999;
    letter-spacing: 0.45px;
    text-align: center;
    font-weight: 400;
    margin-top: 12px;
}

@media (max-width: 550px) {
    .supportFormat {
        margin-top: 6px;
    }
}

@media (max-height: 550px) {
    .supportFormat {
        font-size: 2.096224vh;
    }
}

.uploadingText {
    font-size: 1.46163215590743vh;
    font-weight: 600;
    color: #000000;
    letter-spacing: 0.5px;
    text-align: center;
}

@media (max-height: 550px) {
    .uploadingText {
        font-size: 12px;
    }
}

.completedText {
    font-size: 10px;
    font-weight: 600;
    color: #4CAF50;
    letter-spacing: 0.5px;
    text-align: center;
}

.afterUploadSection {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.docImg {
    width: 20px;
    margin-right: 10px;
}

@media (max-width: 550px) {
    .docImg {
        width: 25px;
        height: 32px;
        margin: 0px 6px;
    }
}

.fileName {
    flex: 1 1;
    display: flex;
    align-items: center;
    height: 4.284vh !important;
    font-weight: 600;
    color: #000000;
    letter-spacing: 0.5px;
    margin-right: 20px;
    word-break: keep-all;
    overflow-x: auto;
    z-index: 100;
    font-size: 0.6rem;
}

@media (max-height: 550px) {
    .fileName {
        font-size: 0.6rem;
    }
}

.quitButton {
    display: flex;
    z-index: 100;
}

.quiteStyle {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.errorContainer {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.errorText {
    font-size: 1.21803vh;
    font-weight: 600;
    color: #F44336;
    text-align: center;
    letter-spacing: 0.5px;
}

@media (max-height: 550px) {
    .errorText {
        font-size: 2.21803vh;
    }
}

.errorImg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

@media (max-width: 414px) {
    .errorImg {
        width: 12px;
        height: 12px;
    }
}


@media (max-width: 767px) {
    .placeholderText, .span {
        font-size: 12px;
    }

}