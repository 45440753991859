
@import '../../../sass/variables';




.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  inset: 0;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}

.content {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  justify-content: flex-start;
  /*  background: white; */
  background: #fafafa;
  border-radius: 10px;
  width: 333px;
  padding: clamp(20px, 35 * $vw-const, 35 * $vw-const);
  padding-bottom: 20px;
  flex-direction: column;
  height: 520px;
  overflow: auto;
  font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
}
.contentContainer {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  
}

.heading {
  font-size: clamp(16px, 24 * $vw-const, 24 * $vw-const);
  font-weight: 700;
  text-align: left;
  margin-top: 10px;
}
.terms {
  color: #FF8E80;
  text-decoration: underline;
  font-weight: 700;
  cursor: pointer;
}

.downLoadSection {
  width: 100%;
//  border: 1px solid #C5C5C5;
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  flex-direction: column;
  width: 100%;

  .topSec {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    margin-top: 20px;
    width: 100%;
  }

  .CheckNavButton {
    border: none;
    background-color: transparent;
  }
  .pdfIcon {
    //background-color: #F6F6F8;
    // width: 45%;
    margin: 5px;
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    top: -20px;
    position: relative;

    img {
      width: 50px;
      margin-right: 15px;
    }


    .span {
      color: grey;
      font-weight: 300;
      text-align: left;
      line-height: 1.5;
    }
  }

  .wordIcon {
    // background-color: #F6F6F8;
    // width: 45%;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    top: -20px;
    position: relative;

    img {
      width: 50px;
      margin-right: 15px;
    }
    .span {
      color: grey;
      text-align: left;
      line-height: 1.5;
      font-weight: 300;
    }
  }

  .text {
    font-size: 14px;
    width: 100%;
    color: #777777;
    margin-bottom: 10px;
    margin-top: -10px;
  }

}

.feedBackBoxContainer {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
 // background-color: white;
  flex-direction: column;
  .feedbackBox {
      width: 100%;
      height: 120px;
      margin-bottom: 10px;
      padding: 10px;
      resize: none;
      border-color: #C4C4C4;
      font-size: 14px;
      border-radius: 5px;
  }
}
.btn {
  background-color: #E06A09;
  font-weight: 400;
  border-radius: 10px;
  margin-top: 10px;
}
@media (max-width: 767px) {
  .content {
    width: 70%;
    height: 90vh;
  } 
}