.containerStyle {
    height: 13.398294762484774vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    padding: 20px;
}
@media (max-width: 1680px) {
    .containerStyle {
        height: 17.05237515225335vh;
    }
}
@media (max-width: 500px) {
    .containerStyle {
        height: 95px !important;
        padding: 6px !important;
    }
}
@media (max-height: 500px) {
    .containerStyle {
        height: 120px !important;
        padding: 6px !important;
    }
}
.infoImg {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 5px;
    right: 5px;
}
.ContentWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.title {
    font-size: 15px;
    color: #000000;
    letter-spacing: 0;
    font-weight: 600;
    text-align: left;
    margin-bottom: 5px;
}
@media (max-width: 1440px) {
    .title {
        font-size: 14px;
    }
}

@media (max-width: 414px) {
    .title {
        font-size: 12px;
    }
}
.reportValue {
    margin: 0 auto;
}
.placeholderContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
}
.placeholder1 {
    width: 78%;
    height: 20px;
    background-color: #F6F5FB;
    border-radius: 8px;
    opacity: 0.7;
    animation-name: placeholder1;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
@keyframes placeholder1 {
    50% {width: 80%;opacity: 1;}
    100% {width: 78%;opacity: 0.7;}
}
.placeholder2 {
    width: 38%;
    height: 20px;
    background-color: #F6F5FB;
    border-radius: 8px;
    opacity: 0.7;
    animation-name: placeholder2;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}
@keyframes placeholder2 {
    50% {width: 40%;opacity: 1;}
    100% {width: 38%;opacity: 0.7;}
}
.LoaderWrapper {
    display: flex;
    justify-content: center;
}
.toolTipPortal > div{
    background-color: red !important;
}
.ToolTipCustom {
    background-color: red !important;
    /* width: 200px;
    height: 200px; */
}
.description {
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 600;
    letter-spacing: 1px;
}
@media (max-width: 1440px) {
    .description {
        font-size: 14px;
    }
}

@media (max-width: 414px) {
    .description {
        font-size: 12px;
    }
}
.renderItemwrap {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.infoIcon {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}