.container {
  position: fixed;
  z-index: 100;
  inset: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  position: relative;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.icon {
  height: 20px;
  width: 20px;
}

.CheckNavButton {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #007aff;
  box-shadow: 0 2px 10px 0 rgba(208, 208, 208, 0.5);
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0px;
  cursor: pointer;
}
@media (max-width: 414px) {
  .CheckNavButton {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.formInputContainer {
  margin-bottom: 5px;
}
.label {
  font-size: 0.8rem;
  font-weight: bold;
}
.input {
  width: 90%;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  outline: none;
  position: relative;
}

.footerLink {
  outline: none;
  border: none;
  background: transparent;
  font-size: 0.8rem;
  color: #007aff;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.error {
  color: red;
  font-size: 0.8rem;
}

.success {
  color: green;
  font-size: 0.8rem;
}

.spanScore {
  color: #aa6dce;
}

.footerLink {
  outline: none;
  border: none;
  background: #007aff;
  font-size: 0.8rem;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.footerLinkCancel {
  outline: none;
  border: 1px solid #007aff;
  background: #ffffff;
  font-size: 0.8rem;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}
