.saveJdContainer {
    width: 35%;
    height: auto;
    background: #FCF8FF;
    border-radius: 5px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 550px) {
    .saveJdContainer {
        width: 80%;
    }
}
.headingStyle {
    width: 100%;
    height: 45px;
    font-size: 2.3605vh;
    font-weight: 600;
    background-color: #DAD6DC;
    color: #6D597A;
    letter-spacing: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}   
.labelStyle {
    font-size: 2.3605vh;
    font-weight: 600;
    color: #707070;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 40px;
}
@media (max-width: 550px) {
    .labelStyle {
        font-size: 1.3605vh;
        margin-bottom: 20px;
    }
}
.buttonWrap {
    width: 100%;
    display: flex;
    justify-content: center;
}
@media (max-width: 550px) {
    .buttonWrap {
        width: auto;
    }
}
.cancelButtonStyle {
    width: 8vw;
    min-width: 100px;
    background: #959595;
    border-radius: 5px;
    font-weight: 600;
    margin-right: 20px;
}
.cancelButtonStyle:hover {
    box-shadow: 0 2px 10px 0 rgba(126, 126, 126, 0.9);
}
.saveButtonStyle {
    width: 8vw;
    min-width: 100px;
    font-weight: 600;
}