@import '../../../sass/variables';

.insightsContainer {
    width: 78%;
    margin: 0 auto;

    .mainHeading {
        padding-top: 40px;
        font-size: 50px;
        line-height: 75px;
        font-weight: 700;
    }

    .mainInsight {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        gap: 50px;

        .leftContainer {
            flex: 0 0 40%;
            .dateContainer {
                display: flex;
                gap: 8px;

                .calenderImg {
                    height: 21px;
                }
                .calenderText {
                    font-size: 16px;
                    font-weight: 400;
                    color: #1D1D1D;
                }
            }

            .mainInsightTitle {
                font-size: 30px;
                line-height: 39px;
                padding-top: 15px;

                .title {
                    font-weight: 700;
                }

                .subTitle {
                    font-weight: 400;
                }
            }

            .description {
                font-size: 18px;
                font-weight: 400;
                line-height: 26px;
                padding-top: 20px;
            }

            .linkButton {
                margin-top: 20px;
                font-size: 16px;
                line-height: 24px;
                background-color: transparent;
                color: #EF5123;
                border-radius: 5px;
                border: 1px solid rgba($color: #EF5123, $alpha: 0.08);
                padding: 8px 16px;
                cursor: pointer;
            }
            
            .linkButton:hover
            .linkButton:active {
                border: 1px solid rgba($color: #EF5123, $alpha: 0.8);
            }
        }

        .rightContainer {
            flex: 0 0 60%;

            .link {
                .mainInsightImage {
                    width: 100%;
                    max-width: 500px;
                }
            }
        }
    }

    @media only screen and (max-width: 980px) {
        .mainInsight {
            flex-direction: column-reverse;
        }
    }
}

.latestArticlesContainer {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #FAFAFA;

    .latestArticlesSubContainer {
        width: 78%;
        margin: 0 auto;

        .mainTitle {
            font-size: 30px;
            line-height: 39px;
            font-weight: 400;
        }

        .insightTiles {
            padding-top: 20px;
        }
    }
}

.insightTilesContainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    
    .link{
        transition: all .5s;
        flex: 0 0 300px;
        text-decoration: none;
        color: #000;
        .insightTileContainer {
            
            .insightImage {
                width: 100%;
                max-width: 300px;
                height: 210px;
            }

            .mainInsightTitle {
                font-size: 18px;
                line-height: 25px;
                padding-top: 15px;

                .title {
                    font-weight: 700;
                }

                .subTitle {
                    font-weight: 400;
                }
            }
            
            .dateContainer {
                display: flex;
                gap: 8px;
                padding-top: 16px;
                
                .calenderImg {
                    height: 21px;
                }
                .calenderText {
                    font-size: 16px;
                    font-weight: 400;
                    color: #1D1D1D;
                    // text-decoration: none !important;
                }
            }
        }
    }

    .link:hover {
        text-decoration: underline;
        scale: 1.02;
    }

    @media only screen and (max-width: 1220px) {
        .link{
            flex: 0 0 280px;
        }
    }

    @media only screen and (max-width: 1130px) {
        .link{
            flex: 0 0 250px;
        }
    }

    @media only screen and (max-width: 1015px) {
        .link {
            flex: 0 0 300px;
        }
    }

    @media only screen and (max-width: 800px) {
        .link {
            flex: 0 0 250px;
        }
    }
}

@media only screen and (max-width: 1015px) {
    .insightTilesContainer {
        justify-content: space-between;
    }
}