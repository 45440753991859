.ContentcontainerStyle {
    width: 100%;
    display: flex;
    align-items: center;
}
.ContentcontainerStyle > div > div > div {
    border: 0px !important;
}
.ContentcontainerStyle > div > div > div > div {
    border-radius: 20px !important;
}
.ContentcontainerStyle > div > div > h2 {
    display: none !important;
}
.ContentcontainerStyle > span {
    font-size: 13px;
    color: #000000;
    font-weight: bold;
}
@media (max-width: 414px) {
    .ContentcontainerStyle > span {
        font-size: 10px;
    }
}