.loadingContainer {
    position: relative;
    width: 80%;
    height: 80%;
    background: #FFFFFF;
    box-shadow: 0 2px 30px 0 rgba(208,208,208,0.50);
    border-radius: 10px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media (max-width: 550px) {
    .loadingContainer {
        width: 95%;
    }
}
.FirstImg {
    width: 30%;
    transform: translate(-50%);
    opacity: 0;
    visibility: hidden;
    animation-name: FirstSection;
    animation-duration: 3s;
}
@media (max-width: 550px) {
    .FirstImg {
        width: 50%;
    }
}
@media (max-height: 550px) {
    .FirstImg {
        width: 25%;
    }
}
@keyframes FirstSection {
    70% {opacity: 1;visibility: visible;transform: translate(0);transition: transform 1s ease-in;}
    100% {opacity: 0;visibility: hidden;transform: translate(50%);transition: transform 1s ease-in;}
}
.secondSectionWrap {
    position: relative;
    margin-right: 30%;
}
@media (max-width: 800px) {
    .secondSectionWrap {
        margin-right: 25%;
    }
}
.secondSection{
    position: absolute;
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hideSecondSection {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-50%);
    transition: all ease-in-out 1s;
}
/* @keyframes SecondSection {
    100% {opacity: 1;visibility: visible;transform: scale(1.5,1.15);transition: all 1s ease-in;}
  } */
.SecondImg {
    width: 100%;
    max-width: 220px;
    animation-name: SecondSection;
    animation-duration: 1s;
    animation-delay: 2s;
    opacity: 0;
    visibility: hidden;
    transform: scale(0,0);
    box-shadow: 0 2px 30px 0 rgb(187, 187, 187);
}
@keyframes SecondSection {
    100% {opacity: 1;visibility: visible;transform: scale(1,1);transition: all 0.5s ease-in;}
  }
.ThirdImg {
    width: 100%;
    max-width: 220px;
    animation-name: ThirsSection;
    animation-duration: 1s;
    animation-delay: 2s;
    opacity: 0; 
    visibility: hidden;
    transform: scale(0,0);
    box-shadow: 0 2px 30px 0 rgb(187, 187, 187);
}
@keyframes ThirsSection {
    100% {opacity: 1;visibility: visible;transform: scale(1,1);transition: all 0.5s ease-in;}
}
.SecondImgAfterAnimation {
    width: 100%;
    max-width: 220px;
    margin-right: 20%;
    opacity: 1;
    visibility: visible;
    transform: scale(1,1);
    box-shadow: 0 2px 30px 0 rgb(187, 187, 187);
}
.ThirdImgAfterAnimation {
    width: 100%;
    max-width: 220px;
    opacity: 1;
    visibility: visible;
    transform: scale(1,1);
    box-shadow: 0 2px 30px 0 rgb(187, 187, 187);
}
@media (max-height: 550px) {
    .SecondImg {
        max-width: 110px;
    }
    .SecondImgAfterAnimation {
        max-width: 110px;
    }
    .ThirdImg {
        max-width: 110px;
    }
    .ThirdImgAfterAnimation {
        max-width: 110px;
    }
}
.softSkillImg {
    position: absolute;
    width: 50%;
    top: 14%;
    right: -25%;
    transform: scale(0,0);
}
.hardSkillImg {
    position: absolute;
    width: 50%;
    top: 30%;
    left: -22%;
    transform: scale(0,0);
}
.experienceSkillImg {
    position: absolute;
    width: 50%;
    top: 46%;
    right: -10%;
    transform: scale(0,0);
}
.eductionSkillImg {
    position: absolute;
    width: 50%;
    bottom: 9%;
    left: -22%;
    transform: scale(0,0);
}
.certificateSkillImg {
    position: absolute;
    width: 55%;
    bottom: 8%;
    right: -30%;
    transform: scale(0,0);
}
.ThirdSectionWrap {
    position: relative;
}
.hardSkill2Img {
    position: absolute;
    width: 65%;
    top: 15%;
    right: -30%;
    z-index: 10;
    transform: scale(0,0);
}
.softSkill2Img {
    position: absolute;
    width: 65%;
    top: 51%;
    left: -40%;
    z-index: 10;
    transform: scale(0,0);
}
.requirementsSkillImg {
    position: absolute;
    width: 70%;
    bottom: 9%;
    right: -35%;
    z-index: 10;
    transform: scale(0,0);
}
.scaleImgAnimation {
    transform: scale(1,1);
    transition: all 0.5s ease-in;
}
.contentImg1Img {
    position: absolute;
    width: 20%;
    top: 32%;
    right: 8%;
    opacity: 0;
    visibility: hidden;
}
.contentImg1ImgAnimation {
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease-in;
}
.contentImg1ImgFinalAnimation {
    left: 73%;
    right: auto;
    margin-left: 50%;
    margin-top: 100%;
    transform: scale(0,0);
    transition: all ease-in-out 1s;
}
.contentImg2Img {
    position: absolute;
    width: 20%;
    top: 45%;
    left: 0%;
    opacity: 0;
    visibility: hidden;
}
.contentImg2ImgAnimation {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.3s;
    transition: all 0.8s ease-in;
}
.contentImg2ImgFinalAnimation {
    left: 100%;
    right: 0;
    margin-left: 50%;
    margin-top: 100%;
    transform: scale(0,0);
    transition-delay: 0.6s !important;
    transition: all ease-in-out 1s;
}
.contentImg3Img {
    position: absolute;
    width: 20%;
    bottom: 28%;
    right: 15%;
    opacity: 0;
    visibility: hidden;
}
.contentImg3ImgAnimation {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.6s !important;
    transition: all 0.8s ease-in;
}
.contentImg3ImgFinalAnimation {
    left: 65%;
    right: 0;
    margin-left: 100%;
    margin-bottom: -100% !important;
    transform: scale(0,0);
    transition-delay: 1.2s !important;
    transition: all ease-in-out 1s;
}
.contentImg4Img {
    position: absolute;
    width: 20%;
    bottom: 0%;
    right: 15%;
    opacity: 0;
    visibility: hidden;
}
.contentImg4ImgAnimation {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.9s !important;
    transition: all 0.8s ease-in;
}
.contentImg4ImgFinalAnimation {
    left: 65%;
    right: 0;
    margin-left: 100%;
    margin-bottom: -60% !important;
    transform: scale(0,0);
    transition-delay: 1.8s !important;
    transition: all ease-in-out 1s;
}
.contentImg5Img {
    position: absolute;
    width: 20%;
    bottom: -5%;
    left: -10%;
    opacity: 0;
    visibility: hidden;
}
.contentImg5ImgAnimation {
    opacity: 1;
    visibility: visible;
    transition-delay: 1.2s !important;
    transition: all 0.8s ease-in;
}
.contentImg5ImgFinalAnimation {
    left: 0;
    right: 0;
    margin-left: 100%;
    margin-bottom: -60% !important;
    transform: scale(0,0);
    transition-delay: 2.4s !important;
    transition: all ease-in-out 1s;
}
@media (max-height: 550px) {
    .contentImg3ImgFinalAnimation {
        margin-bottom: -40% !important;
    }
    .contentImg4ImgFinalAnimation {
        margin-bottom: -30% !important;
    }
    .contentImg5ImgFinalAnimation {
        margin-left: 100%;
        margin-bottom: -20% !important;
    }
}
.jdContentImg1Img {
    position: absolute;
    width: 20%;
    top: 32%;
    right: 8%;
    opacity: 0;
    visibility: hidden;
}
.jdContentImg1ImgAnimation {
    opacity: 1;
    visibility: visible;
    transition-delay: 1.5s !important;
    transition: all 0.8s ease-in;
}
.jdContentImg1ImgFinalAnimation {
    left: auto;
    right: 0;
    margin-right: 100%;
    margin-top: 100% !important;
    transform: scale(0,0);
    transition-delay: 3s !important;
    transition: all ease-in-out 1s;
}
.jdContentImg2Img {
    position: absolute;
    width: 20%;
    bottom: 23%;
    left: 10%;
    opacity: 0;
    visibility: hidden;
}
.jdContentImg2ImgAnimation {
    opacity: 1;
    visibility: visible;
    transition-delay: 1.5s !important;
    transition: all 0.8s ease-in;
}
.jdContentImg2ImgFinalAnimation {
    left: auto;
    right: 70%;
    margin-right: 100%;
    margin-bottom: -80% !important;
    transform: scale(0,0);
    transition-delay: 3.6s !important;
    transition: all ease-in-out 1s;
}
.jdContentImg3Img {
    position: absolute;
    width: 20%;
    bottom: 0%;
    right: 15%;
    opacity: 0;
    visibility: hidden;
}
.jdContentImg3ImgAnimation {
    opacity: 1;
    visibility: visible;
    transition-delay: 1.8s !important;
    transition: all 0.8s ease-in;
}
.jdContentImg3ImgFinalAnimation {
    left: auto;
    margin-right: 100%;
    margin-bottom: -60% !important;
    transform: scale(0,0);
    transition-delay: 4.2s !important;
    transition: all ease-in-out 1s;
}
@media (max-height: 550px) {
    .jdContentImg2ImgFinalAnimation {
        margin-bottom: -60% !important;
    }
    .jdContentImg3ImgFinalAnimation {
        margin-bottom: -20% !important;
    }
}
.DoneImageWrap {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
}
.DoneImageWrapAnimation {
    opacity: 1;
    visibility: visible;
    transition: opacity ease-in-out 1s;
}
.doneImgStyle {
    width: 50%;
    margin-bottom: 30%;
    transform: scale(0.3,0.3);
}
.doneImgStyleAnimation {
    transform: scale(1,1);
    transition: all ease-in 1s;
}
.doneText {
    font-size: 5vh;
    font-weight: bold;
    color: #15A637;
    letter-spacing: 2.5px;
    text-align: center;
}
.progressBarWrap {
    position: absolute;
    bottom: 3%;
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media (max-width: 550px) {
    .progressBarWrap {
        width: 80%;
    }
}
.progressWrapAnimation {
    opacity: 0;
    visibility: hidden;
    transform: translateX(20%);
    transition: all ease-in-out 1s;
}
.progressbarConteinerStyle {
    display: flex;
    justify-content: center;
}
.progressBar {
    width: 60%;
    height: 20px;
}
.measuringText {
    display: flex;
    font-size: 2.92326vh;
    font-weight: 600;
    color: #000000;
    letter-spacing: 1.5px;
    margin-top: 20px;
}
.loadingText {
    font-size: 2.92326vh;
    font-weight: 600;
    color: #000000;
    letter-spacing: 2.5px;
}
@media (max-height: 550px) {
    .loadingText {
        font-size: 2.58344vh;
    }
}
.loadingTextWrap {
    position: absolute;
    top: 5%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.loadingTextWrapAnimation {
    opacity: 0;
    visibility: hidden;
    transform: translateX(20%);
    transition: all ease-in-out 1s;
}
@media (max-width: 550px) {
    .loadingText {
        font-size: 14px;
        text-align: center;
    }
    .measuringText {
        font-size: 14px;
    }
    .loadingTextWrap {
        width: 80%;
    }
}