@import '../../../../../sass/variables';

.wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  .flexWrapper {
    font-family: "Poppins", sans-serif;
    width: 78%;
    margin: 0 auto;
    padding-top: 50px;
    display: flex;
    gap: 5%;

    .container {
      flex: 0 1 65%;

      .dateContainer {
        display: flex;
        gap: 5px;

        .calenderImg {
          height: 22px;
        }

        .date {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          color: #1D1D1D;
        }
      }

      .title {
        font-size: 50px;
        line-height: 55px;
        font-weight: 700;
        padding-top: 15px;
      }

      .heroImage {
        padding-top: 50px;
        width: 100%;
      }
    }

    .restInsightContainer {
      padding-top: 150px;
      flex: 0 1 30%;

      .recentInsightHeader {
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        border-bottom: 1px dashed #E7DFDF;
        padding-bottom: 20px;
      }

      .recentInsightLink {
        text-decoration: none;
        color: #000;

        .recentInsightWrapper {
          padding-top: 20px;
          padding-bottom: 20px;
          display: flex;
          gap: 10px;
          align-items: center;
          border-bottom: 1px dashed #E7DFDF;

          .recentInsightImg {
            width: 80px;
          }

          .recentInsightTitle {
           // height: 80px;

            .title {
              font-size: 14px;
              line-height: 14px;
              font-weight: 700;
            }

            .subTitle {
              font-size: 14px;
              line-height: 14px;
              font-weight: 400;
            }
          }
        }
      }

      .recentInsightLink:hover {
        text-decoration: underline;
      }
    }
  }
}
