.ContentContainerStyle {
    position: relative;
    width: 20.852vw;
    display: flex;
    flex-direction: column;
}
@media (max-width: 550px) {
    .ContentContainerStyle {
        width: 170px;
    }
}
.dropDown {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    font-size: 1.76163vh;
    color: #130E49;
    letter-spacing: 0.4px;
    font-weight: 300;
    border-radius: 5px;
    padding: 0.5rem;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(229,229,229,0.93);
}
@media (max-height: 550px) {
    .dropDown {
        font-size: 2.1vh;
    }
}
.dropDown:hover {
    outline: none !important;
    box-shadow: 0 0 2px #929292 !important;
}
.defaultDescription {
    font-size: 14px;
    font-weight: 600;
    color: #AEAEAE;
    letter-spacing: 0;
    margin-bottom: 5px;
}
@media (max-width: 700px) {
    .defaultDescription {
        font-size: 10px;
    }
}
.placeholder {
    font-size: 14px;
    color: #BBBBBB;
}
.dropDownImg {
    position: absolute;
    right: 10px;
    width: 12px;
    height: 8px;
}
.individualJd {
    width: 100%;
    position: absolute;
    top: 100%;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    z-index: 30000;
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba(211,211,211,0.93);
    border-radius: 5px;
    margin-top: 2px;
}
.individualJd li {
    position: relative;
    font-size: 1.46163vh;
    color: #000000;
    letter-spacing: 0.4px;
    padding: 0.3rem;
    z-index: 10;
    flex: 1;
}
@media (max-height: 550px) {
    .individualJd li {
        font-size: 2.1vh;
    }
}
.resetButton {
    position: relative;
    height: 100%;
    margin: 0 auto;
    top: 0;
    right: 5px;
    display: flex;
    align-items: center;
    z-index: 100;
}
.quiteStyle {
    width: 10px;
    height: 10px;
    align-self: center;
    z-index: 100;
    cursor: pointer;
}
.ListItemWrap {
    display: flex;
    align-items: center;
}
.ListItemWrap:hover {
    background: #E7F3FF;
    cursor: pointer;
}
.buttonWrap {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    display: flex;
    z-index: 40000;
}
.defaultButton {
    flex: 1;
    font-size: 1.46163vh;
    font-weight: 600;
    text-align: center;
    padding: 0.4rem;
    cursor: pointer;
    border-bottom: 1px solid #979797;
    border-right: 1px solid #000000;
}
.savedButton {
    flex: 1;
    font-size: 1.46163vh;
    font-weight: 600;
    text-align: center;
    padding: 0.4rem;
    cursor: pointer;
    border-bottom: 1px solid #979797;
    border-left: 1px solid #000000;
}
.emptyList {
    position: relative;
    font-size: 1.46163vh;
    color: #000000;
    letter-spacing: 0.4px;
    padding: 0.3rem;
    z-index: 10;
    flex: 1;
    text-align: center;
}