@import '../sass/variables';

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  padding: 40px 0px;
}

.popupContainer {
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, .5019607843137255);
  z-index: 9999;
  left: 0;
  font-family: Poppins, sans-serif;
}

.subContainer{
  width: 880 * $vw-const;
  background-color: white;
}

.popupSubContainer {
  width: 880 * $vw-const;
  height: 880 * $vh-const;
  position: relative;
  background-color: white;
  /* border-left: solid; */
  flex-direction: column;
  /* padding: 0.89vh; */
  padding: 20px 0 100 * $vh-const 0px;
  border-radius: 5px;
}

.bodyContainer {
  flex-direction: column;
  padding: 20 * $vh-const 59 * $vw-const;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error {
  border-left-width: 1vw;
  border-left-color: #f85b65;
}

.success {
  border-left-width: 1vw;
  border-left-color: #44dbae;
}

.close {
  display: none;
}

.popupClose {
  position: absolute;
  top: -8px;
  right: -8px;
  width: 2.5vh;
  height: 2.5vh;
  color: #000000;
  cursor: pointer;
}

.buttonContainer {
  height: auto;
  padding: 0.89vh;
  flex-direction: row;
  justify-content: center;
  margin-top: 50 * $vh-const;
}

.textContainer {
  flex-direction: row;
  flex: 1;
  /* padding: 0.89vh; */
  padding-right: 1.78vh;
  color: #707070;
  font-size: 1.85vh;
  display: flex;
  align-items: center;
}

.heading {
  font-size: clamp(14px, 24 * $vw-const, 24 * $vw-const);
  color: #002c94;
  letter-spacing: 0.96px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30 * $vw-const;
}

.content {
  font-size: clamp(12px, 14 * $vw-const, 14 * $vw-const);
  color: #202428;
  letter-spacing: 0.56px;
  line-height: 1.5;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 15px;
}

.title {
  font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
  color: #202428;
  letter-spacing: 0.56px;
  font-weight: 700;
  // text-decoration: underline;

}

.message {
  font-size: 2vh;
}

.button {
  height: 48 * $vh-const;
  width: 160 * $vh-const;
  border-radius: 0.37vh;
  color: #0045e6;
  border: none;
  background: #e6edff 0% 0% no-repeat padding-box;
  justify-content: end;
  justify-self: flex-end;
  font-size: 16 * $vh-const !important;
  cursor: pointer;

  margin-right: 10px;
}

.icon {
  width: 2vw;
  margin-right: 0.89vh;
}

.popupTextarea {
  height: 200px;
  width: 90%;
  padding: 15px;
}

@media screen and (max-width: 640px) {
  .subContainer{
    width: 90%;
  }

  .popupSubContainer {
    width: 90%;
  }

  .icon {
    height: 50px;
  }

  .button {
    width: 15.854vw;
    font-size: 1.42vh;
    height: 3.2vh;
  }
}