@import '../../../sass/variables';

.container {
  display: flex;
  // background-color: #F2F2F6;
  // position: fixed;
  // top: 0;
  width: 78%;
  height: 15vh;
  min-height: 90px;
  font-family: "Poppins", sans-serif;
  margin: 0 auto;
}

.secodSectionContainer {
  position: relative;
  height: calc(100vh);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pannaRBLogo {
  cursor: pointer;
  width: clamp(220px, 380 * $vw-const, 380 * $vw-const);
  // height: 2.1vw;
  margin-right: 15px;
  outline: none;
  border: none;
  z-index: 200;

}

.pannaLogo {
  cursor: pointer;
  width: clamp(100px, 150 * $vw-const, 150 * $vw-const);
  // height: 2.1vw;
  margin-right: 15px;
  outline: none;
  border: none;
  z-index: 200;

}

.pannalogoLink {
  text-decoration: none!important;
 
  display: flex;
  align-items: center;
}
.logoLabel {
  color: #000000;
  text-decoration: none;
  font-weight: 300;
  font-size: clamp(14px, 32 * $vw-const, 32 * $vw-const);
}
.betaLogo {
  cursor: pointer;
  width: 1.53611vw;
  outline: none;
  border: none;
  z-index: 200;
  margin-left: 2px;
}

.menuBtn {
  color: #000000;
  width: fit-content;
  font-weight: 400;
  line-height: 2;
  font-family: "Poppins", sans-serif;
  font-size: clamp(14px, 18 * $vw-const, 18 * $vw-const);
}

.menuBtn:hover,
.menuBtnActive {
  font-weight: 600;
  color: #000000;
  // text-decoration: underline;
  // text-decoration: underline;
  // text-decoration-color: #4936D3;
 // text-underline-position: under;
  //  text-decoration: underline;

}

.loginBtn {
  width: max-content;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 40px;
  padding-right: 40px;
  /* background: #007AFF; */
  border: 0px;
  border-radius: 5px;
  text-align: center;
  align-self: center;
  cursor: pointer;
  border: 1px solid #333;
  font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
  color: #000000;
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.loginBtn:hover {
  background-color: #4936D3;
  cursor: pointer;
  color: white;
  border: 1px solid #333;
  font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
}

@media (max-width: 900px) {
  .pannaLogo {
    margin-top: 8px;
    margin-left: 10px;
  }
  .container {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {

 

  .pannaLogo {
    width: 80px;
    height: 24px;
  }
}

.dotScaleImg {
  width: 30%;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 15px;
}

@media (max-width: 550px) {
  .dotScaleImg {
    width: 40%;
  }
}

.triangle {
  position: absolute;
  z-index: 5;
}

.largeTriangle {
  width: 50%;
  bottom: 0;
  left: 2%;
}

.profileLinkContainer {
  width: 30px;
  height: 30px;
  fill: #7244e7;
  cursor: pointer;
  border-radius: 15px;
  background-color: #f0f3f6;

  svg {
    margin: 5px;
  }
}

.profileLinkContainer:hover {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
}

@media (max-width: 550px) {
  .largeTriangle {
    width: 90%;
  }
}

.triangle1 {
  width: 15%;
  top: 25%;
  right: 0;
}

.triangle3 {
  width: 20%;
  bottom: 0;
  right: 10px;
}

.triangle2 {
  width: 10%;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 17%;
}


.dropDownMenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  min-width: 120px;
}

.logoutButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 0.9rem;
  color: #5f5f5f;
  width: fit-content;
  font-weight: 300;
}

.logoutButton:hover {
  font-weight: 500;
  color: #09024b;
}// styles.module.scss

.profileMenuContainer {
  position: relative;
}

.profileLinkContainer {
  cursor: pointer;
}

.profileIcon {
  /* Your icon styles */
}

.dropdownMenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menuItems {
  display: flex;
  flex-direction: column;
}

.menuItem {
  padding: 8px;
  cursor: pointer;
}

/* Animation */
.dropdownMenu {
  transition: opacity 0.3s ease, transform 0.3s ease;
}
