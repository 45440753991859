@import '../../../../../sass/variables';

.container {
  width: 100%;
  font-family: "Lato", sans-serif !important;
  padding-top: 20px;
  padding-bottom: 50px;

  .title {
    font-family: "Lato", sans-serif !important;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    padding-top: 30px;
  }

  .listText {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 15px;

    .list {
      .listItem {
        padding-top: 5px;
      }
    }
  }

  .text {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-top: 20px;

    .linkTitle {
      font-family: "Lato", sans-serif !important;
      font-weight: 900;
      font-size: 25px;
      line-height: 30px;
      color: #EF5123;
    }
  }

  .highlightBg {
    margin-top: 20px;
    padding: 20px;
    background-color: #F2F2F2;

    .highlightText {
      color: #00C0F0;
      font-size: 30px;
      line-height: 45px;
      font-style: italic;
      font-weight: 500;
    }
  }

  .statisticsContainer {
    margin-top: 20px;
    background-color: #F2F2F2;
    padding: 10px;
    display: flex;
    gap: 10px;

    .statisticsIcon {
      width: 28px;
    }
    .statisticsText {
      line-height: 30px;
      font-size: 18px;
    }
  }

  .listWithLink {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 15px;
    color: #0037FB;

    .listItem {
      .linkTitle {
        color: #0037FB;
        font-weight: 700;
      }

      .text {
        color: #000;
      }
    }
  }
}