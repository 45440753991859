@import '../../sass/variables';

.profileContainer {
    width: 60%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    box-shadow: 0 2px 5px 0 rgba(74, 74, 75, 0.9);
    display: flex;
    margin: 0 auto;
    border-radius: 5px;
    margin-top: 100px;
    background-color: #f0f3f6;
    padding: 20px;
    position: relative;

    .header {
        width: 100%;
        border-bottom: 1px solid #d0d0d080;
        /* font-size: 17px; */
        display: flex;
        align-items: flex-start;
        padding-bottom: 20px;
        position: relative;

        .text1 {
            font-size: 14px;
            font-weight: 500;

        }

        .text2 {
            font-size: 12px;
            font-weight: 300;
        }

        .icon {
            width: 30px;
            height: 30px;
            fill: rgb(12, 11, 11);
            margin-left: 80px;
            border: 1px solid black;
            border-radius: 25px;
        }

        @media(max-width: 1200px) {
            .icon {
                margin-left: 0px;
            }
        }


        .logout {
            position: absolute;
            right: 0;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
        }

        .rightIcon {}
    }

    @media(max-width: 1200px) {
        .header {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;
        }
    }

    .content1 {
        display: flex;
        width: 900* $vw-const;
        justify-content: space-between;
        align-items: center;
        padding: 50px;

        .left {
            border-right: 1px solid #d0d0d080;
            width: 400* $vw-const;

            .icon {
                background-color: gray;
                width: 150px;
                height: 150px;
                border-radius: 100px;

                svg {
                    fill: white;
                }

            }


        }

        @media(max-width: 1200px) {
            .left {
                border: none;
                width: auto;
            }
        }

        .right {
            //  width: 50%;

            .rowContainer {
                display: flex;
                margin-bottom: 40* $vw-const;
                justify-content: flex-end;
                align-items: center;

                .label {
                    font-weight: 500;
                    font-size: 18 * $vw-const;
                }

                @media(max-width: 1200px) {
                    .label {
                        font-size: 12px;
                        width: 55px;
                    }
                }

                .value {
                    width: 250* $vw-const;
                    margin-left: 25px;

                    input {
                        border: none;
                        background: #d0d0d080;
                        padding: 12* $vw-const;
                        width: 100%;
                        border-radius: 4px;
                        width: 250* $vw-const;
                        font-size: 18 * $vw-const;

                    }
                    @media(max-width: 1200px) {
                        .input {
                            padding: 4px;
                            width: 100%;
                            font-size: 12px;
                        }
                    }
                }

                @media(max-width: 1200px) {
                    .value {
                        width: 90%;
                        margin-left: 15px;
                    }
                }
            }

            @media(max-width: 1200px) {
                .rowContainer {
                    margin-bottom: 15px;
                }
            }
        }
    }

    @media(max-width: 1200px) {
        .content1 {
            display: flex;
            flex-direction: column;
            gap: 50px;
            width: 100%;
            padding: 50px 0px;
        }
    }

    .footer {
        position: absolute;
        bottom: 20px;

        .text {
            font-size: 14 * $vw-const;
        }
        @media(max-width: 1200px) {
            .text {
                text-align: center;
                font-size: 10px;
            }
        }
    }
}

@media(max-width: 1200px) {
    .profileContainer {
        width: 82%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        box-shadow: 0 2px 5px 0 rgba(74, 74, 75, 0.9);
        display: flex;
        margin: 0 auto;
        border-radius: 5px;
        margin-top: 30px;
        margin-bottom: 30px;
        background-color: #f0f3f6;
        padding: 20px;
        position: relative;
    }
}