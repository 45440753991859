@import '../../sass/variables';

.container {
  height: 80vh;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-items: center;
  //  background-color: white;
  margin: 0 auto;
  // margin-top: 100px;
  color: #000000
}



.text {
  font-size: clamp(14px, 18* $vw-const, 18* $vw-const);
  line-height: 1.5;

}

.right {
  width: 35%;

  .content {
    margin-top: 20px;
    box-shadow: 3px 3px 20px #cccccc;

    .form {
      width: 85%;
      margin: 0 auto;
    }
  }
}

.left {
  width: 45%;
  margin-right: 50px;

  .content1 {
    margin-bottom: 30px;

    .heading {
      font-size: 28 * $vw-const;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .text {
      font-size: 18* $vw-const;
    }
  }

  .content2 {
    display: flex;

    .left {
      .head {
        font-size: 22 * $vw-const;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .point {
        font-size: 16* $vw-const;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        img {
          width: 18px;
          margin-right: 5px;
        }
      }
    }

    .right {
      .head {
        font-size: 22 * $vw-const;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .point {
        font-size: 16* $vw-const;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        img {
          width: 18px;
          margin-right: 5px;
        }
      }
    }
  }

  .content3 {
    .head {
      color: blueviolet;
      font-weight: 300px;
      font-size: 18* $vw-const;
      margin-bottom: 15* $vw-const;
      margin-top: 35px;
    }

    .text1 {
      font-size: 16* $vw-const;
    }
  }

}




.content {
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background-color: #f0f3f6;
  border-radius: 5px;

}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 50px;

}

.btn {
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.icon {
  height: 20px;
  width: 20px;
}

.CheckNavButton {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #007aff;
  background-color: #5706ed;
  box-shadow: 0 2px 10px 0 rgba(208, 208, 208, 0.5);
  border-radius: 5px;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 0px;
  cursor: pointer;
  width: 100%;

}

.CheckNavButton:hover {
  box-shadow: 0 2px 10px 0 rgba(73, 156, 248, 0.90);
}

@media (max-width: 414px) {
  .CheckNavButton {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.formInputContainer {
  width: 100%;
  margin-bottom: 25px;
  background-color: white;
  border-radius: 5px;


  //  border: 1px solid rgba(0, 0, 0, 0.08);
  // border-radius: 10px;
  // overflow: hidden;
  input {
    border-radius: 5px;
    border: none;
  }
}

.label {
  font-size: 0.8rem;
  font-weight: bold;
}

.input {
  display: block;
  padding: 15px;
  padding-right: 0px;
  border: none;
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  outline: none;
  position: relative;
  display: flex;
  flex: 1;
  width: 90%;
}

.footerLink {
  outline: none;
  border: none;
  background: transparent;
  font-size: 0.8rem;
  color: #007aff;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  text-decoration: underline;
}

.footerLink:hover {
  color: #0256b5;
  font-size: 0.9rem;
}

.error {
  color: red;
  font-size: 0.8rem;
}

.success {
  color: green;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    margin-top: 180px;
    align-items: flex-start;
    margin-bottom: 100px;
  }

  .right {
    width: 90%;
    .head {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .point {
      font-size: 16* $vw-const;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      img {
        width: 18px;
        margin-right: 5px;
      }
    }
  }

  .left {
    width: 100%;
    margin-right: 0px;
    .content1 {
      margin-bottom: 30px;
      margin-top: 200px;
  
      .heading {
        font-size: 20px;
      }
  
      .text {
        font-size: 12px;
      }
    }
  
    .content2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
      align-items: flex-start;
      margin: 0 auto;
  
      .left {
        margin-right: 0px;
        .head {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 20px;
        }
  
        .point {
          font-size: 12px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
  
          img {
            width: 14px;
            margin-right: 5px;
          }
        }
      }
  
      .right {
        .head {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 20px;
        }
  
        .point {
          font-size: 12px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
  
          img {
            width: 14px;
            margin-right: 5px;
          }
        }
      }
    }
  
    .content3 {
      .head {
        color: blueviolet;
        font-weight: 300px;
        font-size: 18px;
        margin-bottom: 15* $vw-const;
        margin-top: 35px;
      }
  
      .text1 {
        font-size: 12px;
        margin-bottom: 30px;
      }
    }
  
  }
}

.socialLogin {
  padding: 20px 25px 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px
}

.googleButton{
  font-size: 1rem;
  font-weight: 700;
  background-color: #ffffff !important;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #EA4335 !important;
  border: 1px solid !important;
  text-transform: none !important;
}

.linkedinButton{
  font-size: 1rem;
  font-weight: 700;
  background-color: #ffffff !important;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #0077B5 !important;
  border: 1px solid !important;
  text-transform: none !important;
}

.seperator {
  display: flex;
  align-items: center;
  justify-content: center;
  width: full;
  padding: 0 25px;
}

.seperatorLine {
  flex: 1;
  height: 1px;
  background-color: #00000046;
}

.seperatorText {
  padding-left: 4px;
  padding-right: 4px;
  color: #00000046;
  font-size: 10px;
}

