@import '../../../sass/variables';




.resumeBuildHomeContainer {
    width: 100%;
    height: 100%;
    font-weight: 18 * $vw-const;
    line-height: 1.5;

    .content3 {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        background-image: url('../../../assets/build-resume-home/bottom.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 400* $vw-const;
        background-color: #000;

        .left {
            width: 70%;
            margin: 0 auto;
            color: #ffffff;
            margin: 0 auto;
            color: #ffffff;
            height: 400* $vh-const;
            justify-content: center;
            display: flex;
            flex-direction: column;

            .text {
                font-size: 24 * $vw-const;
                margin-bottom: 20px;
            }

            .heading {
                font-size: 30 * $vw-const;
                color: #555;
                margin-bottom: 20px;
                margin-top: 20px;

                .priceOld {
                    font-weight: bold;
                    color: white;
                }

                .priceNew {
                    font-weight: bold;
                    color: yellow;
                }
            }

            .btn {}
        }


    }

    .content4 {
        width: 70%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 200px;

        button {
            border: 0;
            padding: 15px;
            width: 250px;
            background: #ccc;
            font-weight: bold;
            font-size: 20px;
            cursor: pointer;
        }

        button:hover {
            background: #999;
        }

    }

    .onlyText {
        font-size: 16px;
        color: #ffffff;
    }

  

    .footer {
        height: 200px;
        background-color: #ccc;
    }
}


@media only screen and (max-width: 768px) {

    .resumeBuildHomeContainer {
        font-weight: clamp(16px,18 * $vw-const, 18 * $vw-const);
      
        .content3 {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            background-image: url('../../../assets/build-resume-home/bottom.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 300px;
            background-color: #000;
            background-position-x: right;
            .left {
                width: 50%;
                color: #ffffff;
                color: #ffffff;
                height: 300px;
                justify-content: center;
                display: flex;
                flex-direction: column;
                margin-left: 50px;
    
                .text {
                    font-size: 16px;
                    margin-bottom: 20px;
                    color: black
                }
              
                .heading {
                    font-size: 20px;
                    color: #000;
                    margin-bottom: 20px;
                    margin-top: 20px;
    
                    .priceOld {
                        font-weight: bold;
                        color: #000;
                    }
    
                    .priceNew {
                        font-weight: bold;
                        color: #8a2be2;
                    }
                    .onlyText {
                        font-size: 16px;
                        color: #ffffff!important;
                    }
                  
                }
    
            }
    
    
        }
    }
}

.content3 {
    max-width: 90%;
    margin: 0px auto;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 40px;
    padding-top: 60px;
    background-color: #000;

    .text {
        font-size: 24px;
        color: white;
        margin-bottom: 20px;
        font-weight: 300;
        line-height: 30px;
    }
    .heading {
        font-size: 30px;
        line-height: 45px;
        color: #555;
        padding-bottom: 30px;
        padding-top: 30px;
        font-weight: bold;
        .priceOld {
            font-weight: bold;
            color: white;
        }
        .priceNew {
            font-weight: bold;
            color: yellow;
        }
    }
    .btnContainer {
        text-align: center;
        padding-top: 25px;
        margin: 0px auto;
        max-width: 90%;

        .primaryButton {
            width: 100%;
            max-width: 500px;
            color: white;
            background-color: #EF5123;
            border-width: 0;
            padding: 10px;
            font-size: 16px;
            font-weight: 600;
            font-family: "Poppins", sans-serif !important;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}

.footer {
    background-color: #000;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 30px;
    padding-bottom: 40px;

    .contentWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;

        .copyRight {
            color: white;
            font-size: 10px;
            line-height: 15px;
            font-weight: 400;
        }

        .privacy {
            color: white;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }

        .reachOut {
            color: white;
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;
        }

        .socialLinks {
            display: flex;
            gap: 20px;

            .socialLink {
                height: 30px;
                cursor: pointer;
            }
        }
    }
}

.onlyText {
    font-size: 16px;
    color: #ffffff!important;
}