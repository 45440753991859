@import '../../../../../sass/variables';

.container {
  width: 100%;
  font-family: "Lato", sans-serif !important;
  padding-top: 20px;
  padding-bottom: 50px;

  .title {
    font-family: "Lato", sans-serif !important;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    padding-top: 30px;
  }

  .highlight {
    line-height: 30px;
    margin-top: 20px;
    padding: 20px 25px 20px 25px;
    background: rgba(231, 223, 223, 0.65);
    border-radius: 5px;

    ul {
      padding-left: 20px;
    }
  }

  .applicationImage {
    padding-top: 20px;
    width: 100%;
  }

  .subTitle {
    font-family: "Lato", sans-serif !important;
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    padding-top: 30px;
  }

  .list {
    padding-left: 15px;
    line-height: 30px;
    .listItem {
      padding-top: 13px;
    }
  }

  .listText {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 5px;

    .list {
      .listItem {
        padding-top: 20px;
        .content {
          display: flex;
          align-items: center;
          gap: 18px;
          padding-top: 5px;
          padding-bottom: 10px;
          .bulb {
            height: 45px;
          }
        }
      }
    }
  }

  .table {
    border-collapse: collapse;
    td {
      border-style: solid;
      border-color: rgba(231, 223, 223, 1);
      border-width: 1.5px;
      font-weight: 500;
    }
    td {
      padding: 15px 40px 15px 20px;
    }
  }

  .listWithNestedContent {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 5px;

    .list {
      .listItem {
        // padding-top: 30px;
        .nestedListTitle {
          padding-top: 20px;
        }
        .nestedList {
          padding-top: 5px;
          padding-left: 15px;
          list-style-type: disc !important;
          .nestedListItem {}
        }
        .highlight {
          width: 110%;
          padding: 10px 10px 10px 25px;
          background-color: rgba(239, 81, 35, 0.08);

          .highlightList {
            list-style-type: disc !important;
          }

          .highlightOrderedList {
            padding-left: 20px;
          }
        }
      }
    }
  }

  .text {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-top: 20px;
  }

  .arrowText {
    display: flex;
    gap: 15px;
    align-items: start;
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-top: 20px;

    img {
      padding-top: 5px;
      width: 20px;
      height: 16px;
    }
  }
}