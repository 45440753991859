@import '../sass/variables';

.instructionWrap {
    display: flex;
    align-items: center;
    font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
    margin-top: clamp(20px, 40 * $vw-const, 40 * $vw-const);
    .step {
        display: flex;
        align-items: center;
        .stepContainer {
            width: clamp(200px, 320 * $vw-const, 320 * $vw-const);
            border-radius: 10px; 
            background-color: #F0F0F5;
            padding: 10px;
            text-align: center;
            display: flex;
            align-items: center;
            .number {
                padding: 10px;
                border-radius: 5px;
                font-size: 16px;
                font-weight: 700;
                background-color: #FFF;
                width: 10px;
                height: 10px;
                align-items: center;
                display: flex;
                justify-content: center;
                margin-right: 10px;
            }
        }
        .active {
            background-color: #040935;
            color: white;
            .number {
                color: black;
                
            }
        }
        .horLine {
            border-top: 1px solid #C5C5CE;
            height: 1px;
            width: 70px;            
          }
    }
   
}

@media (max-width: 768px) {
    .instructionWrap {
    .step {
        .stepContainer {
            width: 100px;
            padding: 5px;
            .number {
                font-size: 14px;
                margin-right: 5px;
            }
        }
        .horLine {
            width: 20px;            
          }
    }
  }
}
