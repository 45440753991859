.tooltipTitle {
    font-size: 1.8270401948842874vh;
    letter-spacing: 1px;
    font-weight: 600;
    background-color: #1a3149;
    color: #FFFFFF;
    padding: 10px;
    z-index: 100;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.KeywordStyle {
    font-size: 1.46163215590743vh;
    font-weight: 500;
    letter-spacing: 1px;
    background: #FFFFFF;
    padding: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.descStyle {
    /* margin-bottom: 10px; */
}
.boldStyles {
    font-weight: bold;
}