@import '../../../../../sass/variables';

.container {
  width: 100%;
  font-family: "Lato", sans-serif !important;
  padding-top: 10px;
  padding-bottom: 50px;

  .title {
    font-family: "Lato", sans-serif !important;
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
    padding-top: 30px;
  }

  .sampleResumeImage {
    padding-top: 20px;
    width: 105%;
  }

  .sampleResumeImageNormal {
    padding-top: 20px;
    width: 100%;
  }

  .text {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-top: 20px;
  }

  .listText {
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-left: 15px;

    .list {
      .listItem {
        padding-top: 20px;

        .exampleContainer {
          padding-top: 14px;

          .bgYellow {
            background-color: #F9E19C;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .textFlex{
    display: flex;
    gap: 5px;
    font-family: "Lato", sans-serif !important;
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
    padding-top: 20px;
  }
}