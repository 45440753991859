.LandingPageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

@media (max-width: 1024px) {
    .landingPageContentContainer {
        height: 100% !important;
    }

    .LandingPageContainer {
        padding-top: 3%;
        padding-bottom: 5%;
        flex-direction: column;
    }
}

@media (max-height: 720px) {
    .landingPageContentContainer {
        height: 100% !important;
    }
}

@media (max-width: 550px) {
    .LandingPageContainer {
        padding-top: 10%;
        padding-bottom: 5%;
    }
}

.col1 {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
    z-index: 10;
}

@media (max-width: 1024px) {
    .col1 {
        padding-left: 0px;
    }
}

@media (min-width: 1024px) and (max-height: 800px) {
    .col1 {
        padding-top: 5%;
        padding-bottom: 2%;
    }
}

.contentWrap {
    width: 100%;
    padding-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (max-width: 1024px) {
    .contentWrap {
        padding-left: 0px;
        align-items: center;
    }
}

.shortContent {
    width: 77%;
    margin-top: 20px;
    font-size: 1.58344vh;
    font-weight: 500;
    color: #130e49;
    letter-spacing: 0.6px;
}

@media (max-width: 1024px) {
    .shortContent {
        width: 85%;
        text-align: center;
    }
}

@media (max-width: 550px) {
    .shortContent {
        width: 90%;
    }
}

@media (max-height: 550px) {
    .shortContent {
        font-size: 2.46163vh;
    }
}

.col2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 1s linear;
    z-index: 10;
    padding-top: 2.43605359317905vh;
}

.trySampleWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.orStyle {
    font-size: 1.58344vh;
    font-weight: 600;
    color: #130E49;
    letter-spacing: 0.65px;
    margin-bottom: 20px;
}

@media (max-width: 550px) {
    .orStyle {
        margin-bottom: 10px;
    }
}

@media (max-height: 550px) {
    .orStyle {
        font-size: 2.67112vh;
    }
}

.sampleWrap {
    display: flex;
    align-items: center;
}

.sampleLable {
    font-size: 1.33983vh;
    font-weight: 500;
    color: #130E49;
    letter-spacing: 0.65px;
    margin-right: 25px;
}

@media (max-width: 550px) {
    .sampleLable {
        margin-right: 10px;
    }
}

@media (max-height: 550px) {
    .sampleLable {
        font-size: 2.67112vh;
    }
}

.onUploadInputField {
    height: 8.284vh !important;
    background-color: #F5FAFF !important;
    border: 2px dashed #007AFF !important;
    transition: height 0.6s linear;
}

@media (max-width: 1024px) {
    .onUploadInputField {
        height: 10.284vh !important;
    }
}

@media (max-height: 550px) {
    .onUploadInputField {
        height: 16vh !important;
    }
}

.popupStyle {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh);
    background: rgba(0, 0, 0, 0.3);
    z-index: 1400;
}

.animationFullPage {
    position: fixed;
    top: 0;
    width: 100%;
    height: calc(100vh);
    z-index: 1300;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

@media (max-height: 550px) {
    .animationFullPage {
        height: 100% !important;
    }
}

.trySample {
    font-size: 1.33983vh !important;
    font-weight: 500;
    background: #FFFFFF !important;
    border: 0 solid #0279FF !important;
    border: 1px solid #0279FF !important;
    border-radius: 5px !important;
    color: #0279FF !important;
    padding: 0.3rem !important;
    padding-left: 1.2rem !important;
    padding-right: 1.2rem !important;
    letter-spacing: 0.45px !important;
}

.trySample:hover {
    background: #007AFF !important;
    color: #FFFFFF !important;
}

@media (max-height: 550px) {
    .trySample {
        font-size: 2.67112vh !important;
    }
}