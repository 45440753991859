@import '../../../sass/variables';

.footerContainer {
    width: 100%;
    height: 100%;
    font-weight: 18 * $vw-const;
    line-height: 1.5;
    .btn button {
        background-color: #EF5123;
        font-size: 20* $vw-const;
        font-weight: 400;
        color: white;
        border-radius: 5px;
        padding: 10px 24px 10px 24px;
        margin-top: 10px;
    }

}

@media (max-width: 900px) {
    .container {
      width: 90%;
    }
    .footerContainer {
        .btn button {
            font-size: 12px;
        }
    
    }
  }


@media only screen and (max-width: 1024px) {

}
