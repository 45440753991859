@import '../../../sass/variables';

.slider {
    background-color: #F2F2F6;
    padding: 50px 0px;
}



.box1 {
    margin-left: 100px;
    margin-bottom: 100px;

    .parentShadow {
        position: relative;
        width: 229px;
        padding: 20px;
        background: #FF5D47;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background-image: url('../../../assets/build-resume-home/Vector.png');

    }

    .arrowBox {
        position: relative;
        width: 332px;
        padding: 40px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        /* shadow effect */
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        z-index: 123;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 50px;
            bottom: -40px;
            z-index: 1;
            /* Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /* Width of the triangle */
            border-right: 50px solid transparent;
            /* Width of the triangle */
            border-top: 50px solid #ffffff;
            /* Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 62px;
        bottom: -40px;
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-right: 60px solid transparent;
        /* Width of the triangle */
        border-top: 60px solid #FF5D47;
        /* Height of the triangle and color */
    }

    /* Height of the triangle container */
}

.box2 {
    margin-top: 50px;
    margin-left: 100px;


    .parentShadow {
        position: relative;
        width: 269px;
        padding: 20px;
        background: #FF5D47;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background-image: url('../../../assets/build-resume-home/Vector.png');
    }

    .arrowBox {
        position: relative;
        width: 292px;
        padding: 40px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        /* shadow effect */
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        z-index: 123;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 140px;
            top: -30px;
            z-index: 1;
            /* Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /* Width of the triangle */
            border-left: 50px solid transparent;
            /* Width of the triangle */
            border-bottom: 50px solid #ffffff;
            /* Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 158px;
        top: -28px;
        /* Height of the triangle container */
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-left: 60px solid transparent;
        /* Width of the triangle */
        border-bottom: 60px solid #FF5D47;
        /* Height of the triangle and color */
    }
}

.box3 {
    margin-top: 50px;
    margin-left: 100px;

    .parentShadow {
        position: relative;
        width: 269px;
        padding: 20px;
        background: #FF5D47;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background-image: url('../../../assets/build-resume-home/Vector.png');
    }

    .arrowBox {
        position: relative;
        width: 430px;
        padding: 40px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        /* shadow effect */
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        z-index: 123;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 140px;
            bottom: -30px;
            z-index: 1;
            /* Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /* Width of the triangle */
            border-left: 50px solid transparent;
            /* Width of the triangle */
            border-top: 50px solid #ffffff;
            /* Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 158px;
        bottom: -28px;
        /* Height of the triangle container */
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-left: 60px solid transparent;
        border-top: 60px solid #FF5D47;
        /* Height of the triangle and color */
    }
}

.headerSection {
    width: 78%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: clamp(400px, 800 * $vw-const, 800 * $vw-const);
    .left{
        width: 40%;
        margin-right: 30px;
        .headText {
            font-size: clamp(26px, 50 * $vw-const, 50 * $vw-const);
            font-weight: 700;
            line-height: 1.2;
        }
        .content {
            font-size: clamp(18px, 26 * $vw-const, 26 * $vw-const);
            font-weight: 300;
            color: #646464;
            margin-top: 10px;
            margin-bottom: 40px;
            line-height: 1.5;
        }
        .buttons {
            
            .btn1 {
                background-color: #EF5123;
                font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
                
                font-weight: 600;
                color: white;
                border-radius: 10px;
                padding: 10px 24px 10px 24px;
            }
            .btn2 {
                background-color: white;
                font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
                font-weight: 400;
                color: #EF5123;
                border-radius: 10px;
                margin-left: 20px;
                padding: 10px 24px 10px 24px;
                margin-top: 15px;
                border: 1px solid #ef50272e;
            }
        }
    }
    .right {
        width: 60%;
    }
    
    .animationVideos {
        display: flex;
    }
}


.resumeBuildHomeContainer {
    width: 100%;
    height: 100%;
    font-weight: 18 * $vw-const;
    line-height: 1.5;


    .header {
        width: 100%;
        margin-top: 0px;
        // background-image: url('../../assets/build-resume-home/bg.png');
        background-color: #F2F2F6;
        background-position: center;
        /* Center the image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
        background-size: cover;
        display: flex;
        align-items: center;
        flex-direction: column;

        justify-content: flex-end;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;

        .pic {
            width: 80* $vw-const;
            height: 80* $vw-const;
            border-radius: 40* $vw-const;
            background: white;
            margin-bottom: 10px;
            fill: gray
        }

        .name {
            font-weight: bold;
            font-size: 28* $vw-const;
            color: #000;

        }

        .text {
            font-size: 20* $vw-const;
            color: #333;
            margin-bottom: 20* $vw-const;
        }

        .blueText {
            color: #4936D3;
            line-height: 1.5;
            font-size: 16* $vw-const;
        }

        .btn {
            background-color: #4936d3;
            margin-bottom: 10* $vw-const;
            margin-top: 30* $vw-const;
            // width: 160* $vw-const;
        }
    }

    .heading {
        font-size: clamp(20px, 30 * $vw-const, 30 * $vw-const);
        font-weight: bold;
        margin-bottom: 20* $vw-const;
    }
    .mgBt40 {
        margin-bottom: 40* $vw-const;

    }
    .midBlackStrip {
        background-color: #040935;
        height: clamp(180px, 254* $vw-const, 254* $vw-const); 
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: -20* $vw-const;
        position: relative;
        z-index: 1;

        .midBlackStripChild {
            width: 78%;
            display: flex;
            align-items: center;
            justify-content: space-between;    
            margin: 0 auto
        }
        .iconBlock {
            width: clamp(180px, 254* $vw-const, 254* $vw-const); 
            font-weight: 300;
            font-size: clamp(16px, 22* $vw-const, 22 *$vw-const);
            color: #ffffff;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            img {
                width: clamp(30px, 40* $vw-const, 40* $vw-const);;
                margin-bottom: 20px;
            }
            .boldColor{
                color:#EF5123;
                font-weight: 600;
            }
        
        }
    }

    .content1 {
        display: flex;
        flex: 1;
        justify-content: space-between;
        width: 78%;
        margin: 0 auto;
        margin-top: 100* $vw-const;
        margin-bottom: 100* $vw-const;
        align-items: center;

        .text {
            font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
            font-family: "IBM Plex Sans", sans-serif;
            font-weight: 400;
            font-style: normal;
        }

        .left {
            width: 45%;
            .btn {
                background-color: #EF5123;
                font-size: 20* $vw-const;
                font-weight: 400;
                color: white;
                border-radius: 5px;
                padding: 10px 24px 10px 24px;
            }
        }

        .content {
            font-weight: 18 * $vw-const;
        }

        .right {
            width: 45%;
            img {
                width: 100%;
            }
        }

    }

    .content2 {
        width: 78%;
        margin: 0 auto;
        margin-top: 100* $vw-const;
        margin-bottom: 100* $vw-const;

        .boxesContainer {
            display: flex;
            width: 70%;

            .boxContainer {
                margin-right: 30px;

                .img {
                    width: 270* $vw-const;
                }

                .head {
                    font-size: 16* $vw-const;
                    font-weight: bold;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .text {
                    font-size: 16* $vw-const;
                }
            }

            .box {
                width: 200 * $vw-const;
                height: 100 * $vw-const;
                background-color: #ccc;
                margin-right: 10px;
            }
        }
    }

    .content3 {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        background-image: url('../../../assets/build-resume-home/bottom.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 400* $vw-const;
        background-color: #000;

        .left {
            width: 70%;
            margin: 0 auto;
            color: #ffffff;
            margin: 0 auto;
            color: #ffffff;
            height: 400* $vh-const;
            justify-content: center;
            display: flex;
            flex-direction: column;

            .text {
                font-size: 24 * $vw-const;
                margin-bottom: 20px;
            }

            .heading {
                font-size: 30 * $vw-const;
                color: #555;
                margin-bottom: 20px;
                margin-top: 20px;

                .priceOld {
                    font-weight: bold;
                    color: white;
                }

                .priceNew {
                    font-weight: bold;
                    color: yellow;
                }
            }

            .btn button {
                background-color: #EF5123;
                font-size: 20* $vw-const;
                font-weight: 400;
                color: white;
                border-radius: 5px;
                padding: 10px 24px 10px 24px;
            }
        }


    }

    .content4 {
        width: 70%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 200px;

        button {
            border: 0;
            padding: 15px;
            width: 250px;
            background: #ccc;
            font-weight: bold;
            font-size: 20px;
            cursor: pointer;
        }

        button:hover {
            background: #999;
        }

    }

    .trySample {
        text-decoration: underline;
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        margin-bottom: 30* $vw-const;
        margin-top: 20* $vw-const;
        cursor: pointer;
    }

    .faqsContainer {
        width: 78%;
        margin: 0 auto;
        margin-bottom: 100px;
        text-align: center;
        .questionContainer {

            padding: 20px 0px;
            font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
            text-align: left;

            .questionAns {
                background-color: #FAFAFA;
                margin-bottom: 20px;
                padding: 10px 40px;
                border-radius: 5px;
            }

            .question {
                font-weight: bold;
                padding-bottom: 10px;
                padding-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

            }

            .open {
                width: 15px;
                height: 15px;
                border-radius: 10px;
                border: 1px solid #DBD9D9;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .close {
                width: 15px;
                height: 15px;
                border-radius: 10px;
                border: 1px solid #DBD9D9;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            .answer {
                padding-top: 20px;
                border-top: 1px solid #DBD9D9;
                margin-bottom: 10px;

            }
        }
    }

    .footer {
        height: 200px;
        background-color: #ccc;
    }
}

@media (max-width: 900px) {
    .pannaLogo {
      margin-top: 8px;
      margin-left: 10px;
    }
    .container {
      width: 90%;
    }
  }


@media only screen and (max-width: 900px) {

    .headerSection {
        width: 90%;
    }   
    video::-webkit-media-controls {
        display: none !important;
    }

    .resumeBuildHomeContainer {
        font-weight: clamp(16px,18 * $vw-const, 18 * $vw-const);
    
        .header {
            margin-top: 0px;
            padding-bottom: 20px;
            padding-top: 20px;
            .pic {
                width: 60px;
                height: 60px;
                border-radius: 30px;
                margin-top: 20px;
            }
    
            .name {
                font-size: 20px;
    
            }
    
            .text {
                font-size: 13px;
                margin-bottom: 10px;
            }
    
            .blueText {
                font-size: 12px;
            }
    
            .btn {
                font-size: 14px;
            }
        }
    
        .heading {
            font-size: 20px;
        }
    
        .content1 {
            flex: 1;
            justify-content: space-between;
            width: 100%;
            margin: 0 auto;
            margin-top: 50px;
            margin-bottom: 50px;
            flex-direction: column;
            align-items: center;
    
            .text {
                font-size: 12px;
            }
    
            .left {
                width: 90%;
            }
    
            .content {
                font-weight: 18 * $vw-const;
            }
    
            .right {
                width: 70%;
                margin-top: 30px;
                margin-bottom: 30px;
            }
    
        }
    
        .content2 {
            width: 90%;
            margin: 0 auto;
            margin-top: 50px;
            margin-bottom: 50px;
    
            .boxesContainer {
                display: flex;
                width: 90%;
                flex-direction: column;
                margin: 0 auto;
                .boxContainer {
                    margin-right: 30px;
                    margin-top: 30px;
                    margin-bottom: 30px;

                    .img {
                        width: 100%;
                    }
    
                    .head {
                        font-size: 18px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                        text-align: center;
                    }
    
                    .text {
                        font-size: 12px;
                    }
                }
             
            }
        }
    
        .content3 {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            background-image: url('../../../assets/build-resume-home/bottom.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 300px;
            background-color: #000;
            background-position-x: right;
            .left {
                width: 50%;
                color: #ffffff;
                color: #ffffff;
                height: 300px;
                justify-content: center;
                display: flex;
                flex-direction: column;
                margin-left: 50px;
    
                .text {
                    font-size: 16px;
                    margin-bottom: 20px;
                    color: black
                }
    
                .heading {
                    font-size: 20px;
                    color: #000;
                    margin-bottom: 20px;
                    margin-top: 20px;
    
                    .priceOld {
                        font-weight: bold;
                        color: #000;
                    }
    
                    .priceNew {
                        font-weight: bold;
                        color: #8a2be2;
                    }
                }
    
            }
    
    
        }
    
        .content4 {
            width: 70%;
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            text-align: center;
            justify-content: center;
            align-items: center;
            height: 200px;
    
            button {
                border: 0;
                padding: 15px;
                width: 250px;
                background: #ccc;
                font-weight: bold;
                font-size: 20px;
                cursor: pointer;
            }
    
            button:hover {
                background: #999;
            }
    
        }
    
        .trySample {
            text-decoration: underline;
            font-size: clamp(12px, 16 * $vw-const, 16 * $vw-const);
            margin-bottom: 30* $vw-const;
            margin-top: 20* $vw-const;
            cursor: pointer;
        }
    
        .faqsContainer {
            width: 90%;
            margin: 0 auto;
            margin-bottom: 100px;
           
        }

        
.box1 {
    margin-left: 50px;
    margin-bottom: 50px;

    .parentShadow {
        position: relative;
        width: 150px;
        padding: 20px;
        border-radius: 20px;
        background: #FF5D47;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background-image: url('../../../assets/build-resume-home/Vector.png');
       
    }

    .arrowBox {
        position: relative;
        width: 160px;
        padding: 40px;
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 50px;
            bottom: -40px;
            z-index: 1;
            /*  Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /*  Width of the triangle */
            border-right: 50px solid transparent;
            /*  Width of the triangle */
            border-top: 50px solid #ffffff;
            /*  Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 62px;
        bottom: -40px;
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-right: 60px solid transparent;
        /* Width of the triangle */
        border-top: 60px solid #FF5D47;
        /* Height of the triangle and color */
    }

    /* Height of the triangle container */
}


.box2 {
    margin-top: 50px;
    margin-left: 50px;


    .parentShadow {
        position: relative;
        width: 169px;
        padding: 20px;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background: #FF5D47;
        background-image: url('../../../assets/build-resume-home/Vector.png');
    }

    .arrowBox {
        position: relative;
        width: 192px;

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 140px;
            top: -30px;
            z-index: 1;
            /*  Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /*  Width of the triangle */
            border-left: 50px solid transparent;
            /*  Width of the triangle */
            border-bottom: 50px solid #ffffff;
            /*  Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 158px;
        top: -28px;
        /*  Height of the triangle container */
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /*  Width of the triangle */
        border-left: 60px solid transparent;
        /*  Width of the triangle */
        border-top: 60px solid #FF5D47;
        /*  Height of the triangle and color */
    }
}

.box3 {
    margin-top: 50px;
    margin-left: 50px;

    .parentShadow {
        position: relative;
        width: 169px;
        padding: 20px;
      //   background: #E6E4F4;
        background: #FF5D47;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        background-image: url('../../../assets/build-resume-home/Vector.png');
        
    }

    .arrowBox {
        position: relative;
        width: 230px;
        padding: 40px;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
        /*  shadow effect */
        font-size: clamp(14px, 16 * $vw-const, 16 * $vw-const);
        z-index: 123;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .name {
            margin-top: 10px;
        }

        .triangleContainer {
            position: absolute;
            width: 50px;
            height: 50px;
            left: 140px;
            bottom: -30px;
            z-index: 1;
            /*  Height of the triangle container */
        }

        .triangle {
            position: relative;
            width: 0;
            height: 0;
            /*  Width of the triangle */
            border-left: 50px solid transparent;
            /*  Width of the triangle */
            border-top: 50px solid #ffffff;
            /*  Height of the triangle and color */
        }
    }

    .triangleContainer1 {
        position: absolute;
        width: 60px;
        height: 60px;
        left: 158px;
        bottom: -28px;
        /* Height of the triangle container */
    }

    .triangle1 {
        position: relative;
        width: 0;
        height: 0;
        /* Width of the triangle */
        border-left: 60px solid transparent;
        border-top: 60px solid #FF5D47;
        /*  Height of the triangle and color */
    }
}
    
    }

}


.featuresBlock {
    width: 88%;
    margin: 0 auto;
    text-align: center;
    .featuresHeading {
        color: #A6B5C8;
        font-weight: 400;
        font-family: "IBM Plex Sans", sans-serif;
        font-style: normal;
        font-size: clamp(18px, 22 * $vw-const, 22 * $vw-const);
    }
}

.featureCard {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    max-width: 420px;
    text-align: left;
  }
  
  .featureIcon {
    width: 50px; /* Adjust the size as needed */
    height: auto;
    margin-right: 20px;
  }
  
  .featureContent {
    display: flex;
    flex-direction: column;
  }
  
 
  
  .featureDescription {
    font-size: clamp(16px, 20 * $vw-const, 20 * $vw-const);
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #000000;
  }

  .featuresContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }