/* SpinnerMaterialUI.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
}

.spinner {
    color: blueviolet !important;
    /* color of the spinner */
}