/* Menu.css */

.menu {
    position: relative;
    top: 0px; /* Adjust top position as needed */
    right: 0px; /* Adjust right position as needed */
    z-index: 123456;
    height: 60px;
    display: flex;
    align-items: center;
  }
  
  .menu-toggle {
    background: none;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .menu-icon {
    width: 25px;
    height: 3px;
    background-color: #333;
    display: block;
    position: relative;
    transition: background-color 0.3s ease;
  }
  
  .menu-icon::before,
  .menu-icon::after {
    content: '';
    width: 25px;
    height: 3px;
    background-color: #333;
    display: block;
    position: absolute;
    transition: transform 0.3s ease;
  }
  
  .menu-icon::before {
    top: -8px;
  }
  
  .menu-icon::after {
    top: 8px;
  }
  
  .menu-items {
    position: absolute;
    top: 0px; /* Adjust top position as needed */
    right: 0;
    width: 100vw; /* Adjust width as needed */
    height: 100vh;
    background-color: #333;
    color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-radius: 10px;
    z-index: 1234567;
  }
  
  .menu-items ul {
    list-style-type: none;
    padding-left: 20px;

  }
  
  .menu-items li {
    margin-bottom: 10px;
    cursor: pointer;
    /* text-decoration: underline; */
    border-bottom: 1px solid transparent;
    width: fit-content;
  }

  .menu-items li:hover {
     /* text-decoration: underline; */
     border-bottom: 1px solid #AA6DCE;
     width: fit-content;
  }
  .close-icon {
    color: white;
    font-weight: 300;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
  }
  
  .menu-items a {
    color: white;
    text-decoration: none;
    font-size: 16px;
  }
  
  .menu-items a:hover {
    color: #ddd;
  }
 

  .pannaRBLogo {
    cursor: pointer;
    width: 180px;
    margin-top: 15px;
    margin-left: 15px;
    outline: none;
    border: none;
    z-index: 200;
  }


  .pannaLogo {
    cursor: pointer;
    width: 100px;
    margin-top: 15px;
    margin-left: 15px;
    outline: none;
    border: none;
    z-index: 200;
  }
  
  .betaLogo {
    cursor: pointer;
    width: 80px;
    outline: none;
    border: none;
    z-index: 200;
    margin-left: 2px;
  }