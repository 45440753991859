@import '../../sass/variables';
.reportContainer {
    position: relative;
}

.MatchReportWrap {
    height: auto;
}

.redirectContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.uploadButton {
    z-index: 100;
}



@media (max-width: 1440px) {
    .uploadButton {
        font-size: 14px;
    }
}

@media (max-width: 414px) {
    .uploadButton {
        font-size: 10px;
        padding: 4px;
        margin-bottom: 6px;
    }
}

.content {
    width: 90%;
    font-size: 32px;
    color: #000000;
    letter-spacing: 0.75px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

.alignContent {
    align-self: flex-start;
}

@media (max-width: 414px) {
    .alignContent {
        align-self: center;
    }
}

@media (max-width: 1700px) {
    .content {
        font-size: 30px;
    }
}

@media (max-width: 1500px) {
    .content {
        font-size: 30px;
    }
}

@media (max-width: 1024px) {
    .content {
        font-size: 30px;
    }
}

@media (max-width: 700px) {
    .content {
        font-size: 24px;
        align-self: center;
    }
}

@media (max-width: 414px) {
    .content {
        font-size: 16px;
        letter-spacing: 1.69px;
    }
}

@media (max-height: 414px) {
    .content {
        font-size: 20px;
        letter-spacing: 1.69px;
    }
}

.popupstyles {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh);
    background: rgba(0, 0, 0, 0.3);
    z-index: 1400;
}

.popup1Style {
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh);
    background: rgba(0, 0, 0, 0.3);
    z-index: 2100;
}

.resumeReportContainer {
    width: 100%;
    height: 100%;
}

.loaderWrap {
    width: 30%;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    padding: 20px 10px;
}

@media (max-width: 500px) {
    .loaderWrap {
        width: 65%;
        padding: 12px 5px;
    }
}

.loaderTitle {
    font-size: 3.089598352214212vh;
    color: #130E49;
    text-align: center;
    font-weight: bold;
    letter-spacing: 1px;
    /* margin-top: 5%; */
    margin-bottom: 10px;
}

.signUpToBtn {
    width: 250px;
    padding: 10px;
    font-weight: bold;
    position: fixed;
    text-align: center;
    right: 0px;
    margin: 30px;
    bottom: 0px;
    z-index: 1;
    background: white;
    box-shadow: 3px 5px 20px #888888;
    border-radius: 5px;
    cursor: pointer;
    animation: tilt-shaking 0.7s infinite;
    background: #48abe0;
    color: white;

}

@keyframes fadeInRightBig {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(430px, 0, 0);
        transform: translate3d(430px, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}



.animate__fadeInRightBig {
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig
}

.signUpToContainer {
    width: 300px;
    height: 140px;
    padding: 20px;
    box-shadow: 0 7px 30px 0 rgba(208, 208, 208, 0.5);
    border-radius: 5px;
    position: fixed;
    z-index: 1;
    top: 200px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;

    .left {
        width: 30%;
    }

    .right {
        width: 75%;

        .head {
            font-weight: 700;
            font-size: 20px;
        }

        .text {
            font-size: 14px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

}

@keyframes fadeInLeftBig {
    from {
        opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(200px, 0, 0);
      transform: translate3d(200px, 0, 0);
    }
  }

  .animateFadeInLeft {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInLeftBig;
    animation-name: fadeInLeftBig;
    height: 100px!important;
    padding: 10px!important;
    align-items: flex-start!important;
//    top: 100px!important;
//     right: 200px!important;
//    height: 100px!important;
//    width: 200px!important;
//    justify-content: flex-start!important;
//    padding: 10px!important;
//    flex-direction: row!important;
//    .heading {
//     font-size: 16px!important;
//    }
.arrowIcon {
    transform: rotate(180deg);
    position: relative;
}
   img {
    display: none;
   }
   .bottomDiv, .content1, .points {
    display: none!important;
   }
   .heading {
    font-size: 16px!important;
    width: 150px;
   }

}


.animateFadeInRight {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInRightBig;
    animation-name: fadeInRightBig;  
}


.sliderContainer {
    width: clamp(300px, 30vw, 30vw);
    height: clamp(540px, 570px, 570px);;
    overflow: hidden;
    box-shadow: 0 7px 30px 0 rgba(208, 208, 208, 0.5);
    border-radius: 5px;
    position: fixed;
    z-index: 1;
    padding: 50px 10px 50px 10px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 90px;
    background-color: white;
   
    .arrowIcon {
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
    }
    .heading {
        font-size: clamp(18px, 22px, 22px);;
        font-weight: bold;
        text-align: center;
        line-height: 1.2;
        padding: 0px 40px 10px 40px;
    }
    .imagesContainer {
        display: flex;

        .newResume, .oldResume {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width:  clamp(100px, 175px, 250px);
            }
        }
    }
    .bottomDiv {
        background-color: #efefefeb;
        padding: 20px 10px 30px 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .content1, .points{
        font-size: 14px;
    } 
    .points {
        margin-top: 10px;
    }
    .point {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    .button {
        margin-top: 20px;
    }
}

@media (max-width: 1280px) {
    .sliderContainer {
       
        .heading {
            padding: 0px 30px 10px 40px;
            font-size: 18px;
        }
        .imagesContainer {
            .newResume, .oldResume {
                img {
                    width:  120px;
                }
            }
        }
    }
    .animateFadeInLeft {
        right: 50px;
       .heading {
        font-size: 16px!important;
        padding-top: 20px;
        padding-left: 0px;
        width: 150px;
       }    
    }
}


@media (max-width: 767px) {
    .sliderContainer {
       
        .heading {
            padding: 0px 30px 10px 40px;
            font-size: 18px;
        }
        .imagesContainer {
            .newResume, .oldResume {
                img {
                    width:  120px;
                }
            }
        }
    }
    .animateFadeInLeft {
        right: 50px;
       .heading {
        font-size: 16px!important;
        padding-top: 20px;
        padding-left: 0px;
        width: 150px;
       }    
    }
}


@media (max-width: 500px) {
    .loaderTitle {
        font-size: 2.089598vh;
    }
}

.loaderMessage {
    font-size: 1.58344vh;
    font-weight: 500;
    letter-spacing: 0.6px;
}

body>iframe[style*='2147483647'] {
    display: none;
}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(2deg);
    }

    50% {
        transform: rotate(0eg);
    }

    75% {
        transform: rotate(-2deg);
    }

    100% {
        transform: rotate(0deg);
    }
}