.buttonStyle {
    width: max-content;
    padding: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    /* background: #007AFF; */
    background: #5706ed;
    border: 0px;
    border-radius: 5px;
    font-size: 1.82704vh;
    color: #FFFFFF;
    text-align: center;
    align-self: center;
    cursor: pointer;
}

.buttonStyle:hover {
    box-shadow: 0 2px 10px 0 rgba(73, 156, 248, 0.90);
}

@media (max-height: 550px) {
    .buttonStyle {
        font-size: 2.77112vh;
    }
}

.buttonStyle:disabled {
    opacity: 0.5;
    background: #007AFF;
    border-radius: 5px;
    cursor: not-allowed;
}